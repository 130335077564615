import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ClipLoader } from 'react-spinners'
import { CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts'

import { getBMITrend } from '../../../api/reporting'
import classes from './BMITrendChart.module.css'

const BMITrendChart = ({ isDarkMode }) => {
  const [chartData, setChartData] = useState([])
  const [loading, setLoading] = useState(true)
  const { t } = useTranslation()

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true) // Set loading to true before fetching data
        const data = await getBMITrend()
        setChartData(data)
      } catch (error) {
        console.error('Error fetching BMI trend:', error)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  return (
    <div className={classes.BMITrendChart}>
      <h3>{t('report.chart.BMITrend.headline')}</h3>
      {loading ? (
        <div className={classes.LoadingSpinner}>
          <ClipLoader size={50} color={''} loading={loading} />
        </div>
      ) : (
        <>
          <div className={classes.Chart}>
            {chartData && chartData.length > 0 ? (
              <LineChart width={window.innerWidth * 0.9} height={160} data={chartData} className={classes.LineChart}>
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis
                  dataKey='date'
                  tick={{ fill: isDarkMode ? 'lightgrey' : '#F0F3FA', fontSize: 12 }}
                  tickLine={{ stroke: isDarkMode ? 'lightgrey' : '#F0F3FA' }}
                  axisLine={{ stroke: isDarkMode ? 'lightgrey' : '#F0F3FA' }}
                  tickFormatter={(str) => {
                    const date = new Date(str)
                    return date.toLocaleDateString()
                  }}
                />
                <YAxis
                  tick={{ fill: isDarkMode ? 'lightgrey' : '#F0F3FA', fontSize: 12 }}
                  tickLine={{ stroke: isDarkMode ? 'lightgrey' : '#F0F3FA' }}
                  axisLine={{ stroke: isDarkMode ? 'lightgrey' : '#F0F3FA' }}
                />
                <Tooltip
                  content={({ active, payload, label }) => {
                    if (active && payload && payload.length) {
                      const date = new Date(label)
                      return (
                        <div className={classes.customTooltip}>
                          <p className={classes.label}>{`${date.toLocaleDateString()}`}</p>
                          <p className={classes.intro}>{`BMI: ${payload[0].value}`}</p>
                        </div>
                      )
                    }

                    return null
                  }}
                />
                <Line type='monotone' dataKey='bmi' stroke={isDarkMode ? '#8AAEE0' : '#395886'} />
              </LineChart>
            ) : (
              <div className={classes.TextWrapper}>
                <p className={classes.NoDataText}>{t('report.noDataAvailable')}</p>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default BMITrendChart
