import { faCheckCircle, faExclamationCircle, faInfoCircle, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React from 'react'

import classes from './NotificationPopUp.module.css'

function Notification({ text, buttons, onButtonClick, type }) {
  let icon = null

  switch (type) {
  case 'alert':
    icon = <FontAwesomeIcon icon={faInfoCircle} className={classes.AlertIcon} />
    break
  case 'error':
    icon = <FontAwesomeIcon icon={faExclamationCircle} className={classes.ErrorIcon} />
    break
  case 'confirmation':
    icon = <FontAwesomeIcon icon={faQuestionCircle} className={classes.ConfirmationIcon} />
    break
  case 'success':
    icon = <FontAwesomeIcon icon={faCheckCircle} className={classes.ConfirmationIcon} />
    break
  default:
    icon = null
  }

  return (
    <>
      <div className={classes.Overlay}></div>
      <div className={classes.Notification}>
        <div className={classes.IconContainer}>{icon}</div>
        {text.split('\n').map((line, index) => (
          <React.Fragment key={index}>
            <p>{line}</p>
          </React.Fragment>
        ))}
        <div className={classes.Buttons}>
          {buttons.map((button, index) => (
            <button key={index} onClick={() => onButtonClick(button.action)}>
              {button.label}
            </button>
          ))}
        </div>
      </div>
    </>
  )
}

Notification.propTypes = {
  text: PropTypes.string.isRequired,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      action: PropTypes.any.isRequired
    })
  ).isRequired,
  onButtonClick: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['alert', 'error', 'confirmation', 'success']).isRequired
}

export default Notification
