import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getBodyWeightTrend } from '../../api/reporting'
import { getUserProfile } from '../../api/user'
import Footer from '../../components/Footer/Footer'
import NavigationMobile from '../../components/Navigation/BottomNavigation/BottomNavigation'
import Navigation from '../../components/Navigation/TopNavigation/Navigation'
import Calendar from '../../components/Reports/Calendar/Calendar'
import CycleWidget from '../../components/Widgets/CycleWidget/CycleWidget'
import WeightWidget from '../../components/Widgets/WeightWidget/WeightWidget'
import classes from './Dashboard.module.css'

export const Dashboard = ({ firstname }) => {
  const { t } = useTranslation()
  const [showWeightWidget, setShowWeightWidget] = useState(false)
  const [userProfile, setUserProfile] = useState(null)

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const profile = await getUserProfile()
        setUserProfile(profile)
        if (profile.body_weight_widget_range > 0) {
          const weightTrend = await getBodyWeightTrend()
          if (weightTrend.length > 0) {
            weightTrend.sort((a, b) => new Date(b.date) - new Date(a.date))
            const lastWeightUpdate = new Date(weightTrend[0].date)
            const daysSinceLastUpdate = Math.ceil((new Date() - lastWeightUpdate) / (1000 * 60 * 60 * 24))

            if (daysSinceLastUpdate >= profile.body_weight_widget_range) {
              setShowWeightWidget(true)
            } else {
              setShowWeightWidget(false)
            }
          } else {
            setShowWeightWidget(true) // Show widget if no weight data is available
          }
        } else {
          setShowWeightWidget(false)
        }
      } catch (error) {
        console.error('Error fetching user profile or weight trend:', error)
        setShowWeightWidget(false)
      }
    }

    fetchUserProfile()
  }, [])

  const handleWeightUpdate = (newDate) => {
    if (userProfile && userProfile.body_weight_widget_range > 0) {
      const daysSinceLastUpdate = Math.ceil((new Date() - newDate) / (1000 * 60 * 60 * 24))

      if (daysSinceLastUpdate >= userProfile.body_weight_widget_range) {
        setShowWeightWidget(true)
      } else {
        setShowWeightWidget(false)
      }
    } else {
      setShowWeightWidget(false)
    }
  }

  const currentMessage = t('dashboard.welcomeMessage', { firstname })

  return (
    <>
      <div className={classes.Dashboard}>
        <Navigation />
        <div className={classes.DashboardContent}>
          <div className={classes.DashboardHeader}>
            <h2>{currentMessage}</h2>
            <hr />
          </div>
          <Calendar />
          <CycleWidget />
          {showWeightWidget && <WeightWidget onWeightUpdate={handleWeightUpdate} />}
        </div>
        <Footer />
      </div>
      <NavigationMobile />
    </>
  )
}
