import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ClipLoader } from 'react-spinners'

import {
  createBugTicketWithAttachments,
  createBugTicketWithoutAttachments,
  createExerciseRequestTicket,
  createFeatureRequestTicket,
  createFeedbackTicket,
  createServiceRequestTicket
} from '../../api/communication'
import { getAllExerciseEquipments, getAllExerciseExperienceLevels, getAllMuscleGroups } from '../../api/exercise'
import Footer from '../../components/Footer/Footer'
import NavigationMobile from '../../components/Navigation/BottomNavigation/BottomNavigation'
import Navigation from '../../components/Navigation/TopNavigation/Navigation'
import Notification from '../../components/Notifications/NotificationPopUp'
import CreatableMultiSelectExercise from '../../components/Select/CreatableMultiSelectExercise'
import MultiSelectExercise from '../../components/Select/MultiSelectExercise'
import SelectExercise from '../../components/Select/SelectExercise'
import classes from './Feedback.module.css'

export const Feedback = () => {
  const { t } = useTranslation()
  const [feedbackType, setFeedbackType] = useState('Bug')
  const [summary, setSummary] = useState('')
  const [description, setDescription] = useState('')
  const [attachments, setAttachments] = useState([])
  const [rating, setRating] = useState(1)
  const [notification, setNotification] = useState({ visible: false, text: '', type: 'error' })
  const [loading, setLoading] = useState(false)
  const [exerciseDetails, setExerciseDetails] = useState({
    name: '',
    targetMuscleGroup: '',
    secondaryMuscleGroups: [],
    description: '',
    experienceLevel: '',
    equipments: [],
    pathToGif: ''
  })
  const [muscleGroupOptions, setMuscleGroupOptions] = useState([])
  const [experienceLevelOptions, setExperienceLevelOptions] = useState([])
  const [equipmentOptions, setEquipmentOptions] = useState([])

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const muscleGroups = await getAllMuscleGroups()
        const experienceLevels = await getAllExerciseExperienceLevels()
        const equipments = await getAllExerciseEquipments()

        setMuscleGroupOptions(muscleGroups.map((group) => ({ value: group, label: group })))
        setExperienceLevelOptions(experienceLevels.map((level) => ({ value: level, label: level })))
        setEquipmentOptions(equipments.map((equipment) => ({ value: equipment, label: equipment })))
      } catch (error) {
        console.error('Error fetching options:', error)
      }
    }
    fetchOptions()
  }, [])

  const handleStarClick = (index) => {
    setRating(index + 1)
  }

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files)
    if (files.length + attachments.length > 5) {
      setNotification({
        visible: true,
        text: t('feedback.maxFilesExceeded'),
        type: 'error'
      })
    } else {
      setAttachments([...attachments, ...files])
    }
  }

  const handleRemoveFile = (index) => {
    const newAttachments = attachments.filter((_, i) => i !== index)
    setAttachments(newAttachments)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (!summary || summary.length > 100) {
      setNotification({
        visible: true,
        text: t('feedback.summaryRequired'),
        type: 'error'
      })
      return
    }

    setLoading(true)

    try {
      if (feedbackType === 'Bug') {
        if (attachments.length > 0) {
          const formData = new FormData()
          formData.append('summary', summary)
          formData.append('description', description)
          attachments.forEach((file) => {
            formData.append('attachments', file)
          })
          await createBugTicketWithAttachments(formData)
        } else {
          await createBugTicketWithoutAttachments({ summary, description })
        }
      } else if (feedbackType === 'Request') {
        await createFeatureRequestTicket({ summary, description })
      } else if (feedbackType === 'Feedback') {
        await createFeedbackTicket({ summary, description, rating })
      } else if (feedbackType === 'Exercise') {
        const { name, targetMuscleGroup, secondaryMuscleGroups, experienceLevel, equipments, pathToGif } = exerciseDetails

        if (!name) {
          setNotification({
            visible: true,
            text: t('feedback.exerciseNameRequired'),
            type: 'error'
          })
          setLoading(false)
          return
        }

        if (!targetMuscleGroup) {
          setNotification({
            visible: true,
            text: t('feedback.targetMuscleGroupRequired'),
            type: 'error'
          })
          setLoading(false)
          return
        }

        await createExerciseRequestTicket({
          summary,
          exercise_name: name,
          target_musclegroup: targetMuscleGroup.value,
          secondary_musclegroups: secondaryMuscleGroups.map((group) => group.value).join(', '),
          description,
          exercise_exp_lvl: experienceLevel.value,
          exercise_equipment: equipments.map((equipment) => equipment.value).join(', '),
          path_to_gif: pathToGif
        })
      } else if (feedbackType === 'Service') {
        await createServiceRequestTicket({ summary, description })
      }

      setNotification({
        visible: true,
        text: t('feedback.success'),
        type: 'success'
      })
    } catch (error) {
      setNotification({
        visible: true,
        text: t('feedback.error'),
        type: 'error'
      })
    } finally {
      setLoading(false)
    }
  }

  const handleExerciseDetailChange = (selectedOption, { name }) => {
    setExerciseDetails((prevDetails) => ({
      ...prevDetails,
      [name]: selectedOption
    }))
  }

  const handleNotificationButtonClick = () => {
    setNotification({ visible: false, text: '', type: 'error' })
  }

  return (
    <>
      {notification.visible && (
        <Notification
          text={notification.text}
          buttons={[{ label: 'OK', action: handleNotificationButtonClick }]}
          type={notification.type}
          onButtonClick={handleNotificationButtonClick}
        />
      )}
      <div className={classes.Feedback}>
        <Navigation />
        <div className={classes.FeedbackContent}>
          <div className={classes.FeedbackHeader}>
            <div className={classes.FeedbackHeaderTop}>
              <h2>{t('feedback.headline')}</h2>
            </div>
            <hr />
          </div>
          <form onSubmit={handleSubmit}>
            <div className={classes.FormGroup}>
              <label htmlFor='feedbackType'>{t('feedback.feedbackType')}</label>
              <div className={classes.Select}>
                <select id='feedbackType' value={feedbackType} onChange={(e) => setFeedbackType(e.target.value)} className={classes.Select}>
                  <option value='Bug'>{t('feedback.type.bug')}</option>
                  <option value='Request'>{t('feedback.type.request')}</option>
                  <option value='Feedback'>{t('feedback.type.feedback')}</option>
                  <option value='Exercise'>{t('feedback.type.exercise')}</option>
                  <option value='Service'>{t('feedback.type.other')}</option>
                </select>
              </div>
            </div>

            <div className={classes.FormGroup}>
              <label htmlFor='summary'>{t('feedback.summary')}</label>
              <textarea
                id='summary'
                value={summary}
                placeholder={t('feedback.summaryPlaceholder')}
                onChange={(e) => setSummary(e.target.value)}
                maxLength={100}
                required
              />
            </div>

            <div className={classes.FormGroup}>
              <label htmlFor='description'>{t('feedback.description')}</label>
              <textarea
                id='description'
                value={description}
                placeholder={t('feedback.descriptionPlaceholder')}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>

            {feedbackType === 'Bug' && (
              <div className={classes.FormGroup}>
                <label htmlFor='attachments' className={classes.FileInputLabel}>
                  {t('feedback.attachFiles')}
                </label>
                <input
                  type='file'
                  id='attachments'
                  multiple
                  accept='image/png, image/jpeg'
                  onChange={handleFileChange}
                  className={classes.FileInput}
                />
                <div className={classes.FileList}>
                  {attachments.map((file, index) => (
                    <div key={index} className={classes.FileItem}>
                      {file.name}
                      <button type='button' onClick={() => handleRemoveFile(index)} className={classes.RemoveButton}>
                        {t('feedback.remove')}
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {feedbackType === 'Feedback' && (
              <div className={classes.FormGroup}>
                <label>{t('feedback.rating')}</label>
                <div className={classes.StarRating}>
                  {[...Array(5)].map((star, index) => {
                    const isFilled = index < rating
                    const starClass = isFilled ? `${classes.Star} ${classes.FilledStar}` : classes.Star
                    return (
                      <svg
                        key={index}
                        className={starClass}
                        data-testid={`star-${index}`}
                        onClick={() => handleStarClick(index)}
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 24 24'
                        width='24'
                        height='24'
                      >
                        <path d='M12 .587l3.668 7.568L24 9.423l-6 5.899 1.421 8.462L12 18.897 4.579 23.784 6 15.322 0 9.423l8.332-1.268z' />
                      </svg>
                    )
                  })}
                </div>
              </div>
            )}

            {feedbackType === 'Exercise' && (
              <div className={classes.ExerciseDetails}>
                <div className={classes.FormGroup}>
                  <label htmlFor='name'>{t('feedback.exercise.name')}</label>
                  <input
                    type='text'
                    id='name'
                    name='name'
                    value={exerciseDetails.name}
                    onChange={(e) => setExerciseDetails({ ...exerciseDetails, name: e.target.value })}
                    required
                  />
                </div>
                <div className={classes.SpecialFormGroup}>
                  <label htmlFor='targetMuscleGroup'>{t('feedback.exercise.targetMuscleGroup')}</label>
                  <SelectExercise
                    id='targetMuscleGroup'
                    name='targetMuscleGroup'
                    value={exerciseDetails.targetMuscleGroup}
                    onChange={(selectedOption) => handleExerciseDetailChange(selectedOption, { name: 'targetMuscleGroup' })}
                    options={muscleGroupOptions}
                    placeholder={t('feedback.selectPlaceholderDefault')}
                    isDisabled={loading}
                  />
                </div>
                <div className={classes.SpecialFormGroup}>
                  <label htmlFor='secondaryMuscleGroups'>{t('feedback.exercise.secondaryMuscleGroups')}</label>
                  <MultiSelectExercise
                    id='secondaryMuscleGroups'
                    name='secondaryMuscleGroups'
                    value={exerciseDetails.secondaryMuscleGroups}
                    onChange={(selectedOption) => handleExerciseDetailChange(selectedOption, { name: 'secondaryMuscleGroups' })}
                    options={muscleGroupOptions}
                    placeholder={t('feedback.selectPlaceholderDefault')}
                    isDisabled={loading}
                  />
                </div>
                <div className={classes.SpecialFormGroup}>
                  <label htmlFor='experienceLevel'>{t('feedback.exercise.experienceLevel')}</label>
                  <SelectExercise
                    id='experienceLevel'
                    name='experienceLevel'
                    value={exerciseDetails.experienceLevel}
                    onChange={(selectedOption) => handleExerciseDetailChange(selectedOption, { name: 'experienceLevel' })}
                    options={experienceLevelOptions}
                    placeholder={t('feedback.selectPlaceholderDefault')}
                    isDisabled={loading}
                  />
                </div>
                <div className={classes.SpecialFormGroup}>
                  <label htmlFor='equipments'>{t('feedback.exercise.equipments')}</label>
                  <CreatableMultiSelectExercise
                    id='equipments'
                    name='equipments'
                    value={exerciseDetails.equipments}
                    onChange={(selectedOption) => handleExerciseDetailChange(selectedOption, { name: 'equipments' })}
                    options={equipmentOptions}
                    placeholder={t('feedback.selectPlaceholderDefault')}
                    isDisabled={loading}
                  />
                </div>
                <div className={classes.FormGroup}>
                  <label htmlFor='pathToGif'>{t('feedback.exercise.pathToGif')}</label>
                  <input
                    type='text'
                    id='pathToGif'
                    name='pathToGif'
                    value={exerciseDetails.pathToGif}
                    onChange={(e) => setExerciseDetails({ ...exerciseDetails, pathToGif: e.target.value })}
                  />
                </div>
              </div>
            )}

            <button type='submit' disabled={loading}>
              {loading ? <ClipLoader size={20} color={''} /> : t('feedback.submit')}
            </button>
          </form>
        </div>
        <Footer />
      </div>
      <NavigationMobile />
    </>
  )
}
