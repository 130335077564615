import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getAllExercises } from '../../api/exercise'
import classes from './InfoPopUp.module.css'

const InfoPopUp = ({ trainingInfo, onClose }) => {
  const { t } = useTranslation()
  const [allExercises, setAllExercises] = useState([])
  const popupRef = useRef(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const exercisesData = await getAllExercises()
        setAllExercises(exercisesData)
      } catch (error) {
        console.error('Error fetching exercises:', error)
      }
    }
    fetchData()
  }, [])

  useEffect(() => {
    if (popupRef.current) {
      setTimeout(() => {
        const popupPosition = popupRef.current.getBoundingClientRect().top + window.scrollY
        const offsetPosition = popupPosition - window.innerHeight * 0.15
        window.scrollTo({ top: offsetPosition, behavior: 'smooth' })
      }, 100)
    }
  }, [trainingInfo])

  return (
    <>
      <div className={classes.Overlay} onClick={onClose}></div>
      <div className={classes.infoPopUp} ref={popupRef}>
        <div className={classes.popUpContent}>
          <h2>{t('report.finishedTraining.infoPopUp.trainingInformation')}</h2>
          <hr />
          <div className={classes.infoPopUpSection}>
            <p>
              <strong>{t('report.finishedTraining.infoPopUp.name')}:</strong> {trainingInfo.training_name}
            </p>
            <p>
              <strong>{t('report.finishedTraining.infoPopUp.date')}:</strong> {new Date(trainingInfo.training_date).toLocaleDateString()}
            </p>
            <p>
              <strong>{t('report.finishedTraining.infoPopUp.duration')}:</strong> {trainingInfo.training_duration}{' '}
              {t('report.finishedTraining.infoPopUp.minutes')}
            </p>
            <p>
              <strong>{t('report.finishedTraining.infoPopUp.type')}:</strong> {trainingInfo.training_type_name}
            </p>
            <p>
              <strong>{t('report.finishedTraining.infoPopUp.caloriesBurned')}:</strong> {trainingInfo.calories_burned}{' '}
              {t('report.finishedTraining.infoPopUp.kcal')}
            </p>
          </div>
          {trainingInfo.finished_exercises.map((exercise, index) => {
            const exerciseData = allExercises.find((ex) => ex.exercise_id === exercise.exercise_id)
            return (
              <div key={index} className={classes.infoPopUpSection}>
                <p>
                  <strong>{t('report.finishedTraining.infoPopUp.exerciseName')}:</strong>{' '}
                  {exerciseData ? exerciseData.exercise_name : t('report.finishedTraining.infoPopUp.unknown')}
                </p>
                {exercise.finished_exercise_duration !== 0 && (
                  <p>
                    <strong>{t('report.finishedTraining.infoPopUp.duration')}:</strong>{' '}
                    {Math.floor(exercise.finished_exercise_duration / 60)}:
                    {(exercise.finished_exercise_duration % 60).toString().padStart(2, '0')}{' '}
                    {t('report.finishedTraining.infoPopUp.minutes')}
                  </p>
                )}
                <p>
                  <strong>{t('report.finishedTraining.infoPopUp.sets')}:</strong>
                </p>
                {exercise.finished_sets.map((set, index) => (
                  <p key={index}>
                    {t('report.finishedTraining.infoPopUp.repetition')}: {set.repetition}x, {t('report.finishedTraining.infoPopUp.weight')}:{' '}
                    {set.weight}kg
                  </p>
                ))}
              </div>
            )
          })}
          <button className={classes.closeButton} onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
      </div>
    </>
  )
}

export default InfoPopUp
