import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import CookieSettings from '../Cookie/CookieSettings/CookieSettings'
import classes from './Footer.module.css'

const Footer = () => {
  const { t } = useTranslation()
  const [showCookieSettings, setShowCookieSettings] = useState(false)

  const handleCookieSettingsOpen = () => {
    setShowCookieSettings(true)
  }

  const handleCookieSettingsClose = () => {
    setShowCookieSettings(false)
  }

  return (
    <footer className={classes.Footer}>
      <div className={classes.SocialIcons}>
        <SocialIcon href='https://www.instagram.com/flexer.fitness' icon={faInstagram} />
        {/* <SocialIcon href='https://discord.com/' icon={faDiscord} />
        <SocialIcon href='https://wa.me/' icon={faWhatsapp} /> */}
      </div>

      <div className={classes.Copyright}>
        <p>{t('footer.copyright')}</p>
      </div>

      <div className={classes.LegalLinks}>
        <LegalLink to='/privacy-policy' text={t('footer.privacyPolicy')} />
        <LegalLink to='/terms-of-use' text={t('footer.termsOfUse')} />
        <button onClick={handleCookieSettingsOpen} className={classes.paragraphButton}>
          {t('footer.cookieSettings.title')}
        </button>
      </div>

      {showCookieSettings && <CookieSettings onClose={handleCookieSettingsClose} />}
    </footer>
  )
}

const SocialIcon = ({ href, icon }) => (
  <a href={href} target='_blank' rel='noopener noreferrer'>
    <FontAwesomeIcon icon={icon} />
  </a>
)

const LegalLink = ({ to, text, onClick }) => (
  <Link to={to} className={classes.LegalLink} onClick={onClick}>
    {text}
  </Link>
)

export default Footer
