import React from 'react'
import { useTranslation } from 'react-i18next'

import Footer from '../../components/Footer/Footer'
import NavigationMobile from '../../components/Navigation/BottomNavigation/BottomNavigation'
import Navigation from '../../components/Navigation/TopNavigation/Navigation'
import classes from './Health.module.css'

export const Health = () => {
  const { t } = useTranslation()

  return (
    <>
      <div className={classes.Health}>
        <Navigation />
        <div className={classes.HealthContent}>
          <div className={classes.HealthContentInfo}>
            <h1>{t('comingSoon.title')}</h1>
            <p>{t('comingSoon.description')}</p>
          </div>
        </div>
        <Footer />
      </div>
      <NavigationMobile />
    </>
  )
}
