import React, { createContext, useEffect, useState } from 'react'

export const ThemeContext = createContext()

export const ThemeProvider = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(false)

  useEffect(() => {
    const userProfile = JSON.parse(localStorage.getItem('userProfile')) || {}
    setIsDarkMode(userProfile.is_darkmode || false)
    updateTheme(userProfile.is_darkmode || false)
  }, [])

  const toggleTheme = () => {
    const newTheme = !isDarkMode
    setIsDarkMode(newTheme)
    updateTheme(newTheme)
    const userProfile = JSON.parse(localStorage.getItem('userProfile')) || {}
    userProfile.is_darkmode = newTheme
    localStorage.setItem('userProfile', JSON.stringify(userProfile))
  }

  const updateTheme = (darkMode) => {
    if (!darkMode) {
      document.documentElement.style.setProperty('--blue6', '#395886')
      document.documentElement.style.setProperty('--blue5', '#638ECB')
      document.documentElement.style.setProperty('--blue4', '#8AAEE0')
      document.documentElement.style.setProperty('--blue3', '#B1C9EF')
      document.documentElement.style.setProperty('--blue2', '#D5DEEF')
      document.documentElement.style.setProperty('--blue1', '#F0F3FA')
      document.documentElement.style.setProperty('--navigation-color', '#395886')
      document.documentElement.style.setProperty('--primary-button-color', '#395886')
      document.documentElement.style.setProperty('--tertiary-button-color', '#8AAEE0')
      document.documentElement.style.setProperty('--fourth-button-color', '#395886')
      document.documentElement.style.setProperty('--popup-color', '#395886')
      document.documentElement.style.setProperty('--popup-button-color', '#638ECB')
      document.documentElement.style.setProperty('--input-color', '#F0F3FA')
      document.documentElement.style.setProperty('--color-orange', '#FF7300')
      document.documentElement.style.setProperty('--pending-count-color', '#F0F3FA')
    } else {
      document.documentElement.style.setProperty('--blue6', '#022B42')
      document.documentElement.style.setProperty('--blue5', '#006494')
      document.documentElement.style.setProperty('--blue4', '#395886')
      document.documentElement.style.setProperty('--blue3', '#004D74')
      document.documentElement.style.setProperty('--blue2', '#395886')
      document.documentElement.style.setProperty('--blue1', '#B3B3B3')
      document.documentElement.style.setProperty('--navigation-color', '#20324d')
      document.documentElement.style.setProperty('--primary-button-color', '#395886')
      document.documentElement.style.setProperty('--tertiary-button-color', '#294264')
      document.documentElement.style.setProperty('--fourth-button-color', '#294264')
      document.documentElement.style.setProperty('--popup-color', '#20324d')
      document.documentElement.style.setProperty('--popup-button-color', '#395886')
      document.documentElement.style.setProperty('--input-color', 'lightgrey')
      document.documentElement.style.setProperty('--color-orange', '#FF7300')
      document.documentElement.style.setProperty('--pending-count-color', 'black')
    }
  }

  return <ThemeContext.Provider value={{ isDarkMode, toggleTheme }}>{children}</ThemeContext.Provider>
}
