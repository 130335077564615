import { faCalendarDays, faCalendarWeek, faCheckCircle, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ClipLoader } from 'react-spinners' // Importing the spinner
import { Tooltip } from 'react-tooltip'

import { getAllFinishedTrainings } from '../../../api/finishedTraining'
import { countTrainingDaily } from '../../../api/reporting'
import classes from './Calendar.module.css'

const Calendar = () => {
  const [trainingData, setTrainingData] = useState([])
  const [viewMode, setViewMode] = useState('weekly')
  const [currentDate, setCurrentDate] = useState(new Date())
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [loading, setLoading] = useState(true) // Loading state
  const { t } = useTranslation()
  const today = new Date()
  const weekdays = [
    t('dashboard.calendar.days.MO'),
    t('dashboard.calendar.days.DI'),
    t('dashboard.calendar.days.MI'),
    t('dashboard.calendar.days.DO'),
    t('dashboard.calendar.days.FR'),
    t('dashboard.calendar.days.SA'),
    t('dashboard.calendar.days.SO')
  ]

  useEffect(() => {
    const fetchTrainingData = async () => {
      setLoading(true) // Set loading to true when fetching starts
      try {
        const dailyTrainingData = await countTrainingDaily()

        const allFinishedTrainings = await getAllFinishedTrainings()

        const finishedTrainingsByDate = allFinishedTrainings.reduce((acc, training) => {
          const date = training.training_date
          if (!acc[date]) {
            acc[date] = []
          }
          acc[date].push(training)
          return acc
        }, {})

        const detailedData = dailyTrainingData.map((item) => {
          const details = finishedTrainingsByDate[item.date] || []
          return { ...item, details }
        })

        setTrainingData(detailedData)
      } catch (error) {
        console.error('Failed to fetch training data:', error)
      } finally {
        setLoading(false) // Set loading to false when fetching is complete
      }
    }

    fetchTrainingData()
  }, [])

  const hasTraining = (date) => {
    const dateString = toLocalISOString(date)
    return trainingData.some((data) => data.date === dateString && data.training_count > 0)
  }

  const handleNext = () => {
    setCurrentDate((prevDate) => {
      const nextDate = new Date(prevDate)
      viewMode === 'monthly' ? nextDate.setMonth(prevDate.getMonth() + 1) : nextDate.setDate(prevDate.getDate() + 7)
      return nextDate
    })
  }

  const handlePrev = () => {
    setCurrentDate((prevDate) => {
      const prevDateCopy = new Date(prevDate)
      viewMode === 'monthly' ? prevDateCopy.setMonth(prevDate.getMonth() - 1) : prevDateCopy.setDate(prevDate.getDate() - 7)
      return prevDateCopy
    })
  }

  const handleToggleView = () => {
    setViewMode((prevViewMode) => {
      const newViewMode = prevViewMode === 'monthly' ? 'weekly' : 'monthly'
      if (newViewMode === 'weekly') {
        setCurrentDate(new Date()) // Reset to current week
      } else {
        setCurrentDate(selectedDate) // Use selected date in monthly view
      }
      return newViewMode
    })
  }

  const handleDateChange = (event) => {
    const newDate = new Date(event.target.value)
    setSelectedDate(newDate)
    setCurrentDate(newDate)
  }

  const toLocalISOString = (date) => {
    const year = date.getFullYear()
    const month = ('0' + (date.getMonth() + 1)).slice(-2)
    const day = ('0' + date.getDate()).slice(-2)
    return `${year}-${month}-${day}`
  }

  const renderWeeklyView = () => {
    const todayIndex = today.getDay()
    const weekStart = new Date(today)
    const diffDays = todayIndex === 0 ? 6 : todayIndex - 1
    weekStart.setDate(today.getDate() - diffDays)

    return (
      <div className={classes.WeeklyView}>
        <div className={classes.WeekContainer}>
          {weekdays.map((day, index) => {
            const date = new Date(weekStart)
            date.setDate(weekStart.getDate() + index)
            const isCurrentDate = date.toDateString() === today.toDateString()
            const hasTrainingToday = hasTraining(date)
            const tooltipId = `tooltip-${index}`

            const trainingDataForDate = trainingData.find((data) => data.date === toLocalISOString(date))
            const trainingCount = trainingDataForDate?.training_count || 0

            const tooltipContent = `${t('dashboard.calendar.tooltip.trainingDone')}: ${trainingCount}x</br>
            ${
      trainingCount > 0
        ? trainingDataForDate.details
          .map(
            (detail, index, array) => `
            </br>${t('dashboard.calendar.tooltip.name')}: ${detail.training_name ? detail.training_name : t('common.unit.N/A')} </br>
            ${t('dashboard.calendar.tooltip.type')}: ${detail.training_type_name ? detail.training_type_name : t('common.unit.N/A')} </br>
            ${t('dashboard.calendar.tooltip.duration')}: ${detail.training_duration > 0 ? `${detail.training_duration} ${t('common.unit.min')}` : t('common.unit.N/A')} </br>
            ${index < array.length - 1 ? '' : ''}
            `
          )
          .join('')
        : ''
      }`

            return (
              <div
                key={day}
                className={`${classes.DayContainer} ${isCurrentDate && classes.CurrentDate}`}
                data-tooltip-id={tooltipId}
                data-tooltip-html={tooltipContent}
              >
                {day}
                <div>{date.getDate()}</div>
                {hasTrainingToday && (
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className={`${classes.CheckIcon} ${viewMode === 'weekly' ? classes.CheckIconWeekly : classes.CheckIconMonthly}`}
                  />
                )}
                <Tooltip className={classes.TooltipContent} id={tooltipId} place='bottom' effect='solid' />
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  const renderMonthlyView = () => {
    const daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate()
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getDay()
    const emptySlots = firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1
    const totalSlots = daysInMonth + emptySlots
    const slotsArray = Array.from({ length: totalSlots }, (_, i) => (i >= emptySlots ? i - emptySlots + 1 : null))

    return (
      <div className={classes.MonthlyView}>
        <div className={classes.MonthContainer}>
          <div className={classes.WeekdaysContainer}>
            {weekdays.map((day, index) => (
              <div key={index} className={classes.Day}>
                {day}
              </div>
            ))}
          </div>
          <div className={classes.DayContainer}>
            {slotsArray.map((day, index) => {
              if (day === null) {
                return <div key={index} className={`${classes.Day} ${classes.Empty}`} />
              }
              const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), day)
              const isCurrentDate = date.toDateString() === today.toDateString()
              const hasTrainingDay = hasTraining(date)
              const tooltipId = `tooltip-${index + weekdays.length}`

              const trainingDataForDate = trainingData.find((data) => data.date === toLocalISOString(date))
              const trainingCount = trainingDataForDate?.training_count || 0

              const tooltipContent = `${t('dashboard.calendar.tooltip.trainingDone')}: ${trainingCount}x</br>
              ${
      trainingCount > 0
        ? trainingDataForDate.details
          .map(
            (detail, index, array) => `
              </br>${t('dashboard.calendar.tooltip.name')}: ${detail.training_name ? detail.training_name : t('common.unit.N/A')} </br>
              ${t('dashboard.calendar.tooltip.type')}: ${detail.training_type_name ? detail.training_type_name : t('common.unit.N/A')} </br>
              ${t('dashboard.calendar.tooltip.duration')}: ${detail.training_duration > 0 ? `${detail.training_duration} ${t('common.unit.min')}` : t('common.unit.N/A')} </br>
              ${index < array.length - 1 ? '' : ''}
              `
          )
          .join('')
        : ''
      }`

              return (
                <div
                  key={index}
                  className={`${classes.Day} ${isCurrentDate && classes.CurrentDate}`}
                  data-tooltip-id={tooltipId}
                  data-tooltip-html={tooltipContent}
                >
                  {day}
                  {hasTrainingDay && (
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className={`${classes.CheckIcon} ${viewMode === 'weekly' ? classes.CheckIconWeekly : classes.CheckIconMonthly}`}
                    />
                  )}
                  <Tooltip className={classes.TooltipContent} id={tooltipId} place='bottom' effect='solid' />
                </div>
              )
            })}
          </div>
        </div>
        {viewMode === 'monthly' && (
          <div className={classes.PrevNextButton}>
            <button onClick={handlePrev}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
            <button onClick={handleNext}>
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
          </div>
        )}
      </div>
    )
  }

  return (
    <div className={classes.CalendarContainer}>
      {loading ? (
        <div className={classes.LoaderContainer}>
          <ClipLoader color={''} loading={loading} size={50} />
        </div>
      ) : (
        <>
          <div className={classes.ViewChangeButton}>
            <div className={classes.DateInput}>
              {viewMode === 'monthly' ? (
                <input type='date' value={toLocalISOString(currentDate)} onChange={handleDateChange} className={classes.DateSelector} />
              ) : (
                <>
                  <p>{currentDate.toLocaleString('default', { day: 'numeric', month: 'long', year: 'numeric' })}</p>
                </>
              )}
            </div>
            <button onClick={handleToggleView}>
              <FontAwesomeIcon icon={viewMode === 'monthly' ? faCalendarDays : faCalendarWeek} />
            </button>
          </div>
          <div className={classes.CalendarContent}>{viewMode === 'monthly' ? renderMonthlyView() : renderWeeklyView()}</div>
        </>
      )}
    </div>
  )
}

export default Calendar
