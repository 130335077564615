// SelectTrainingPlanPopUp.jsx
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getAllTrainingPlans } from '../../../api/trainingPlan'
import classes from './SelectTrainingPlanPopUp.module.css'

const SelectTrainingPlanPopUp = ({ onSelectTrainingPlan, onClose, useAbsolutePosition }) => {
  const [trainingPlans, setTrainingPlans] = useState([])
  const { t } = useTranslation()

  useEffect(() => {
    fetchTrainingPlans()
  }, [])

  const fetchTrainingPlans = async () => {
    try {
      const plans = await getAllTrainingPlans()
      setTrainingPlans(plans)
    } catch (error) {
      console.error('Error fetching training plans:', error)
    }
  }

  const handleOverlayClick = () => {
    onClose()
  }

  const handleButtonClick = (trainingPlan) => {
    const selectedTrainingPlan = trainingPlan
    localStorage.setItem('selectedTrainingPlanName', JSON.stringify(selectedTrainingPlan.trainingplan_name))
    onSelectTrainingPlan(selectedTrainingPlan)
    onClose()
  }

  return (
    <>
      <div className={classes.Overlay} onClick={handleOverlayClick}></div>
      <div className={useAbsolutePosition ? classes.AbsolutePosition : classes.RelativePosition}>
        <div className={classes.TrainingPlanContainer}>
          <div className={classes.TrainingPlanHeader}>
            <h3>{t('training.selectTrainingsplan.headline')}</h3>
            <hr />
            <button className={classes.CloseButton} onClick={onClose}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          <div className={classes.TrainingPlanContent}>
            {trainingPlans.map((trainingPlan) => (
              <button
                key={trainingPlan.trainingplan_id}
                className={classes.TrainingPlanItem}
                onClick={() => handleButtonClick(trainingPlan)}
              >
                {trainingPlan.trainingplan_name}
              </button>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default SelectTrainingPlanPopUp
