import { getUserProfile } from '../../../api/user'
import i18n from '../../../i18n'

let hasShownWeightNotification = false

export const MET_VALUES = {
  Lauftraining: 8, // Running training
  walking: 3.9, // Walking
  cycling: 7.5, // Cycling
  Krafttraining: 6, // Strength training
  'Strength training': 6, // Strength training (English)
  'Running training': 8, // Running training (English)
  Fussball: 7, // Football
  Football: 7, // Football (English)
  Radfahren: 7.5, // Cycling (German)
  Cycling: 7.5, // Cycling (English)
  Schwimmen: 7, // Swimming
  Swimming: 7, // Swimming (English)
  Sonstiges: 4, // Other
  Other: 4 // Other (English)
}

export const calculateCalories = async (trainingType, durationInSeconds, setNotification) => {
  try {
    let weight = localStorage.getItem('weight')

    if (!weight) {
      const data = await getUserProfile()
      weight = data.weight

      if (!weight && !hasShownWeightNotification) {
        setNotification(i18n.t('training.notification.setWeight'), 'alert')
        hasShownWeightNotification = true
      }

      if (!weight) {
        return 0
      }

      localStorage.setItem('weight', weight)
    } else {
      weight = parseFloat(weight)
    }

    const MET = MET_VALUES[trainingType]

    const caloriesPerMinute = (MET * weight * 3.5) / 200
    const totalTime = durationInSeconds / 60
    const totalCalories = caloriesPerMinute * totalTime
    return totalCalories.toFixed(2)
  } catch (error) {
    console.error('Error fetching user profile:', error)
    setNotification(i18n.t('training.notification.errorFetchingProfile'), 'error')
    return 0
  }
}
