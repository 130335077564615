import axios from 'axios'
import Cookies from 'js-cookie'

import { API_URL } from './config'

export const apiClient = axios.create({
  baseURL: API_URL,
  withCredentials: true // Include cookies in requests
})

// Interceptor to handle token refresh
apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      try {
        const { data } = await apiClient.post('/auth/refresh')
        localStorage.setItem('access_token', data.access_token)
        apiClient.defaults.headers.common['Authorization'] = `Bearer ${data.access_token}`
        originalRequest.headers['Authorization'] = `Bearer ${data.access_token}`
        return apiClient(originalRequest)
      } catch (err) {
        await logoutUser()
        return Promise.reject(err)
      }
    }
    return Promise.reject(error)
  }
)

export const loginUser = async (username, password) => {
  const credentials = new URLSearchParams({
    username: username,
    password: password
  })

  const response = await apiClient.post('/auth/token', credentials.toString(), {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })

  if (response.status === 200) {
    localStorage.setItem('access_token', response.data.access_token)
    apiClient.defaults.headers.common['Authorization'] = `Bearer ${response.data.access_token}`
    return response.data
  } else {
    throw new Error('Failed to login.')
  }
}

export const createUser = async (userData) => {
  const response = await apiClient.post('/auth/register', userData, {
    headers: {
      'Content-Type': 'application/json'
    }
  })

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error('Failed to register.')
  }
}

export const logoutUser = async () => {
  try {
    await apiClient.post('/auth/logout')
    localStorage.removeItem('access_token')
    Cookies.remove('refresh_token')
  } catch (error) {
    console.error('Error logging out:', error)
    throw new Error('Failed to logout.')
  }
}

export const isAuthenticated = async () => {
  const token = localStorage.getItem('access_token')

  if (token) {
    try {
      const response = await apiClient.get('/auth/verify-token', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return response.data.authenticated
    } catch (error) {
      console.error('Error verifying token:', error)
      return false
    }
  } else {
    return false
  }
}
