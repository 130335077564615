import { apiClient } from './auth'

export const createTrainingCycle = async (newTrainingCycle) => {
  const token = localStorage.getItem('access_token')
  const response = await apiClient.post('/training_cycle/save_training_cycle', newTrainingCycle, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })
  return response.data
}

export const getAllTrainingCycles = async () => {
  const token = localStorage.getItem('access_token')
  const response = await apiClient.get('/training_cycle/all_training_cycles', {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return response.data
}

export const getCurrentActiveTrainingCycle = async () => {
  const token = localStorage.getItem('access_token')
  const response = await apiClient.get('/training_cycle/current_training_cycle', {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return response.data
}

export const getAllTrainingCyclesByTrainingPlanId = async (trainingPlanId) => {
  const token = localStorage.getItem('access_token')
  const response = await apiClient.get(`/training_cycle/all_training_cycles_by_trainingplan_id/${trainingPlanId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return response.data
}

export const getTrainingCycleById = async (trainingCycleId) => {
  const token = localStorage.getItem('access_token')
  const response = await apiClient.get(`/training_cycle/training_cycle_by_id/${trainingCycleId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return response.data
}

export const setTrainingCycleStatusToActive = async (trainingCycleId) => {
  const token = localStorage.getItem('access_token')
  const url = `/training_cycle/training_cycle_status_to_active/${trainingCycleId}`
  const response = await apiClient.patch(
    url,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
  return response.data
}

export const abortActiveTrainingCycle = async () => {
  const token = localStorage.getItem('access_token')
  const response = await apiClient.patch(
    '/training_cycle/abort_active_training_cycle',
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
  return response.data
}

export const updateTrainingCycle = async (trainingCycleId, updatedTrainingCycle) => {
  const token = localStorage.getItem('access_token')
  const response = await apiClient.patch(`/training_cycle/update_training_cycle/${trainingCycleId}`, updatedTrainingCycle, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })
  return response.data
}

export const deleteTrainingCycle = async (trainingCycleId) => {
  const token = localStorage.getItem('access_token')
  const response = await apiClient.delete(`/training_cycle/delete_training_cycle/${trainingCycleId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  if (response.status === 200) {
    return response.data
  } else {
    throw new Error('Failed to delete training cycle.')
  }
}
