import { faArrowLeft, faArrowRight, faCheckCircle, faCircle, faInfoCircle, faSave, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'

import { createTrainingCycle, updateTrainingCycle } from '../../../api/cycle'
import { getAllExercises } from '../../../api/exercise'
import { postTrainingPlan } from '../../../api/trainingPlan'
import { getAllTrainingTypes } from '../../../api/trainingType'
import Notification from '../../Notifications/NotificationPopUp'
import SelectTrainingPlanPopUp from '../ExercisePopUps/SelectTrainingPlanPopUp'
import TrainingPlanPopup from '../TrainingPlanPopUp/TrainingPlanPopUp'
import classes from './TrainingCyclePopUp.module.css'

const TrainingCyclePopup = ({ isOpen, onClose, onCycleSaved, cycle, isEdit }) => {
  const [currentStep, setCurrentStep] = useState(1)
  const [completedStep, setCompletedStep] = useState(0)
  const [cycleName, setCycleName] = useState('')
  const [cycleFrequency, setCycleFrequency] = useState(1)
  const [selectedTrainingPlans, setSelectedTrainingPlans] = useState([])
  const [isSelectPopupOpen, setSelectPopupOpen] = useState(false)
  const [isPopupOpen, setPopupOpen] = useState(false)
  const [currentTrainingPlan, setCurrentTrainingPlan] = useState({ trainingplan_name: '', training_type_name: '', exercises: [] })
  const [trainingTypes, setTrainingTypes] = useState([])
  const [exercises, setExercises] = useState([])
  const [selectedTrainingType, setSelectedTrainingType] = useState('')
  const [selectedExercises, setSelectedExercises] = useState([])
  const [nameError, setNameError] = useState(false)
  const [typeError, setTypeError] = useState(false)
  const [exerciseError, setExerciseError] = useState(false)
  const [isAddPopup, setIsAddPopup] = useState(true)
  const [saveError, setSaveError] = useState(false)
  const [saveSuccess, setSaveSuccess] = useState(false)
  const { t } = useTranslation()

  const popupRef = useRef(null)

  useEffect(() => {
    if (isEdit) {
      setCycleName(cycle.training_cycle_name)
      setCycleFrequency(cycle.repeat_rate)
      setSelectedTrainingPlans(cycle.planned_trainingplans_days.map((plan) => ({ ...plan, uuid: uuidv4() })))
    } else {
      setCycleName('')
      setCycleFrequency(1)
      setSelectedTrainingPlans([])
    }
  }, [cycle])

  useEffect(() => {
    Promise.all([getAllExercises(), getAllTrainingTypes()])
      .then(([exercisesData, trainingTypesData]) => {
        setExercises(exercisesData)
        setTrainingTypes(trainingTypesData)
      })
      .catch((error) => console.error('Error fetching data:', error))
  }, [])

  useEffect(() => {
    if (isPopupOpen && popupRef.current) {
      setTimeout(() => {
        const popupPosition = popupRef.current.getBoundingClientRect().top + window.scrollY
        const offsetPosition = popupPosition - window.innerHeight * -0.75
        window.scrollTo({ top: offsetPosition, behavior: 'smooth' })
      }, 100)
    } else if (isSelectPopupOpen && popupRef.current) {
      setTimeout(() => {
        const popupPosition = popupRef.current.getBoundingClientRect().top + window.scrollY
        const offsetPosition = popupPosition - window.innerHeight * -0.75
        window.scrollTo({ top: offsetPosition, behavior: 'smooth' })
      }, 100)
    } else {
      const popupPosition = popupRef.current?.getBoundingClientRect().top + window.scrollY
      const offsetPosition = popupPosition - window.innerHeight * 0.15
      window.scrollTo({ top: offsetPosition, behavior: 'smooth' })
    }
  }, [isPopupOpen, isSelectPopupOpen])

  const handleNextStep = () => {
    setCompletedStep(currentStep)
    setCurrentStep((prevStep) => prevStep + 1)
  }

  const handlePrevStep = () => {
    setCurrentStep((prevStep) => prevStep - 1)
    setCompletedStep((prevStep) => prevStep - 2)
  }

  const handleStepClick = (stepNumber) => {
    setCurrentStep(stepNumber)
    setCompletedStep(stepNumber - 1)
  }

  const handleSelectTrainingPlan = (trainingPlan) => {
    setSelectedTrainingPlans((prevSelectedPlans) => {
      if (!prevSelectedPlans.some((plan) => plan.trainingplan_id === trainingPlan.trainingplan_id || plan.uuid === trainingPlan.uuid)) {
        return [...prevSelectedPlans, { ...trainingPlan, uuid: trainingPlan.uuid || uuidv4() }]
      }
      return prevSelectedPlans
    })
  }

  const handleRemoveTrainingPlan = (uuid) => {
    setSelectedTrainingPlans((prevSelectedPlans) => prevSelectedPlans.filter((plan) => plan.uuid !== uuid))
  }

  const openAddPopup = () => {
    setCurrentTrainingPlan({ trainingplan_name: '', training_type_name: '', exercises: [] })
    setSelectedTrainingType('')
    setSelectedExercises([])
    setIsAddPopup(true)
    setPopupOpen(true)
  }

  const openSelectPopup = () => {
    setSelectPopupOpen(true)
  }

  const saveTrainingPlan = async () => {
    try {
      const hasNameError = !currentTrainingPlan.trainingplan_name.trim()
      const hasTypeError = !selectedTrainingType.trim()
      const hasExerciseError = selectedExercises.length === 0

      setNameError(hasNameError)
      setTypeError(hasTypeError)
      setExerciseError(hasExerciseError)

      if (hasNameError || hasTypeError || hasExerciseError) return

      const exercisesData = selectedExercises.map((exerciseId) => ({
        exercise_id: exerciseId,
        sets: []
      }))

      const trainingPlanData = {
        trainingplan_name: currentTrainingPlan.trainingplan_name,
        training_type_name: selectedTrainingType,
        exercises: exercisesData
      }

      const savedTrainingPlan = await postTrainingPlan(trainingPlanData)
      handleSelectTrainingPlan(savedTrainingPlan)
      setPopupOpen(false)
    } catch (error) {
      console.error('Error saving training plan:', error)
    }
    window.scrollTo({ top: window.innerHeight * 0.15, behavior: 'smooth' })
  }

  const movePlan = (dragIndex, hoverIndex) => {
    const dragPlan = selectedTrainingPlans[dragIndex]
    const newPlans = [...selectedTrainingPlans]
    newPlans.splice(dragIndex, 1)
    newPlans.splice(hoverIndex, 0, dragPlan)
    setSelectedTrainingPlans(newPlans)
  }

  const createPausePlan = () => {
    const pausePlan = {
      trainingplan_name: t('report.trainingCycle.trainingCyclePopUp.restDay'),
      training_type_name: 'Rest',
      exercises: [],
      uuid: uuidv4()
    }
    setSelectedTrainingPlans((prevPlans) => [...prevPlans, pausePlan])
  }

  const saveTrainingCycle = async () => {
    try {
      if (!cycleName.trim()) {
        setSaveError(t('report.trainingCycle.trainingCyclePopUp.notification.error.nameRequired'))
        return
      }

      if (cycleFrequency < 1) {
        setSaveError(t('report.trainingCycle.trainingCyclePopUp.notification.error.cycleFrequencyRequired'))
        return
      }

      const allPlansAreRest = selectedTrainingPlans.every((plan) => plan.training_type_name === 'Rest')

      if (selectedTrainingPlans.length === 0 || allPlansAreRest) {
        setSaveError(t('report.trainingCycle.trainingCyclePopUp.notification.error.trainingPlanRequired'))
        return
      }

      const currentDate = new Date().toISOString().split('T')[0]
      const trainingCycleData = {
        training_cycle_name: cycleName,
        repeat_rate: cycleFrequency,
        created_at: currentDate,
        trainingplans_days: selectedTrainingPlans
          .map((plan, index) => ({
            trainingplan_id: plan.trainingplan_id,
            training_day: index + 1
          }))
          .filter((plan) => plan.trainingplan_id !== undefined)
      }

      if (isEdit) {
        await updateTrainingCycle(cycle.training_cycle_id, trainingCycleData)
      } else {
        await createTrainingCycle(trainingCycleData)
      }
      setSaveSuccess(t('trainingCyclePopUp.notification.success.saved'))
      resetValues()
      onClose()
      onCycleSaved()
    } catch (error) {
      setSaveError(t('trainingCyclePopUp.notification.error.saveFailed'))
      console.error('Error saving training cycle:', error)
    }
  }

  const resetValues = () => {
    setCycleName('')
    setCycleFrequency(1)
    setSelectedTrainingPlans([])
    setSaveError('')
    setSaveSuccess('')
    setCurrentStep(1)
  }

  const PlanItem = ({ plan, index, movePlan }) => {
    const ref = React.useRef(null)
    const [, drop] = useDrop({
      accept: 'plan',
      hover(item, monitor) {
        if (!ref.current) {
          return
        }
        const dragIndex = item.index
        const hoverIndex = index

        if (dragIndex === hoverIndex) {
          return
        }

        const hoverBoundingRect = ref.current.getBoundingClientRect()
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
        const clientOffset = monitor.getClientOffset()
        const hoverClientY = clientOffset.y - hoverBoundingRect.top

        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return
        }

        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return
        }

        movePlan(dragIndex, hoverIndex)
        item.index = hoverIndex
      }
    })

    const [{ isDragging }, drag] = useDrag({
      type: 'plan',
      item: { type: 'plan', index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging()
      })
    })

    drag(drop(ref))

    return (
      <div ref={ref} className={classes.SelectedTrainingPlanListItem} style={{ opacity: isDragging ? 0.5 : 1 }}>
        {plan.trainingplan_name}
        <button className={classes.RoundButton} onClick={() => handleRemoveTrainingPlan(plan.uuid)}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>
    )
  }

  if (!isOpen) return null

  const handleSelectPopupClose = () => {
    setSelectPopupOpen(false)
    window.scrollTo({ top: window.innerHeight * 0.15, behavior: 'smooth' })
  }

  const handlePopupClose = () => {
    setPopupOpen(false)
    window.scrollTo({ top: window.innerHeight * 0.15, behavior: 'smooth' })
  }

  return (
    <DndProvider backend={HTML5Backend}>
      {(nameError || typeError || exerciseError || saveError || saveSuccess) && (
        <Notification
          text={
            nameError
              ? 'Name is a required field.'
              : typeError
                ? 'Type is a required field.'
                : exerciseError
                  ? 'At least one Exercise is a required field.'
                  : saveError
                    ? saveError
                    : saveSuccess
                      ? 'Saved successfully.'
                      : ''
          }
          buttons={[
            {
              label: t('common.ok'),
              action: 'close'
            }
          ]}
          type={saveSuccess ? 'success' : 'error'}
          onButtonClick={(action) => {
            if (action === 'close') {
              setNameError(false)
              setTypeError(false)
              setExerciseError(false)
              setSaveError(false)
              setSaveSuccess(false)
            }
          }}
        />
      )}
      <div className={classes.Overlay} onClick={onClose}></div>
      <div className={classes.Popup} ref={popupRef}>
        <div className={classes.PopupHeader}>
          <div className={classes.InnerPopupHeader}>
            <h3>
              {isEdit ? t('report.trainingCycle.trainingCyclePopUp.editCycle') : t('report.trainingCycle.trainingCyclePopUp.createCycle')}
            </h3>
            <hr />
          </div>
          <button className={classes.RoundButton} onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        <div className={classes.StepIndicatorContainer}>
          <div className={classes.StepIndicator}>
            {[1, 2, 3, 4].map((stepNumber) => (
              <div key={stepNumber} className={classes.StepLabel} onClick={() => handleStepClick(stepNumber)}>
                <span className={classes.stepLabel}>{t('report.trainingCycle.trainingCyclePopUp.steps.step', { stepNumber })}</span>
                <FontAwesomeIcon
                  icon={completedStep >= stepNumber ? faCheckCircle : faCircle}
                  className={`${currentStep >= stepNumber ? classes.Active : ''} ${completedStep >= stepNumber ? classes.CheckIcon : ''}`}
                />
              </div>
            ))}
          </div>
          <div className={classes.StepIndicatorProgessbar}>
            <div className={classes.StepIndicatorProgessbarInner} style={{ width: `${(currentStep / 4) * 100}%` }} />
          </div>
        </div>
        <div className={classes.PopupContent}>
          {currentStep === 1 && (
            <div className={classes.Step}>
              <h4>{t('report.trainingCycle.trainingCyclePopUp.steps.1.headline')}</h4>
              <label>
                {t('report.trainingCycle.trainingCyclePopUp.steps.1.cycleName')}:
                <input
                  type='text'
                  name='cycleName'
                  placeholder={t('report.trainingCycle.trainingCyclePopUp.steps.1.enterNamePlaceholder')}
                  value={cycleName}
                  onChange={(e) => setCycleName(e.target.value)}
                />
              </label>
              <label>
                {t('report.trainingCycle.trainingCyclePopUp.steps.1.cycleFrequency')}:
                <div className={classes.Select}>
                  <select name='cycleFrequency' value={cycleFrequency} onChange={(e) => setCycleFrequency(parseInt(e.target.value))}>
                    {[...Array(10).keys()].map((value) => (
                      <option key={value + 1} value={value + 1}>
                        {value + 1} x
                      </option>
                    ))}
                  </select>
                </div>
              </label>
            </div>
          )}
          {currentStep === 2 && (
            <div className={classes.Step}>
              <h4>{t('report.trainingCycle.trainingCyclePopUp.steps.2.headline')}</h4>
              <div className={classes.ButtonContainer}>
                <button className={classes.LongButton} onClick={openSelectPopup}>
                  {t('report.trainingCycle.trainingCyclePopUp.steps.2.selectFromExisting')}
                </button>
                <button className={classes.LongButton} onClick={openAddPopup}>
                  {t('report.trainingCycle.trainingCyclePopUp.steps.2.createNewTrainingPlan')}
                </button>
                <h4>{t('report.trainingCycle.trainingCyclePopUp.steps.2.selectedTrainingPlans')}:</h4>
                <div className={classes.SelectedTrainingPlanList}>
                  {selectedTrainingPlans.map((plan, index) => (
                    <PlanItem key={plan.uuid} index={index} plan={plan} movePlan={movePlan} />
                  ))}
                </div>
              </div>
            </div>
          )}
          {currentStep === 3 && (
            <div className={classes.Step} id={classes.Step3}>
              <div className='draggable noSelect' title='Drag to reorder'>
                <h4>{t('report.trainingCycle.trainingCyclePopUp.steps.3.headline')}</h4>
                <div className={classes.SelectedTrainingPlanList}>
                  {selectedTrainingPlans.map((plan, index) => (
                    <div key={plan.uuid} className={classes.PlanItem}>
                      <span>{index + 1}</span>
                      <PlanItem index={index} plan={plan} movePlan={movePlan} />
                    </div>
                  ))}
                </div>
                <div className={classes.ButtonContainerStep3}>
                  <button className={classes.LongButton} onClick={createPausePlan}>
                    {t('report.trainingCycle.trainingCyclePopUp.steps.3.addRestDay')}
                  </button>
                </div>
              </div>
              <div className={classes.InfoText}>
                <FontAwesomeIcon icon={faInfoCircle} data-tip data-for='infoTooltip' />
                <span>{t('report.trainingCycle.trainingCyclePopUp.steps.3.dragInfo')}</span>
              </div>
            </div>
          )}
          {currentStep === 4 && (
            <div id={classes.Step4} className={classes.Step}>
              <h4>{t('report.trainingCycle.trainingCyclePopUp.steps.4.headline')}</h4>
              <div className={classes.Preview}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <h4>{t('report.trainingCycle.trainingCyclePopUp.steps.1.cycleName')}:</h4>
                  <p>{cycleName}</p>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <h4>{t('report.trainingCycle.trainingCyclePopUp.steps.1.cycleFrequency')}:</h4>
                  <p>
                    {cycleFrequency} {t('common.unit.times')}
                  </p>
                </div>
                <h4>{t('report.trainingCycle.trainingCyclePopUp.steps.2.selectedTrainingPlans')}:</h4>
                <div className={classes.SelectedTrainingPlanList}>
                  {selectedTrainingPlans.map((plan, index) => (
                    <div key={plan.uuid} className={classes.PlanItem}>
                      <span>
                        {t('report.trainingCycle.trainingCyclePopUp.day')} {index + 1}:
                      </span>{' '}
                      {plan.trainingplan_name}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={classes.PopupFooter}>
          {currentStep > 1 && (
            <button className={classes.RoundButton} onClick={handlePrevStep}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </button>
          )}
          {currentStep < 4 && (
            <button className={classes.RoundButton} onClick={handleNextStep}>
              <FontAwesomeIcon icon={faArrowRight} />
            </button>
          )}
          {currentStep === 4 && (
            <button className={classes.RoundButton} onClick={saveTrainingCycle}>
              <FontAwesomeIcon icon={faSave} />
            </button>
          )}
        </div>
      </div>
      {isSelectPopupOpen && (
        <SelectTrainingPlanPopUp
          onSelectTrainingPlan={handleSelectTrainingPlan}
          onClose={handleSelectPopupClose}
          useAbsolutePosition={false}
        />
      )}
      {isPopupOpen && (
        <TrainingPlanPopup
          isOpen={isPopupOpen}
          isAddPopup={isAddPopup}
          currentTrainingPlan={currentTrainingPlan}
          trainingTypes={trainingTypes}
          selectedTrainingTypeId={trainingTypes.find((type) => type.training_type_name === selectedTrainingType)?.training_type_id}
          exercises={exercises}
          selectedTrainingType={selectedTrainingType}
          selectedExercises={selectedExercises}
          setCurrentTrainingPlan={setCurrentTrainingPlan}
          setSelectedTrainingType={setSelectedTrainingType}
          setSelectedExercises={setSelectedExercises}
          saveTrainingPlan={saveTrainingPlan}
          closePopup={handlePopupClose}
        />
      )}
    </DndProvider>
  )
}

export default TrainingCyclePopup
