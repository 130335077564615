import React from 'react'
import { useTranslation } from 'react-i18next'

import Footer from '../../components/Footer/Footer'
import NavigationMobile from '../../components/Navigation/BottomNavigation/BottomNavigation'
import Navigation from '../../components/Navigation/TopNavigation/Navigation'
import classes from './Chat.module.css'

export const Chat = () => {
  const { t } = useTranslation()

  return (
    <>
      <div className={classes.Chat}>
        <Navigation />
        <div className={classes.ChatContent}>
          <div className={classes.ChatHeader}>
            <h2>{t('chat.headline')}</h2>
            <hr />
            <div>
              <h1>{t('comingSoon.title')}</h1>
              <p>{t('comingSoon.description')}</p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <NavigationMobile />
    </>
  )
}
