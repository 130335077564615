import {
  faAdd,
  faChartLine,
  faComment,
  faComments,
  faHome,
  faListCheck,
  faPeopleGroup,
  faRunning,
  faTimes
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { animated, useSpring } from 'react-spring'

import { getAllPendingFriendRequests } from '../../../api/friend' // Import the API call
import AddPostPopUp from '../../PopUp/AddPostPopUp/AddPostPopUp'
import classes from './BottomNavigation.module.css'

function NavigationMobile() {
  const [expanded, setExpanded] = useState(false)
  const [animationComplete, setAnimationComplete] = useState(false)
  const [showAddPostPopup, setShowAddPostPopup] = useState(false) // State to control the visibility of AddPostPopUp
  const [pendingRequestsCount, setPendingRequestsCount] = useState(0) // State to store the pending requests count
  const location = useLocation()

  useEffect(() => {
    fetchPendingRequests()
  }, [])

  const fetchPendingRequests = async () => {
    try {
      const data = await getAllPendingFriendRequests()
      if (data) {
        setPendingRequestsCount(data.received_friend_requests?.length || 0)
      } else {
        setPendingRequestsCount(0)
      }
    } catch (error) {
      console.error('Error fetching pending friend requests:', error)
      setPendingRequestsCount(0)
    }
  }

  const toggleExpand = () => {
    setExpanded(!expanded)
    setAnimationComplete(false)
  }

  const buttonSpring = useSpring({
    transform: expanded ? 'translateY(-40px)' : 'translateY(0)',
    scale: expanded ? 1.1 : 1,
    config: { duration: '1s' },
    onRest: () => setAnimationComplete(true) // Triggered when the toggle button animation completes
  })

  const buttonsSpring = useSpring({
    opacity: animationComplete && expanded ? 1 : 0,
    transform: animationComplete && expanded ? 'translateY(-10px)' : 'translateY(10px)',
    config: { tension: 100, friction: 20 }
  })

  function isChatPage(pathname) {
    return ['/chat', '/feed'].includes(pathname)
  }

  const handleCloseAddPostPopup = () => {
    setShowAddPostPopup(false)
  }

  return (
    <div className={classes.NavigationMobile}>
      <ul>
        <NavItem to='/dashboard' icon={faHome} />
        <NavItem to='/report' icon={faChartLine} />
        <NavItem to='/training' icon={faRunning} />
        <NavItem to='/health' icon={faListCheck} />

        <animated.a onClick={toggleExpand} className={isChatPage(location.pathname) ? classes.ActiveMobile : ''} style={buttonSpring}>
          {expanded ? (
            <FontAwesomeIcon icon={faTimes} />
          ) : (
            <div style={{ position: 'relative' }}>
              <FontAwesomeIcon icon={faComments} />
              {pendingRequestsCount > 0 && <span className={classes.PendingCount}>{pendingRequestsCount}</span>}
            </div>
          )}
        </animated.a>

        <animated.div className={classes.ExpandedButtons} style={buttonsSpring}>
          {expanded && (
            <>
              <NavLink to='/feed' className={classes.PendingNavLink}>
                <FontAwesomeIcon icon={faPeopleGroup} />
                {pendingRequestsCount > 0 && <span className={classes.PendingCountFeed}>{pendingRequestsCount}</span>}
              </NavLink>
              <NavLink to='/chat'>
                <FontAwesomeIcon icon={faComment} />
              </NavLink>
              <animated.a>
                <FontAwesomeIcon icon={faAdd} />
              </animated.a>
            </>
          )}
        </animated.div>
      </ul>
      {showAddPostPopup && <AddPostPopUp onClose={handleCloseAddPostPopup} />}
    </div>
  )
}

function NavItem({ to, icon }) {
  return (
    <NavLink to={to} className={({ isActive }) => (isActive ? classes.ActiveMobile : '')}>
      <FontAwesomeIcon icon={icon} />
    </NavLink>
  )
}

export default NavigationMobile
