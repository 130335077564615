import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Notification from '../../Notifications/NotificationPopUp'
import SelectExercise from '../../Select/SelectExercise'
import classes from './TrainingPlanPopUp.module.css'

const TrainingPlanPopup = ({
  isOpen,
  isAddPopup,
  currentTrainingPlan,
  trainingTypes,
  exercises,
  selectedTrainingTypeId,
  selectedTrainingType,
  selectedExercises,
  setCurrentTrainingPlan,
  setSelectedTrainingType,
  setSelectedExercises,
  saveTrainingPlan,
  closePopup,
  popupRef
}) => {
  const { t } = useTranslation()
  const [notification, setNotification] = useState({ visible: false, text: '', type: 'alert' })

  const removeExercise = (exerciseId) => {
    setSelectedExercises((prevSelectedExercises) => prevSelectedExercises.filter((exercise) => exercise !== exerciseId))
  }

  const handleTrainingTypeChange = (event) => {
    setSelectedTrainingType(event.target.value)
  }

  const handleExerciseChange = (option) => {
    const selectedExerciseId = option.value
    const isAlreadySelected = selectedExercises.includes(selectedExerciseId)

    if (!isAlreadySelected) {
      setSelectedExercises([...selectedExercises, selectedExerciseId])
    } else {
      setNotification({ visible: true, text: t('report.trainingPlan.notification.alreadySelectedExercise'), type: 'alert' })
    }
  }

  const handleNotificationButtonClick = () => {
    setNotification({ visible: false, text: '', type: 'alert' })
  }

  useEffect(() => {
    if (popupRef && popupRef.current) {
      popupRef.current.scrollTop = 0
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }, [isOpen])

  if (!isOpen) return null

  const exerciseOptions = exercises.map((exercise) => ({
    value: exercise.exercise_id,
    label: exercise.exercise_name
  }))

  return (
    <>
      <div className={classes.Overlay} onClick={closePopup}></div>
      <div className={classes.PopUp} ref={popupRef}>
        <div className={classes.ExerciseHead}>
          <div className={classes.PopUpHeader}>
            <h3>
              {t('report.trainingPlan.headline')} {isAddPopup ? t('common.add') : t('common.update')}{' '}
            </h3>
            <hr />
            <button onClick={closePopup}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          <div className={classes.ExerciseInputs}>
            <input
              type='text'
              placeholder={t('report.trainingPlan.name')}
              value={currentTrainingPlan.trainingplan_name}
              onChange={(e) => setCurrentTrainingPlan({ ...currentTrainingPlan, trainingplan_name: e.target.value })}
            />
            <div className={classes.Select}>
              <select
                id='TrainingCategory'
                name='TrainingCategory'
                value={selectedTrainingType}
                onChange={handleTrainingTypeChange}
                disabled={!isAddPopup}
              >
                <option value='' disabled>
                  {t('report.trainingPlan.selectType')}
                </option>
                {trainingTypes.map((trainingType) => (
                  <option key={trainingType.training_type_id} value={trainingType.training_type_name}>
                    {trainingType.training_type_name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {selectedTrainingTypeId === 0 && (
            <div className={classes.ExerciseContainer}>
              <h4>{t('report.trainingPlan.selectedExercises')}:</h4>
              <SelectExercise
                value=''
                onChange={handleExerciseChange}
                options={exerciseOptions}
                placeholder={t('training.exercise.selectExercisePlaceholder')}
              />
              {selectedExercises.map((exerciseId) => (
                <div key={exerciseId} className={classes.SelectedExercise}>
                  <span>{exercises.find((exercise) => exercise.exercise_id === parseInt(exerciseId))?.exercise_name}</span>
                  <button onClick={() => removeExercise(exerciseId)}>
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </div>
              ))}
            </div>
          )}
          <div className={classes.ExerciseButtons}>
            <div className={classes.SaveCancelContainer}>
              <button onClick={saveTrainingPlan}>
                <FontAwesomeIcon icon={faSave} />
              </button>
            </div>
          </div>
        </div>
      </div>
      {notification.visible && (
        <Notification
          text={notification.text}
          buttons={[
            {
              label: t('common.ok'),
              action: 'close'
            }
          ]}
          type={notification.type}
          onButtonClick={handleNotificationButtonClick}
        />
      )}
    </>
  )
}

export default TrainingPlanPopup
