import { apiClient } from './auth'

export const createBugTicketWithAttachments = async (formData) => {
  const token = localStorage.getItem('access_token')
  const response = await apiClient.post('/user_communication/create_bug_ticket_with_attachments', formData, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data'
    }
  })
  return response.data
}

export const createBugTicketWithoutAttachments = async (newBugData) => {
  const token = localStorage.getItem('access_token')
  const response = await apiClient.post('/user_communication/create_bug_ticket_without_attachments', newBugData, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })
  return response.data
}

export const createFeatureRequestTicket = async (newFeatureRequestData) => {
  const token = localStorage.getItem('access_token')
  const response = await apiClient.post('/user_communication/create_feature_request_ticket', newFeatureRequestData, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })
  return response.data
}

export const createFeedbackTicket = async (newFeedbackData) => {
  const token = localStorage.getItem('access_token')
  const response = await apiClient.post('/user_communication/create_feedback_ticket', newFeedbackData, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })
  return response.data
}

export const createExerciseRequestTicket = async (newExerciseRequestData) => {
  const token = localStorage.getItem('access_token')
  const response = await apiClient.post('/user_communication/create_exercise_request_ticket', newExerciseRequestData, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })
  return response.data
}

export const createServiceRequestTicket = async (newServiceRequestData) => {
  const token = localStorage.getItem('access_token')
  const response = await apiClient.post('/user_communication/create_service_request_ticket', newServiceRequestData, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })
  return response.data
}
