import 'react-swipeable-list/dist/styles.css'

import { faInfoCircle, faSearch, faTrash, faUserCheck, faUserPlus, faUserSlash, faUserTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SwipeAction, SwipeableList, SwipeableListItem, TrailingActions } from 'react-swipeable-list'
import { Tooltip } from 'react-tooltip'

import {
  acceptFriendRequest,
  deleteFriend,
  getAllFriends,
  getAllPendingFriendRequests,
  rejectFriendRequest,
  searchUsername,
  sendFriendRequest,
  withdrawFriendRequest
} from '../../api/friend'
import { getUserProfile } from '../../api/user'
import Footer from '../../components/Footer/Footer'
import NavigationMobile from '../../components/Navigation/BottomNavigation/BottomNavigation'
import Navigation from '../../components/Navigation/TopNavigation/Navigation'
import Notification from '../../components/Notifications/NotificationPopUp'
import classes from './Feed.module.css'

export const Feed = () => {
  const { t } = useTranslation()
  const [searchTerm, setSearchTerm] = useState('')
  const [searchResult, setSearchResult] = useState(null)
  const [pendingRequests, setPendingRequests] = useState([])
  const [sentRequests, setSentRequests] = useState([])
  const [friends, setFriends] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [currentUser, setCurrentUser] = useState(null)
  const [notification, setNotification] = useState({ show: false, type: '', text: '' })
  const [confirmDelete, setConfirmDelete] = useState({ show: false, userId: null })

  useEffect(() => {
    fetchUserProfile()
    fetchPendingRequests()
    fetchFriends()
  }, [])

  const fetchUserProfile = async () => {
    try {
      const user = await getUserProfile()
      if (user.profile_picture) {
        user.profile_picture = `data:image/webp;base64,${user.profile_picture}`
      }
      setCurrentUser(user)
    } catch (error) {
      console.error('Error fetching user profile:', error)
    }
  }

  const fetchPendingRequests = async () => {
    try {
      const data = await getAllPendingFriendRequests()
      if (data) {
        setPendingRequests(
          (data.received_friend_requests || []).map((request) => {
            if (request.sender_profile_picture) {
              request.sender_profile_picture = `data:image/webp;base64,${request.sender_profile_picture}`
            }
            return request
          })
        )
        setSentRequests(
          (data.sent_friend_requests || []).map((request) => {
            if (request.receiver_profile_picture) {
              request.receiver_profile_picture = `data:image/webp;base64,${request.receiver_profile_picture}`
            }
            return request
          })
        )
      } else {
        setPendingRequests([])
        setSentRequests([])
      }
    } catch (error) {
      console.error('Error fetching pending friend requests:', error)
      setNotification({ show: true, type: 'error', text: t('feed.notification.errorFetchingPendingRequests') })
    }
  }

  const fetchFriends = async () => {
    const data = await getAllFriends()
    const updatedFriends = (data || []).map((friend) => {
      if (friend.profile_picture) {
        friend.profile_picture = `data:image/webp;base64,${friend.profile_picture}`
      }
      return friend
    })
    setFriends(updatedFriends)
  }

  const handleSearch = async () => {
    try {
      const result = await searchUsername(searchTerm)
      if (result.profile_picture) {
        result.profile_picture = `data:image/webp;base64,${result.profile_picture}`
      }
      setSearchResult(result)
    } catch (error) {
      console.error('Error searching username:', error)
      setNotification({ show: true, type: 'error', text: t('feed.notification.errorSearchingUsername') })
    }
  }

  const handleSendRequest = async (userId) => {
    try {
      await sendFriendRequest(userId)
      fetchPendingRequests()
      setNotification({ show: true, type: 'success', text: t('feed.notification.friendRequestSent') })
    } catch (error) {
      console.error('Error sending friend request:', error)
      if (error.response && error.response.data.detail) {
        setNotification({ show: true, type: 'error', text: error.response.data.detail })
      } else {
        setNotification({ show: true, type: 'error', text: t('feed.notification.generalError') })
      }
    }
  }

  const handleWithdrawRequest = async (requestId) => {
    try {
      await withdrawFriendRequest(requestId)
      fetchPendingRequests()
      setNotification({ show: true, type: 'success', text: t('feed.notification.friendRequestWithdrawn') })
    } catch (error) {
      console.error('Error withdrawing friend request:', error)
      setNotification({ show: true, type: 'error', text: t('feed.notification.generalError') })
    }
  }

  const handleAcceptRequest = async (requestId) => {
    try {
      await acceptFriendRequest(requestId)
      fetchPendingRequests()
      fetchFriends()
      setNotification({ show: true, type: 'success', text: t('feed.notification.friendRequestAccepted') })
    } catch (error) {
      console.error('Error accepting friend request:', error)
      setNotification({ show: true, type: 'error', text: t('feed.notification.generalError') })
    }
  }

  const handleRejectRequest = async (requestId) => {
    try {
      await rejectFriendRequest(requestId)
      fetchPendingRequests()
      setNotification({ show: true, type: 'success', text: t('feed.notification.friendRequestRejected') })
    } catch (error) {
      console.error('Error rejecting friend request:', error)
      setNotification({ show: true, type: 'error', text: t('feed.notification.generalError') })
    }
  }

  const handleDeleteFriend = async (friendUserId) => {
    setConfirmDelete({ show: true, userId: friendUserId })
  }

  const confirmDeleteFriend = async () => {
    try {
      await deleteFriend(confirmDelete.userId)
      fetchFriends()
      setNotification({ show: true, type: 'success', text: t('feed.notification.friendDeleted') })
    } catch (error) {
      console.error('Error deleting friend:', error)
      setNotification({ show: true, type: 'error', text: t('feed.notification.generalError') })
    } finally {
      setConfirmDelete({ show: false, userId: null })
    }
  }

  const trailingActions = (friendUserId) => (
    <TrailingActions>
      <SwipeAction onClick={() => handleDeleteFriend(friendUserId)}>
        <div className={classes.SwipeActionButtonTrailing}>
          <FontAwesomeIcon icon={faTrash} /> {t('feed.deleteFriend')}
        </div>
      </SwipeAction>
    </TrailingActions>
  )

  return (
    <>
      <div className={classes.Feed}>
        <Navigation />
        <div className={classes.FeedContent}>
          <div className={classes.FeedHeader}>
            <h2>{t('feed.headline')}</h2>
            <hr />
            <div className={classes.SearchSection}>
              <h3>{t('feed.searchUser')}</h3>
              <div className={classes.SearchBar}>
                <input
                  type='text'
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder={t('feed.enterUsername')}
                  data-testid='search-input'
                />
                <button onClick={handleSearch} data-testid='search-button'>
                  <FontAwesomeIcon icon={faSearch} />
                </button>
              </div>
              {searchResult && (
                <div className={classes.SearchResult}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={searchResult.profile_picture} alt='img' className={classes.ProfilePicture} />
                    <p>{searchResult.username}</p>
                  </div>
                  <button onClick={() => handleSendRequest(searchResult.user_id)} data-testid='send-request-button'>
                    <FontAwesomeIcon icon={faUserPlus} />
                  </button>
                </div>
              )}
            </div>
            <div className={classes.PendingRequestsSection}>
              <h3>{t('feed.pendingFriendRequests')}</h3>
              {pendingRequests.map((request) => (
                <div key={request.friend_request_id} className={classes.RequestItem}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={request.sender_profile_picture} alt='img' className={classes.ProfilePicture} />
                    <p>{request.sender_username}</p>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <button onClick={() => handleAcceptRequest(request.friend_request_id)} data-testid='accept-request-button'>
                      <FontAwesomeIcon icon={faUserCheck} />
                    </button>
                    <button onClick={() => handleRejectRequest(request.friend_request_id)} data-testid='reject-request-button'>
                      <FontAwesomeIcon icon={faUserTimes} />
                    </button>
                  </div>
                </div>
              ))}
            </div>
            <div className={classes.SentRequestsSection}>
              <h3>{t('feed.sentFriendRequests')}</h3>
              {sentRequests.map((request) => (
                <div key={request.friend_request_id} className={classes.RequestItem}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={request.receiver_profile_picture} alt='img' className={classes.ProfilePicture} />
                    <p>{request.receiver_username}</p>
                  </div>
                  <button onClick={() => handleWithdrawRequest(request.friend_request_id)} data-testid='withdraw-request-button'>
                    <FontAwesomeIcon icon={faUserSlash} />
                  </button>
                </div>
              ))}
            </div>
            <div className={classes.FriendsSection}>
              <div className={classes.HeadlineWrapper}>
                <h3>{t('feed.friends')}</h3>
                <FontAwesomeIcon icon={faInfoCircle} className={classes.TooltipIcon} data-tooltip-id='friendsSwipeTooltip' />
                <Tooltip
                  id='friendsSwipeTooltip'
                  className={classes.TooltipContent}
                  effect='solid'
                  place='bottom'
                  content={t('feed.swipeToDelete')}
                />
              </div>
              <div className={classes.SwipeableListItem}>
                <SwipeableList>
                  {friends.map((friend) => (
                    <SwipeableListItem key={friend.user_id} trailingActions={trailingActions(friend.user_id)}>
                      <div className={classes.FriendItem}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <img src={friend.profile_picture} alt='img' className={classes.ProfilePicture} />
                          <p>{friend.username}</p>
                        </div>
                      </div>
                    </SwipeableListItem>
                  ))}
                </SwipeableList>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <NavigationMobile />
      {notification.show && (
        <Notification
          text={notification.text}
          type={notification.type}
          buttons={[{ label: t('common.ok'), action: () => setNotification({ show: false, type: '', text: '' }) }]}
          onButtonClick={() => setNotification({ show: false, type: '', text: '' })}
        />
      )}
      {confirmDelete.show && (
        <Notification
          text={t('feed.notification.confirmDeleteFriend')}
          type='warning'
          buttons={[
            { label: t('common.yes'), action: confirmDeleteFriend },
            { label: t('common.no'), action: () => setConfirmDelete({ show: false, userId: null }) }
          ]}
          onButtonClick={() => setConfirmDelete({ show: false, userId: null })}
        />
      )}
    </>
  )
}

export default Feed
