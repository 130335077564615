import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ClipLoader } from 'react-spinners'
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from 'recharts'

import { countTrainingDaily } from '../../../api/reporting'
import classes from './ExerciseFrequencyChart.module.css'

function MonthlyWeeklyChart({ isDarkMode }) {
  const [view, setView] = useState('monthly')
  const [selectedMonth, setSelectedMonth] = useState('')
  const [allMonthsSelected, setAllMonthsSelected] = useState(true)
  const [trainingData, setTrainingData] = useState([])
  const [loading, setLoading] = useState(true)
  const { t } = useTranslation()

  useEffect(() => {
    const fetchTrainingData = async () => {
      setLoading(true)
      try {
        const data = await countTrainingDaily()
        setTrainingData(data)
      } catch (error) {
        console.error('Failed to fetch training data:', error)
      } finally {
        setLoading(false)
      }
    }

    fetchTrainingData()
  }, [])

  const getMonthlyData = () => {
    const monthlyData = {}
    trainingData.forEach((training) => {
      const month = training.date.substring(0, 7) // Extract month (YYYY-MM)
      monthlyData[month] = (monthlyData[month] || 0) + training.training_count
    })
    return Object.keys(monthlyData).map((month) => ({ month, trainings: monthlyData[month] }))
  }

  const getWeek = (date) => {
    const dt = new Date(date)
    dt.setHours(0, 0, 0, 0)
    dt.setDate(dt.getDate() + 4 - (dt.getDay() || 7))
    const yearStart = new Date(dt.getFullYear(), 0, 1)
    const weekNumber = Math.ceil(((dt - yearStart) / 86400000 + 1) / 7)
    return weekNumber
  }

  const getWeeklyData = () => {
    const weeklyData = {}
    trainingData.forEach((training) => {
      const yearMonth = training.date.substring(0, 7)
      const yearWeek = yearMonth + '-' + getWeek(training.date)
      if (allMonthsSelected || yearMonth === selectedMonth) {
        weeklyData[yearWeek] = (weeklyData[yearWeek] || 0) + training.training_count
      }
    })
    return Object.keys(weeklyData).map((week) => ({ week, trainings: weeklyData[week] }))
  }

  const handleMonthChange = (e) => {
    const selectedMonth = e.target.value
    setSelectedMonth(selectedMonth)
    setAllMonthsSelected(selectedMonth === '')
  }

  const data = view === 'monthly' ? getMonthlyData() : getWeeklyData()
  const months = Array.from(new Set(trainingData.map((training) => training.date.substring(0, 7))))
  const allWeeksOption = [{ value: '', label: t('report.chart.trainingFrequency.allWeeks') }]

  return (
    <div className={classes.MonthlyWeeklyChart}>
      <h3>
        {view === 'monthly' ? t('report.chart.trainingFrequency.headlineMonthly') : t('report.chart.trainingFrequency.headlineWeekly')}
        {view === 'weekly' &&
          selectedMonth &&
          ` - ${new Date(selectedMonth).toLocaleString('default', {
            month: 'long',
            year: 'numeric'
          })}`}
      </h3>
      {loading ? (
        <div className={classes.LoadingSpinner}>
          <ClipLoader size={50} color='' loading={loading} />
        </div>
      ) : (
        <>
          {trainingData.length > 0 ? (
            <BarChart width={window.innerWidth * 0.9} height={160} data={data} className={classes.BarChart}>
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis
                tick={{ fill: isDarkMode ? 'lightgrey' : '#F0F3FA', fontSize: 12 }}
                tickLine={{ stroke: isDarkMode ? 'lightgrey' : '#F0F3FA' }}
                axisLine={{ stroke: isDarkMode ? 'lightgrey' : '#F0F3FA' }}
                dataKey={view === 'monthly' ? 'month' : 'week'}
                tickFormatter={(tick) => {
                  if (view === 'monthly') {
                    return new Date(tick).toLocaleString('default', { month: 'long' })
                  } else {
                    return new Date(tick.split('-')[0], tick.split('-')[1] - 1, 1).toLocaleString('default', { month: 'long' })
                  }
                }}
              />
              <YAxis
                tick={{ fill: isDarkMode ? 'lightgrey' : '#F0F3FA', fontSize: 12 }}
                tickLine={{ stroke: isDarkMode ? 'lightgrey' : '#F0F3FA' }}
                axisLine={{ stroke: isDarkMode ? 'lightgrey' : '#F0F3FA' }}
                allowDecimals={false}
              />
              <Tooltip
                labelFormatter={(value) => {
                  if (view === 'weekly') {
                    const weekNum = value.split('-').pop()
                    return `Week ${weekNum}`
                  }
                  return value
                }}
                formatter={(value) => [value + 'x', t('report.chart.trainingFrequency.trainingDone')]}
              />
              <Bar dataKey='trainings' fill={'#395886'} />
            </BarChart>
          ) : (
            <div className={classes.TextWrapper}>
              <p className={classes.NoDataText}>{t('report.noDataAvailable')}</p>
            </div>
          )}
        </>
      )}
      <div className={classes.ChartSelector}>
        <div className={classes.Select}>
          <select value={view} onChange={(e) => setView(e.target.value)}>
            <option value='monthly'>{t('report.unit.monthly')}</option>
            <option value='weekly'>{t('report.unit.weekly')}</option>
          </select>
        </div>
        {view === 'weekly' && (
          <div className={classes.Select}>
            <select value={selectedMonth} onChange={handleMonthChange}>
              {allWeeksOption
                .concat(
                  months.map((month) => ({
                    value: month,
                    label: new Date(month).toLocaleString('default', { month: 'long', year: 'numeric' })
                  }))
                )
                .map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
            </select>
          </div>
        )}
      </div>
    </div>
  )
}

export default MonthlyWeeklyChart
