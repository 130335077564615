import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import Footer from '../../components/Footer/Footer'
import classes from './ReleaseNotes.module.css'

const ReleaseNotes = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  const handleBackClick = () => {
    navigate(-1)
  }

  return (
    <div className={classes.ReleaseNotes}>
      <div className={classes.ReleaseNotesContent}>
        <button onClick={handleBackClick} className={classes.BackButton}>
          {t('releaseNotes.backButton')}
        </button>
        <h1>{t('releaseNotes.title')}</h1>
        <p>{t('releaseNotes.intro')}</p>
        <section>
          <h2>{t('releaseNotes.developedFeaturesTitle')}</h2>
          <ul>
            <li>{t('releaseNotes.developedFeatures.dashboard')}</li>
            <li>{t('releaseNotes.developedFeatures.calendarWidget')}</li>
            <li>{t('releaseNotes.developedFeatures.weightTrackerWidget')}</li>
            <li>{t('releaseNotes.developedFeatures.trainingReports')}</li>
            <li>{t('releaseNotes.developedFeatures.trainingCycles')}</li>
            <li>{t('releaseNotes.developedFeatures.breakTimer')}</li>
            <li>{t('releaseNotes.developedFeatures.darkmode')}</li>
            <li>{t('releaseNotes.developedFeatures.trackStrengthTraining')}</li>
            <li>{t('releaseNotes.developedFeatures.userProfile')}</li>
            <li>{t('releaseNotes.developedFeatures.settings')}</li>
            <li>{t('releaseNotes.developedFeatures.feedback')}</li>
          </ul>
        </section>
        <section>
          <h2>{t('releaseNotes.plannedFeaturesTitle')}</h2>
          <ul>
            <li>{t('releaseNotes.plannedFeatures.additionalReports')}</li>
            <li>{t('releaseNotes.plannedFeatures.tutorial')}</li>
            <li>{t('releaseNotes.plannedFeatures.friendRequests')}</li>
            <li>{t('releaseNotes.plannedFeatures.additionalTrainingTypes')}</li>
            <li>{t('releaseNotes.plannedFeatures.feedAndPosts')}</li>
            <li>{t('releaseNotes.plannedFeatures.healthCategory')}</li>
            <li>{t('releaseNotes.plannedFeatures.achievementsAndActivities')}</li>
            <li>{t('releaseNotes.plannedFeatures.profilePictureUpload')}</li>
            <li>{t('releaseNotes.plannedFeatures.andMore')}</li>
          </ul>
        </section>
      </div>
      <Footer />
    </div>
  )
}

export default ReleaseNotes
