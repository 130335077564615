import { faPlay } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { getCurrentActiveTrainingCycle } from '../../../api/cycle'
import Notification from '../../Notifications/NotificationPopUp'
import classes from './CycleWidget.module.css'

function CycleWidget() {
  const navigate = useNavigate()
  const [trainingCycle, setTrainingCycle] = useState(null)
  const [showNotification, setShowNotification] = useState(false)
  const [notificationText, setNotificationText] = useState('')
  const { t } = useTranslation()

  useEffect(() => {
    const fetchTrainingCycle = async () => {
      try {
        const cycle = await getCurrentActiveTrainingCycle()
        if (cycle && cycle.training_cycle_status === 'ACTIVE') {
          setTrainingCycle(cycle)
        } else {
          setTrainingCycle(null)
        }
      } catch (error) {
        setTrainingCycle(null)
      }
    }

    fetchTrainingCycle()
  }, [])

  const startTraining = () => {
    const isTrainingActive = localStorage.getItem('isActive') === 'true'
    if (isTrainingActive) {
      setNotificationText(t('dashboard.cycleWidget.activeTrainingWarning'))
      setShowNotification(true)
    } else if (trainingCycle && trainingCycle.next_trainingplan) {
      sessionStorage.setItem('fromWidget', 'true')
      localStorage.setItem('trainingCycleId', trainingCycle.training_cycle_id)
      navigate('/training', { state: { trainingPlan: trainingCycle.next_trainingplan } })
    }
  }

  const handleNotificationButtonClick = (action) => {
    if (action === 'dismiss') {
      setShowNotification(false)
    }
  }

  if (!trainingCycle) {
    return null
  }

  return (
    <div className={classes.CycleWidget}>
      <div className={classes.CycleWidgetContent}>
        <div className={classes.CycleWidgetContentHeader}>
          <h4>
            {t('dashboard.cycleWidget.trainingCycleName')}: {trainingCycle.training_cycle_name}
          </h4>
        </div>
        <div className={classes.Timebar}>
          {trainingCycle.previous_trainingplan_name && (
            <div className={classes.TimebarContentLeft}>
              <p>{t('dashboard.cycleWidget.prev')}:</p>
              <p>{trainingCycle.previous_trainingplan_name}</p>
            </div>
          )}
          <div className={classes.TimebarContentRight}>
            <div className={classes.TimebarContentInner}>
              <p>{t('dashboard.cycleWidget.next')}:</p>
              <p>{trainingCycle.next_trainingplan.trainingplan_name}</p>
            </div>
            <button className={classes.StartTrainingButton} onClick={startTraining}>
              <FontAwesomeIcon icon={faPlay} />
            </button>
          </div>
        </div>
      </div>
      {showNotification && (
        <Notification
          text={notificationText}
          buttons={[{ label: t('common.ok'), action: 'dismiss' }]}
          onButtonClick={handleNotificationButtonClick}
          type='alert'
        />
      )}
    </div>
  )
}

export default CycleWidget
