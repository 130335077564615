import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ClipLoader } from 'react-spinners'
import { CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts'

import { getAllExercises } from '../../../api/exercise'
import { highestWeightMonthly, highestWeightWeekly } from '../../../api/reporting'
import SelectExercise from '../../Select/SelectExercise'
import classes from './ExerciseMaxWeightChart.module.css'

const ExerciseMaxChart = ({ isDarkMode }) => {
  const [exercises, setExercises] = useState([])
  const [selectedExercise, setSelectedExercise] = useState(null)
  const [selectedTimeframe, setSelectedTimeframe] = useState('monthly')
  const [chartData, setChartData] = useState([])
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const exercisesData = await getAllExercises()
        setExercises(exercisesData)
      } catch (error) {
        console.error('Error fetching exercises:', error)
      }
    }
    fetchData()
  }, [])

  useEffect(() => {
    const fetchChartData = async () => {
      if (!selectedExercise) return
      setLoading(true)
      try {
        let data
        if (selectedTimeframe === 'monthly') {
          data = await highestWeightMonthly(selectedExercise.exercise_id)
          setChartData(
            data.map((d) => ({
              ...d,
              date: new Date(d.year, d.month - 1).toLocaleString('default', { month: 'short' })
            }))
          )
        } else {
          data = await highestWeightWeekly(selectedExercise.exercise_id)
          setChartData(
            data.map((d) => ({
              ...d,
              date: `W${String(d.week).padStart(2, '0')}`
            }))
          )
        }
      } catch (error) {
        console.error('Error fetching chart data:', error)
      } finally {
        setLoading(false)
      }
    }
    fetchChartData()
  }, [selectedExercise, selectedTimeframe])

  const handleExerciseChange = (option) => {
    const selectedExercise = exercises.find((exercise) => exercise.exercise_id === parseInt(option.value))
    setSelectedExercise(selectedExercise)
  }

  const handleTimeframeChange = (event) => {
    setSelectedTimeframe(event.target.value)
  }

  const formatWeeklyLabel = (value) => {
    const weekNum = value.substring(1)
    const year = new Date().getFullYear()
    const date = new Date(year, 0, 1)
    const day = date.getDay()

    date.setDate(date.getDate() + ((4 - day + 7) % 7))
    date.setDate(date.getDate() + (Number(weekNum) - 1) * 7)

    return `${value} ${date.toLocaleString('default', { month: 'short' })}`
  }

  const formatMonthlyLabel = (value) => {
    const year = new Date().getFullYear()
    return `${value} ${year}`
  }

  const exerciseOptions = exercises.map((exercise) => ({
    value: exercise.exercise_id,
    label: exercise.exercise_name
  }))

  return (
    <div className={classes.ExerciseMaxChart}>
      <h3>
        {t(`report.chart.MaxWeightChart.specificMaxWeight.${selectedTimeframe}`)} {t('report.chart.MaxWeightChart.headline')}
      </h3>
      <div className={classes.Chart}>
        {loading ? (
          <div className={classes.SpinnerWrapper}>
            <ClipLoader color={isDarkMode ? '#8AAEE0' : '#395886'} loading={loading} size={50} />
          </div>
        ) : !selectedExercise ? (
          <div className={classes.TextWrapper}>
            <p className={classes.NoDataText}>{t('report.pleaseSelectExercise')}</p>
          </div>
        ) : chartData.length > 0 ? (
          <LineChart width={window.innerWidth * 0.9} height={160} data={chartData} className={classes.LineChart}>
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis
              dataKey='date'
              tick={{ fill: isDarkMode ? 'lightgrey' : '#F0F3FA', fontSize: 12 }}
              tickLine={{ stroke: isDarkMode ? 'lightgrey' : '#F0F3FA' }}
              axisLine={{ stroke: isDarkMode ? 'lightgrey' : '#F0F3FA' }}
            />
            <YAxis
              tick={{ fill: isDarkMode ? 'lightgrey' : '#F0F3FA', fontSize: 12 }}
              tickLine={{ stroke: isDarkMode ? 'lightgrey' : '#F0F3FA' }}
              axisLine={{ stroke: isDarkMode ? 'lightgrey' : '#F0F3FA' }}
            />
            <Tooltip
              labelFormatter={(value) => (selectedTimeframe === 'weekly' ? formatWeeklyLabel(value) : formatMonthlyLabel(value))}
              formatter={(value) => [value + t('common.unit.kg'), t('report.chart.MaxWeightChart.tooltipLabel')]}
            />
            <Line type='monotone' dataKey='max_weight' stroke={isDarkMode ? '#395886' : '#638ecb'} />
          </LineChart>
        ) : (
          <div className={classes.TextWrapper}>
            <p className={classes.NoDataText}>{t('report.noDataAvailable')}</p>
          </div>
        )}
      </div>
      <div className={classes.ChartSelector}>
        <SelectExercise
          options={exerciseOptions}
          onChange={handleExerciseChange}
          placeholder={t('training.exercise.selectExercisePlaceholder')}
        />
        <div className={classes.Select}>
          <select id='timeframe' value={selectedTimeframe} onChange={handleTimeframeChange}>
            <option value='monthly'>{t('report.unit.monthly')}</option>
            <option value='weekly'>{t('report.unit.weekly')}</option>
          </select>
        </div>
      </div>
    </div>
  )
}

export default ExerciseMaxChart
