import { apiClient } from './auth'

export const getAllTrainingPlans = async () => {
  const token = localStorage.getItem('access_token')
  const response = await apiClient.get('/trainingplan/all_trainingplans', {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return response.data
}

export const getTrainingPlanById = async (trainingPlanId) => {
  const token = localStorage.getItem('access_token')
  const response = await apiClient.get(`/trainingplan/trainingplan_by_id/${trainingPlanId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return response.data
}

export const postTrainingPlan = async (trainingPlanData) => {
  const token = localStorage.getItem('access_token')
  const response = await apiClient.post('/trainingplan/save_trainingplan', trainingPlanData, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })
  return response.data
}

export const updateTrainingPlan = async (trainingPlanId, updatedTrainingPlanData) => {
  const token = localStorage.getItem('access_token')
  const response = await apiClient.patch(`/trainingplan/update_trainingplan/${trainingPlanId}`, updatedTrainingPlanData, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })
  return response.data
}

export const deleteTrainingPlan = async (trainingPlanId) => {
  const token = localStorage.getItem('access_token')
  const response = await apiClient.delete(`/trainingplan/delete_trainingplan/${trainingPlanId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  if (response.status === 200) {
    return response.data
  } else {
    throw new Error('Failed to delete training plan.')
  }
}
