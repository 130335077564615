import { apiClient } from './auth'

export const getAllExercises = async () => {
  const response = await apiClient.get('/exercise/all_exercises')
  return response.data
}

export const getExerciseById = async (exerciseId) => {
  const token = localStorage.getItem('access_token')
  const response = await apiClient.get(`/exercise/exercise_by_id/${exerciseId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return response.data
}

export const getAllExercisesByTrainingTypeId = async (trainingTypeId) => {
  const token = localStorage.getItem('access_token')
  const response = await apiClient.get(`/exercise/exercises_of_training_type/${trainingTypeId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return response.data
}

export const getAllExercisesWithPrevSets = async () => {
  const token = localStorage.getItem('access_token')
  const response = await apiClient.get('/exercise/all_exercises_with_prev_sets', {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return response.data
}

export const getExerciseWithPrevSets = async (exerciseId) => {
  const token = localStorage.getItem('access_token')
  const response = await apiClient.get(`/exercise/exercise_with_prev_sets/${exerciseId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return response.data
}

export const getAllExercisesByTrainingTypeIdWithPrevSets = async (trainingTypeId) => {
  const token = localStorage.getItem('access_token')
  const response = await apiClient.get(`/exercise/all_exercises_by_training_type_id_with_prev_sets/${trainingTypeId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return response.data
}

export const getExercisesBySameTargetMuscleGroup = async (exerciseId) => {
  const token = localStorage.getItem('access_token')
  const response = await apiClient.get(`/exercise/all_exercises_by_same_target_musclegroup/${exerciseId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return response.data
}

export const getAllMuscleGroups = async (languageCode = 'de') => {
  const token = localStorage.getItem('access_token')
  const response = await apiClient.get('/exercise/all_musclegroups', {
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: {
      language_code: languageCode
    }
  })
  return response.data
}

export const getAllExerciseExperienceLevels = async (languageCode = 'de') => {
  const token = localStorage.getItem('access_token')
  const response = await apiClient.get('/exercise/all_exercise_experience_levels', {
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: {
      language_code: languageCode
    }
  })
  return response.data
}

export const getAllExerciseEquipments = async (languageCode = 'de') => {
  const token = localStorage.getItem('access_token')
  const response = await apiClient.get('/exercise/all_exercise_equipments', {
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: {
      language_code: languageCode
    }
  })
  return response.data
}
