// import { faCalendarDay, faWeight } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getBodyWeightTrend } from '../../../api/reporting'
import { updateUserProfile } from '../../../api/user'
import Notification from '../../Notifications/NotificationPopUp'
import classes from './WeightWidget.module.css'

const WeightWidget = ({ onWeightUpdate }) => {
  const { t } = useTranslation()
  const [bodyWeight, setBodyWeight] = useState(null)
  const [lastUpdate, setLastUpdate] = useState(null)
  const [newWeight, setNewWeight] = useState('')
  const [showNotification, setShowNotification] = useState(false)
  const [notificationText, setNotificationText] = useState('')

  useEffect(() => {
    const fetchBodyWeightTrend = async () => {
      try {
        const weightTrend = await getBodyWeightTrend()
        if (weightTrend.length > 0) {
          weightTrend.sort((a, b) => new Date(b.date) - new Date(a.date))
          const latestWeight = weightTrend[0]
          setBodyWeight(latestWeight.body_weight)
          setNewWeight(latestWeight.body_weight) // Set the select to the current weight
          setLastUpdate(new Date(latestWeight.date))
        }
      } catch (error) {
        console.error('Error fetching body weight trend:', error)
      }
    }

    fetchBodyWeightTrend()
  }, [])

  const handleWeightChange = (e) => {
    setNewWeight(e.target.value)
  }

  const handleWeightSubmit = async () => {
    try {
      await updateUserProfile({ weight: parseFloat(newWeight) })
      setNotificationText(t('dashboard.weightWidget.weightUpdated'))
      setShowNotification(true)
      setBodyWeight(newWeight)
      const newDate = new Date()
      setLastUpdate(newDate)
      setNewWeight('') // Clear the new weight input
      onWeightUpdate(newDate) // Notify parent about the weight update
    } catch (error) {
      console.error('Error updating weight:', error)
      setNotificationText(t('dashboard.weightWidget.updateError'))
      setShowNotification(true)
    }
  }

  const calculateDaysSinceLastUpdate = () => {
    if (!lastUpdate) return 0
    const now = new Date()
    const diffTime = Math.abs(now - lastUpdate)
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24))
  }

  const daysSinceLastUpdate = calculateDaysSinceLastUpdate()

  return (
    <div className={classes.WeightWidget}>
      <div className={classes.WeightWidgetContent}>
        <h4>{t('dashboard.weightWidget.headline')}</h4>
        {bodyWeight !== null && lastUpdate ? (
          <div className={classes.InfoContainer}>
            <div className={classes.Info}>
              {/* <FontAwesomeIcon icon={faWeight} className={classes.Icon} /> */}
              <p>
                <strong>{t('dashboard.weightWidget.lastWeight')}:</strong> {bodyWeight}kg
              </p>
            </div>
            <div className={classes.Info}>
              {/* <FontAwesomeIcon icon={faCalendarDay} className={classes.Icon} /> */}
              <p>
                <strong>{t('dashboard.weightWidget.lastUpdate')}:</strong> {lastUpdate.toLocaleDateString()}
              </p>
            </div>
            <div className={classes.Info}>
              {/* <FontAwesomeIcon icon={faCalendarDay} className={classes.Icon} /> */}
              <p>
                <strong>{t('dashboard.weightWidget.daysSinceLastUpdate')}:</strong> {daysSinceLastUpdate}{' '}
                {daysSinceLastUpdate === 1 ? t('common.unit.day') : t('common.unit.days')}
              </p>
            </div>
            <div className={classes.SelectContainer}>
              <label htmlFor='weight-select'>{t('dashboard.weightWidget.newWeight')}:</label>
              <div className={classes.Select}>
                <select id='weight-select' value={newWeight} onChange={handleWeightChange}>
                  {[...Array(121).keys()]
                    .map((i) => i + 30)
                    .map((weight) => (
                      <option key={weight} value={weight}>
                        {weight} kg
                      </option>
                    ))}
                </select>
              </div>
            </div>
          </div>
        ) : (
          <p>{t('dashboard.weightWidget.noData')}</p>
        )}
        <button onClick={handleWeightSubmit} className={classes.SubmitButton}>
          {t('dashboard.weightWidget.updateWeight')}
        </button>
      </div>
      {showNotification && (
        <Notification
          text={notificationText}
          buttons={[{ label: t('dashboard.weightWidget.ok'), action: () => setShowNotification(false) }]}
          type='alert'
          onButtonClick={() => setShowNotification(false)}
        />
      )}
    </div>
  )
}

export default WeightWidget
