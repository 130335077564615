import { faFloppyDisk, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { postFinishedTraining } from '../../api/finishedTraining'
import classes from './NotificationPopUp.module.css'

const getDate = () => {
  const currentDate = new Date()
  const formattedDate = currentDate.toISOString().split('T')[0]
  return formattedDate
}

const formatDurationInMinutes = (seconds) => {
  const minutes = Math.floor(seconds / 60)
  return minutes
}

function StopPopUp({ onSubmit, onCancel, selectedTrainingType, startTime, timer, exercises, calories, trainingCycleId }) {
  const { t } = useTranslation()
  const [showInput, setShowInput] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const today = new Date().toLocaleDateString()
  const trainingPlanName = localStorage.getItem('selectedTrainingPlanName')
    ? '- ' + localStorage.getItem('selectedTrainingPlanName').replace(/"/g, '')
    : ''
  const defaultValue = `${today} ${trainingPlanName}`
  const [inputValue, setInputValue] = useState(defaultValue)

  const handleYesClick = () => {
    setShowInput(true)
  }

  const handleSubmit = async () => {
    try {
      const stopTime = new Date().toISOString()
      const currentDate = getDate()

      let formattedStartTime = startTime
      if (!isNaN(startTime)) {
        formattedStartTime = new Date(startTime * 1).toISOString()
      }

      await postFinishedTraining({
        training_type_name: selectedTrainingType,
        training_name: inputValue,
        training_date: currentDate,
        training_duration: formatDurationInMinutes(timer),
        training_start: formattedStartTime.split('T')[1],
        training_end: stopTime.split('T')[1],
        finished_exercises: exercises,
        calories_burned: calories,
        training_cycle_id: trainingCycleId
      })

      onSubmit()
    } catch (error) {
      console.error('Error posting finished training data:', error)
      if (error.response && error.response.status === 422) {
        setErrorMessage(t('training.notification.error422'))
      } else {
        setErrorMessage(t('training.notification.generalError'))
      }
    }
  }

  const handleCancel = () => {
    setShowInput(false)
    setErrorMessage(null)
    onCancel()
  }

  return (
    <>
      <div className={classes.Overlay}></div>
      <div className={classes.Notification}>
        <FontAwesomeIcon icon={faQuestionCircle} />
        <p>{t('training.confirmFinishTraining')}</p>
        {errorMessage && <p className={classes.ErrorMessage}>{errorMessage}</p>}
        <div className={classes.Buttons}>
          {showInput ? (
            <>
              <div className={classes.conformationContainer}>
                <div className={classes.inputContainer}>
                  <FontAwesomeIcon icon={faFloppyDisk} />
                  <input
                    type='text'
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    placeholder={t('training.enterNamePlaceholder')}
                    maxLength={22}
                  />
                </div>
                <div className={classes.Buttons}>
                  <button onClick={handleSubmit}>{t('common.submit')}</button>
                  <button onClick={handleCancel}>{t('common.cancel')}</button>
                </div>
              </div>
            </>
          ) : (
            <>
              <button onClick={handleYesClick}>{t('common.yes')}</button>
              <button onClick={handleCancel}>{t('common.no')}</button>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default StopPopUp
