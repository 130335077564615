import 'react-swipeable-list/dist/styles.css'

import { faChevronDown, faChevronUp, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ClipLoader } from 'react-spinners'
import { LeadingActions, SwipeAction, SwipeableList, SwipeableListItem, TrailingActions } from 'react-swipeable-list'

import { abortActiveTrainingCycle, deleteTrainingCycle, getAllTrainingCycles, setTrainingCycleStatusToActive } from '../../api/cycle'
import Notification from '../Notifications/NotificationPopUp'
import TrainingCyclePopUp from '../PopUp/TrainingCyclePopUp/TrainingCyclePopUp'
import classes from './TrainingCycleTable.module.css'

const TrainingCycleTable = () => {
  const [expandedIndex, setExpandedIndex] = useState(null)
  const contentRefs = useRef([])
  const [isPopupOpen, setPopupOpen] = useState(false)
  const popupRef = useRef(null)
  const [cycles, setCycles] = useState([])
  const [notification, setNotification] = useState(null)
  const [currentCycle, setCurrentCycle] = useState(null)
  const [isEdit, setIsEdit] = useState(false)
  const [loading, setLoading] = useState(true)
  const { t } = useTranslation()

  const handleAccordionToggle = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index)
  }

  const leadingActions = (index) => (
    <LeadingActions>
      <SwipeAction onClick={() => handleOpenPopupUpdate(cycles[index])}>
        <div className={classes.SwipeActionButtonLeading}>{t('common.edit')}</div>
      </SwipeAction>
    </LeadingActions>
  )

  const trailingActions = (index) => (
    <TrailingActions>
      <SwipeAction onClick={() => handleDelete(index)}>
        <div className={classes.SwipeActionButtonTrailing}>{t('common.delete')}</div>
      </SwipeAction>
    </TrailingActions>
  )

  const handleStatusChange = async (index) => {
    const cycle = cycles[index]
    if (cycle.training_cycle_status === 'ACTIVE') {
      const updatedCycle = await abortActiveTrainingCycle()
      const updatedCycles = [...cycles]
      updatedCycles[index] = updatedCycle
      setCycles(updatedCycles)
    } else {
      const activeCycle = cycles.find((cycle) => cycle.training_cycle_status === 'ACTIVE')
      if (activeCycle) {
        setNotification({
          text: t('report.trainingCycle.notification.activeCycle'),
          buttons: [
            { label: t('common.yes'), action: { type: 'abort_and_activate', index } },
            { label: t('common.no'), action: { type: 'cancel' } }
          ],
          type: 'confirmation'
        })
      } else {
        const updatedCycle = await setTrainingCycleStatusToActive(cycle.training_cycle_id)
        const updatedCycles = [...cycles]
        updatedCycles[index] = updatedCycle
        setCycles(updatedCycles)
      }
    }
  }

  const handleOpenPopupCreate = () => {
    setIsEdit(false)
    setPopupOpen(true)
  }

  const handleOpenPopupUpdate = (cycle) => {
    setIsEdit(true)
    setPopupOpen(true)
    setCurrentCycle(cycle)
  }

  const handleClosePopup = () => {
    setPopupOpen(false)
    setCurrentCycle(null)
    setIsEdit(false)
    // window.scrollTo({ top: window.innerHeight * 0, behavior: "smooth" });
  }

  useEffect(() => {
    if (expandedIndex !== null && contentRefs.current[expandedIndex]) {
      contentRefs.current[expandedIndex].style.maxHeight = contentRefs.current[expandedIndex].scrollHeight + 'px'
    }
  }, [expandedIndex])

  useEffect(() => {
    if (isPopupOpen && popupRef.current) {
      setTimeout(() => {
        const popupPosition = popupRef.current.getBoundingClientRect().top + window.scrollY
        const offsetPosition = popupPosition - window.innerHeight * 0.15
        window.scrollTo({ top: offsetPosition, behavior: 'smooth' })
      }, 100)
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }, [isPopupOpen])

  const fetchData = async () => {
    try {
      const cyclesData = await getAllTrainingCycles()
      setCycles(cyclesData)
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const handleDelete = async (index) => {
    const cycle = cycles[index]
    if (cycle.training_cycle_status === 'ACTIVE') {
      setNotification({
        text: t('report.trainingCycle.notification.activeDelete'),
        buttons: [{ label: t('common.ok'), action: { type: 'cancel' } }],
        type: 'error'
      })
    } else {
      setNotification({
        text: t('report.trainingCycle.notification.confirmDelete', { cycleName: cycle.training_cycle_name }),
        buttons: [
          { label: t('common.yes'), action: { type: 'confirm_delete', index } },
          { label: t('common.no'), action: { type: 'cancel' } }
        ],
        type: 'confirmation'
      })
    }
  }

  const handleNotificationButtonClick = async (action) => {
    if (action.type === 'confirm_delete') {
      const index = action.index
      try {
        await deleteTrainingCycle(cycles[index].training_cycle_id)
        const updatedCycles = [...cycles]
        updatedCycles.splice(index, 1)
        setCycles(updatedCycles)
        setNotification({
          text: t('report.trainingCycle.notification.deleteSuccess'),
          buttons: [],
          type: 'success'
        })
      } catch (error) {
        console.error('Error deleting cycle:', error)
        setNotification({
          text: t('report.trainingCycle.notification.deleteFailed'),
          buttons: [],
          type: 'error'
        })
      }
    } else if (action.type === 'abort_and_activate') {
      const activeCycleIndex = cycles.findIndex((cycle) => cycle.training_cycle_status === 'ACTIVE')
      const newCycleIndex = action.index
      try {
        await abortActiveTrainingCycle()
        const updatedCycle = await setTrainingCycleStatusToActive(cycles[newCycleIndex].training_cycle_id)
        const updatedCycles = [...cycles]
        if (activeCycleIndex !== -1) {
          updatedCycles[activeCycleIndex].training_cycle_status = 'INACTIVE'
        }
        updatedCycles[newCycleIndex] = updatedCycle
      } catch (error) {
        console.error('Error aborting and activating cycle:', error)
      }
    }
    setNotification(null)
    fetchData()
  }

  return (
    <>
      <div className={classes.TrainingCycleTableWrapper}>
        {loading ? (
          <div className={classes.LoadingSpinner}>
            <ClipLoader size={50} color='' loading={loading} />
          </div>
        ) : (
          <div className={classes.SwipeableListWrapper}>
            <SwipeableList className={classes.SwipeableList}>
              {cycles.map((cycle, index) => (
                <SwipeableListItem
                  key={index}
                  leadingActions={leadingActions(index)}
                  trailingActions={trailingActions(index)}
                  className={classes.SwipeableListItem}
                >
                  <div className={`${classes.Accordion} ${expandedIndex === index ? classes.Expanded : ''}`}>
                    <div className={classes.AccordionSummary} onClick={() => handleAccordionToggle(index)}>
                      <div>{cycle.training_cycle_name}</div>
                      <FontAwesomeIcon icon={expandedIndex === index ? faChevronUp : faChevronDown} />
                    </div>
                    <div style={{ width: '120%', height: 2, backgroundColor: 'black', marginTop: '10px' }}></div>
                    <div
                      className={classes.AccordionDetailsWrapper}
                      ref={(el) => (contentRefs.current[index] = el)}
                      style={{
                        maxHeight: expandedIndex === index ? contentRefs.current[index].scrollHeight + 'px' : '0'
                      }}
                    >
                      <div className={classes.AccordionDetails}>
                        <div style={{ marginTop: '10px' }}>
                          <strong>{t('report.trainingCycle.repeat')}</strong> {cycle.repeat_rate} {t('common.unit.times')}
                        </div>
                        <div style={{ marginTop: '10px' }}>
                          <strong>{t('report.trainingCycle.trainingPlans')}</strong>
                        </div>
                        <ul>
                          {cycle.planned_trainingplans_days.map((plan, idx) => (
                            <li key={idx}>{`${plan.trainingplan_name} (Day ${plan.training_day})`}</li>
                          ))}
                        </ul>
                        <div style={{ marginBottom: '10px' }}>
                          <strong>{t('report.trainingCycle.nextPlan')}</strong>{' '}
                          {cycle.next_trainingplan ? cycle.next_trainingplan.trainingplan_name : t('common.none')}
                        </div>
                        <div className={classes.DetailStatusWrapper}>
                          <div>
                            <strong>{t('common.status')}:</strong> {t(`report.trainingCycle.status.${cycle.training_cycle_status}`)}
                          </div>
                          <button className={classes.DetailStatusButton} onClick={() => handleStatusChange(index)}>
                            {cycle.training_cycle_status === 'ACTIVE' ? t('common.abort') : t('common.activate')}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwipeableListItem>
              ))}
            </SwipeableList>
          </div>
        )}
      </div>
      <button className={classes.AddCycleButton} onClick={handleOpenPopupCreate}>
        <FontAwesomeIcon icon={faPlus} />
      </button>
      <div ref={popupRef}>
        <TrainingCyclePopUp isOpen={isPopupOpen} onClose={handleClosePopup} onCycleSaved={fetchData} cycle={currentCycle} isEdit={isEdit} />
      </div>
      {notification && (
        <Notification
          text={notification.text}
          buttons={notification.buttons}
          onButtonClick={handleNotificationButtonClick}
          type={notification.type}
          onClose={fetchData}
        />
      )}
    </>
  )
}

export default TrainingCycleTable
