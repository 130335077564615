import { faAngleLeft, faAngleRight, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Tooltip } from 'react-tooltip'

import Accordion from '../../components/Accordion/Accordion'
import FinishedTrainingTable from '../../components/FinishedTrainingTable/FinishedTrainingTable'
import Footer from '../../components/Footer/Footer'
import NavigationMobile from '../../components/Navigation/BottomNavigation/BottomNavigation'
import Navigation from '../../components/Navigation/TopNavigation/Navigation'
import BMITrendChart from '../../components/Reports/BMITrendChart/BMITrendChart'
import BodyWeightTrendChart from '../../components/Reports/BodyWeightTrendChart/BodyWeightTrendChart'
import ExerciseFrequencyChart from '../../components/Reports/ExerciseFrequencyChart/ExerciseFrequencyChart'
import ExerciseMaxLoadChart from '../../components/Reports/ExerciseMaxLoadChart/ExerciseMaxLoadChart'
import ExerciseMaxWeightChart from '../../components/Reports/ExerciseMaxWeightChart/ExerciseMaxWeightChart'
import TrainingCycleTable from '../../components/TrainingCycleTable/TrainingCycleTable'
import TrainingPlanTable from '../../components/TrainingPlanTable/TrainingPlanTable'
import classes from './Report.module.css'

export const Report = ({ isDarkMode }) => {
  const { t } = useTranslation()
  const [currentView, setCurrentView] = useState(0)
  const views = [
    t('report.chart.headline'),
    t('report.finishedTraining.headline'),
    t('report.trainingCycle.headline'),
    t('report.trainingPlan.headline')
  ]

  const infoTexts = [
    t('report.tooltip.charts'),
    t('report.tooltip.finishedTrainings'),
    t('report.tooltip.trainingCycles'),
    t('report.tooltip.trainingPlans')
  ]

  const goBack = () => {
    if (currentView > 0) {
      setCurrentView(currentView - 1)
    }
  }

  const goForward = () => {
    if (currentView < views.length - 1) {
      setCurrentView(currentView + 1)
    }
  }

  return (
    <>
      <div className={classes.Report}>
        <Navigation />
        <div className={classes.ReportContent}>
          <div className={classes.ReportHeader}>
            <div className={classes.ReportHeaderTop}>
              <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                <h2>{views[currentView]}</h2>
                <div>
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    data-tooltip-id='info-tooltip'
                    data-tooltip-content={infoTexts[currentView]}
                    className={classes.TooltipIcon}
                  />
                  <Tooltip id='info-tooltip' place='bottom' effect='solid' className={classes.TooltipContent} />
                </div>
              </div>
              <div className={classes.ButtonContainer}>
                <button disabled={currentView === 0} onClick={goBack}>
                  <FontAwesomeIcon icon={faAngleLeft} />
                </button>
                <button disabled={currentView === views.length - 1} onClick={goForward}>
                  <FontAwesomeIcon icon={faAngleRight} />
                </button>
              </div>
            </div>
            <hr />
          </div>
          {currentView === 0 && (
            <div className={classes.ChartContainer}>
              <ExerciseFrequencyChart isDarkMode={isDarkMode} />
              <Accordion title={t('report.chart.ExerciseAccordionHeadline')}>
                <ExerciseMaxWeightChart isDarkMode={isDarkMode} />
                <ExerciseMaxLoadChart isDarkMode={isDarkMode} />
              </Accordion>
              <Accordion title={t('report.chart.BodyMetricsAccordionHeadline')}>
                <BodyWeightTrendChart isDarkMode={isDarkMode} />
                <BMITrendChart isDarkMode={isDarkMode} />
              </Accordion>
            </div>
          )}
          {currentView === 1 && (
            <div className={classes.FinishedTrainingsContainer}>
              <FinishedTrainingTable />
            </div>
          )}
          {currentView === 2 && (
            <div className={classes.TrainingplansContainer}>
              <TrainingCycleTable />
            </div>
          )}
          {currentView === 3 && (
            <div className={classes.TrainingplansContainer}>
              <TrainingPlanTable />
            </div>
          )}
        </div>
        <Footer />
      </div>
      <NavigationMobile />
    </>
  )
}

export default Report
