import './i18n'
import './index.css'

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom'

import { isAuthenticated } from './api/auth'
import { getUserProfile } from './api/user'
import { requestNotificationPermission } from './components/Notifications/NotificationManager'
import { ThemeProvider } from './context/ThemeContext'
import { Chat } from './pages/Chat/Chat'
import { Dashboard } from './pages/Dashboard/Dashboard'
import { Feed } from './pages/Feed/Feed'
import { Feedback } from './pages/Feedback/Feedback'
import { Health } from './pages/Health/Health'
import { LandingPage } from './pages/LandingPage/LandingPage'
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy'
import { Profile } from './pages/Profile/Profile'
import ReleaseNotes from './pages/ReleaseNotes/ReleaseNotes'
import { Report } from './pages/Report/Report'
import { Settings } from './pages/Settings/Settings'
import { Training } from './pages/Training/Training'

export const App = () => {
  const [authenticated, setAuthenticated] = useState(null) // Start with null to show loading state
  const [firstname, setFirstname] = useState('')
  const [isMobile, setIsMobile] = useState(false)
  const [isDarkMode, setIsDarkMode] = useState(false)
  const { i18n } = useTranslation()

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const isAuthenticatedResponse = await isAuthenticated()
        setAuthenticated(isAuthenticatedResponse)
        if (!isAuthenticatedResponse) {
          localStorage.clear()
        }
      } catch (error) {
        console.error('Error checking authentication:', error)
        setAuthenticated(false)
        localStorage.clear()
      }
    }

    checkAuthentication()

    const interval = setInterval(checkAuthentication, 60000)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const userProfile = await getUserProfile()
        setFirstname(userProfile.firstname)
        setIsDarkMode(userProfile.is_darkmode)

        const { language_code } = userProfile
        i18n.changeLanguage(language_code)
      } catch (error) {
        console.error(error.message)
      }
    }

    fetchUserProfile()
  }, [i18n])

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)
    }

    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    requestNotificationPermission() // Anfrage der Benachrichtigungs-Erlaubnis
  }, [])

  // Wait until authentication check is done before rendering
  if (authenticated === null) {
    return <div>Loading...</div>
  }

  return (
    <ThemeProvider>
      <Router>
        <Routes>
          {authenticated && isMobile ? (
            <>
              <Route path='/dashboard' element={<Dashboard firstname={firstname} />} />
              <Route path='/report' element={<Report isDarkMode={isDarkMode} />} />
              <Route path='/training' element={<Training />} />
              <Route path='/health' element={<Health />} />
              <Route path='/chat' element={<Chat />} />
              <Route path='/feed' element={<Feed />} />
              <Route path='/profile' element={<Profile />} />
              <Route path='/settings' element={<Settings />} />
              <Route path='/feedback' element={<Feedback />} />
              <Route path='/release-notes' element={<ReleaseNotes />} />
              <Route path='/privacy-policy' element={<PrivacyPolicy />} />
              <Route path='*' element={<Navigate to='/dashboard' />} />
            </>
          ) : (
            <>
              <Route path='/' element={<LandingPage />} />
              <Route path='/privacy-policy' element={<PrivacyPolicy />} />
              <Route path='*' element={<LandingPage />} />
            </>
          )}
        </Routes>
      </Router>
    </ThemeProvider>
  )
}
