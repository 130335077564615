import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ClipLoader from 'react-spinners/ClipLoader'

import classes from './InfoExercisePopUp.module.css'

const InfoExercisePopUp = ({ onClose, selectedExercise }) => {
  const { t } = useTranslation()
  const popupRef = useRef(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (popupRef.current) {
      popupRef.current.scrollTop = 0
    }
  }, [])

  const handleVideoLoadStart = () => {
    setIsLoading(true)
  }

  const handleVideoCanPlay = () => {
    setIsLoading(false)
  }

  return (
    <>
      <div className={classes.Overlay} onClick={onClose} data-testid='overlay'></div>
      <div className={classes.InfoExercisePopUp} ref={popupRef}>
        <div className={classes.InfoExerciseContainer}>
          <div className={classes.InfoExerciseHeader}>
            <h3>{t('training.exercise.exerciseInformation.headline')}</h3>
            <hr />
            <h4 style={{ textAlign: 'center', margin: '10px' }}>{selectedExercise.exercise_name}</h4>
            <button className={classes.CloseButton} onClick={onClose} aria-label='Close'>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          <div className={classes.InfoExerciseContent}>
            <div className={classes.InfoExerciseBodySchema}>
              {isLoading && (
                <div className={classes.LoaderContainer}>
                  <ClipLoader color='' loading={isLoading} size={50} />
                </div>
              )}
              <video
                src={selectedExercise.path_to_gif}
                alt='Exercise Schema'
                autoPlay
                loop
                muted
                playsInline
                style={{ width: '100%', height: 'auto', display: isLoading ? 'none' : 'block' }}
                onLoadStart={handleVideoLoadStart}
                onCanPlay={handleVideoCanPlay}
              ></video>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <div className={classes.InfoExerciseDescription}>
                <p>{t('training.exercise.exerciseInformation.description')}:</p>
                <p>{selectedExercise.exercise_description}</p>
              </div>
              <div className={classes.InfoExerciseDescription}>
                <p>{t('training.exercise.exerciseInformation.targetMuscleGroup')}:</p>
                <p>{selectedExercise.target_musclegroup}</p>
              </div>
              <div className={classes.InfoExerciseDescription}>
                <p>{t('training.exercise.exerciseInformation.secondaryMuscleGroup')}:</p>
                <p>{selectedExercise.secondary_musclegroups}</p>
              </div>
              <div className={classes.InfoExerciseDescription}>
                <p>{t('training.exercise.exerciseInformation.experienceLevel')}:</p>
                <p>{selectedExercise.experience_level}</p>
              </div>
              <div className={classes.InfoExerciseDescription}>
                <p>{t('training.exercise.exerciseInformation.equipment')}:</p>
                <p>{selectedExercise.equipment}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default InfoExercisePopUp
