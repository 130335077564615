import Cookies from 'js-cookie'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { logoutUser } from '../../../api/auth'
import classes from './CookieSettings.module.css'

const CookiePopUp = ({ onClose }) => {
  const { t } = useTranslation()
  const [essentialCookiesEnabled, setEssentialCookiesEnabled] = useState(false)
  const [googleAnalyticsEnabled, setGoogleAnalyticsEnabled] = useState(false)

  useEffect(() => {
    const acceptedCookies = Cookies.get('acceptedCookies') === 'true'
    setEssentialCookiesEnabled(acceptedCookies)
    setGoogleAnalyticsEnabled(Cookies.get('gaEnabled') === 'true')
  }, [])

  const handleSaveSettings = async () => {
    if (essentialCookiesEnabled) {
      Cookies.set('acceptedCookies', 'true', { expires: 365, sameSite: 'Strict', secure: true })
    } else {
      Cookies.remove('acceptedCookies')
      Cookies.remove('refreshToken')
      Cookies.remove('_ga')
      Cookies.remove('_ga_1SY3CDDG3R')
      Cookies.remove('gaEnabled')
      window['ga-disable-G-1SY3CDDG3R'] = true
      await logoutUser() // Log out the user if essential cookies are disabled
      window.location.reload() // Reload the page to reflect the logout
    }

    if (googleAnalyticsEnabled && essentialCookiesEnabled) {
      Cookies.set('gaEnabled', 'true', { expires: 365, sameSite: 'Strict', secure: true })
      window.gtag('config', 'G-1SY3CDDG3R')
    } else {
      Cookies.remove('gaEnabled')
      Cookies.remove('_ga')
      Cookies.remove('_ga_1SY3CDDG3R')
      window['ga-disable-G-1SY3CDDG3R'] = true
    }

    onClose()
  }

  const handleEssentialCookiesToggle = () => {
    setEssentialCookiesEnabled(!essentialCookiesEnabled)
  }

  const handleGoogleAnalyticsToggle = () => {
    setGoogleAnalyticsEnabled(!googleAnalyticsEnabled)
  }

  return (
    <>
      <div className={classes.CookieOverlay}></div>
      <div className={classes.CookieSettingsPopup}>
        <div className={classes.PopupContent}>
          <h2>{t('footer.cookieSettings.title')}</h2>
          <div className={classes.CookieMessage}>
            <p>{t('cookiePopUp.message')}</p>
            <p>{t('cookiePopUp.essentialCookiesRequired')}</p>
            <Link to='/privacy-policy' target='_blank' rel='noopener noreferrer' className={classes.PrivacyPolicyLink}>
              {t('cookiePopUp.privacyPolicyLink')}
            </Link>
            <br />
          </div>
          <div className={classes.CookieOptions}>
            <label>
              <input type='checkbox' checked={essentialCookiesEnabled} onChange={handleEssentialCookiesToggle} />
              {t('footer.cookieSettings.essentialCookies')}
            </label>
            <label>
              <input
                type='checkbox'
                checked={googleAnalyticsEnabled}
                onChange={handleGoogleAnalyticsToggle}
                disabled={!essentialCookiesEnabled}
              />
              {t('footer.cookieSettings.googleAnalytics')}
            </label>
          </div>
          <div className={classes.ButtonContainer}>
            <button className={classes.SaveButton} onClick={handleSaveSettings}>
              {t('common.save')}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default CookiePopUp
