import { faEye, faEyeSlash, faLock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next' // Import useTranslation hook

import { changeUserPassword } from '../../../api/user'
import Notification from '../../Notifications/NotificationPopUp'
import classes from './PasswordChangePopUp.module.css'

const PasswordChangePopup = ({ onClose }) => {
  const { t } = useTranslation()

  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [showOldPassword, setShowOldPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [notification, setNotification] = useState(null)

  const handlePasswordChange = async () => {
    if (newPassword !== confirmPassword) {
      setNotification({ type: 'error', text: t('profile.passwordChangeError') })
      return
    }
    try {
      await changeUserPassword({ old_password: oldPassword, new_password: newPassword })
      setNotification({ type: 'success', text: t('profile.passwordChangeSuccess') })
      setTimeout(() => setNotification(null), 2000)
      setOldPassword('')
      setNewPassword('')
      setConfirmPassword('')
    } catch (error) {
      setNotification({ type: 'error', text: t('profile.passwordChangeFailed') })
    }
  }

  const handleNotificationClose = () => {
    setNotification(null)
  }

  return (
    <>
      <div className={classes.PopupOverlay} onClick={onClose}></div>
      <div className={classes.PasswordChangePopup}>
        <h2>{t('profile.changePassword')}</h2> {/* Use translation for heading */}
        <hr />
        {notification && (
          <Notification
            text={notification.text}
            type={notification.type}
            buttons={[{ label: 'OK', action: handleNotificationClose }]}
            onButtonClick={handleNotificationClose}
          />
        )}
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <div className={classes.InputContainer}>
            <FontAwesomeIcon className={classes.LeftSvg} icon={faLock} />
            <input
              type={showOldPassword ? 'text' : 'password'}
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              placeholder={t('profile.oldPasswordPlaceholder')}
            />
            <button className={classes.InputButton} onClick={() => setShowOldPassword((prev) => !prev)}>
              {showOldPassword ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
            </button>
          </div>
          <div className={classes.InputContainer}>
            <FontAwesomeIcon className={classes.LeftSvg} icon={faLock} />
            <input
              type={showNewPassword ? 'text' : 'password'}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder={t('profile.newPasswordPlaceholder')}
            />
            <button className={classes.InputButton} onClick={() => setShowNewPassword((prev) => !prev)}>
              {showNewPassword ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
            </button>
          </div>
          <div className={classes.InputContainer}>
            <FontAwesomeIcon className={classes.LeftSvg} icon={faLock} />
            <input
              type={showConfirmPassword ? 'text' : 'password'}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder={t('profile.confirmPasswordPlaceholder')}
            />
            <button className={classes.InputButton} onClick={() => setShowConfirmPassword((prev) => !prev)}>
              {showConfirmPassword ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
            </button>
          </div>
          <button className={classes.FullWidthButton} onClick={handlePasswordChange}>
            {t('profile.changePassword')}
          </button>
          <button className={classes.FullWidthButton} onClick={onClose}>
            {t('common.cancel')}
          </button>
        </div>
      </div>
    </>
  )
}

export default PasswordChangePopup
