import Cookies from 'js-cookie'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import logo from '../../assets/images/logo.png'
import tutorial from '../../assets/videos/tutorial.mp4'
import CookiePopUp from '../../components/Cookie/CookiePopUp/CookiePopUp'
import Footer from '../../components/Footer/Footer'
import LoginPopup from '../../components/Login/LoginPopup'
import classes from './LandingPage.module.css'

export const LandingPage = () => {
  const { t } = useTranslation()
  const [isLoginPopupVisible, setLoginPopupVisible] = useState(false)
  const [showCookiePopup, setShowCookiePopup] = useState(true)
  const [showBackToTop, setShowBackToTop] = useState(false)

  const toggleLoginPopup = () => {
    setLoginPopupVisible(!isLoginPopupVisible)
  }

  const closeLoginPopup = () => {
    setLoginPopupVisible(false)
  }

  const handleScroll = () => {
    if (window.scrollY > 300) {
      setShowBackToTop(true)
    } else {
      setShowBackToTop(false)
    }
  }

  useEffect(() => {
    const acceptedCookies = Cookies.get('acceptedCookies')
    if (acceptedCookies === 'true') {
      setShowCookiePopup(false)
    }
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const closeCookiePopUp = () => {
    setShowCookiePopup(false)
  }

  return (
    <>
      <div className={classes.LandingPage}>
        {showCookiePopup && <CookiePopUp onClose={closeCookiePopUp} />}
        {isLoginPopupVisible && <LoginPopup onClose={closeLoginPopup} />}

        {/* Header Section */}
        <div style={{ padding: '10px' }}>
          <header className={classes.Header}>
            <img src={logo} alt='FLEXER Logo' className={classes.Logo} />
            <nav className={classes.Nav}>
              <button onClick={toggleLoginPopup}>{t('common.login')}</button>
            </nav>
          </header>
        </div>

        {/* Main Content Section */}
        <main className={classes.Main}>
          {/* Hero Section */}
          <section className={classes.Hero}>
            <h1>{t('landingpage.welcome')}</h1>
            <p>{t('landingpage.description')}</p>
            <button onClick={toggleLoginPopup}>{t('landingpage.getStarted')}</button>
          </section>

          {/* About the App Section */}
          <section className={classes.About}>
            <h2>{t('landingpage.aboutApp')}</h2>
            <p>{t('landingpage.appDescription')}</p>
          </section>

          {/* Tutorial Section */}
          <section className={classes.Tutorial}>
            <h2>{t('landingpage.howToUse')}</h2>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div className={classes.iPhoneFrame}>
                <video controls className={classes.TutorialVideo}>
                  <source src={tutorial} type='video/mp4' />
                  <track kind='captions' src='captions.vtt' label='English' />
                  {t('landingpage.yourBrowserDoesNotSupportVideo')}
                </video>
              </div>
            </div>
            <ol>
              <li>{t('landingpage.instructions.step1')}</li>
              <li>{t('landingpage.instructions.step2')}</li>
              <li>{t('landingpage.instructions.step3')}</li>
              <li>{t('landingpage.instructions.step4')}</li>
            </ol>
            <p>{t('landingpage.instructions.conclusion')}</p>
          </section>

          {/* About Us Section */}
          <section className={classes.AboutUs}>
            <h2>{t('landingpage.aboutUs')}</h2>
            <p>{t('landingpage.aboutUsDescription')}</p>
          </section>

          <section className={classes.Feedback}>
            <h2>{t('landingpage.feedback')}</h2>
            <p>{t('landingpage.feedbackDescription')}</p>
            <h4>{t('landingpage.email')}</h4>
          </section>

          {/* Donations Section */}
          <section className={classes.Donations}>
            <h2>{t('landingpage.donations')}</h2>
            <p>{t('landingpage.donationsDescription')}</p>
            <a href='https://www.paypal.com/pools/c/96x9jFWbMz' target='_blank' rel='noopener noreferrer' className={classes.DonateButton}>
              {t('landingpage.donateNow')}
            </a>
          </section>

          {/* Back to Top Button */}
          {showBackToTop && (
            <button className={classes.BackToTop} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
              {t('landingpage.backToTop')}
            </button>
          )}
        </main>

        <Footer />
      </div>
    </>
  )
}
