import { apiClient } from './auth'

export const getAllTrainingTypes = async () => {
  const token = localStorage.getItem('access_token')
  const response = await apiClient.get('/training_type/all_training_types', {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return response.data
}
