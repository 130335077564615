import { faCaretLeft, faCaretRight, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { animated, useSpring } from 'react-spring'

import classes from './AddPostPopUp.module.css'

const AddPostPopUp = ({ onClose }) => {
  const popupSpring = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: { tension: 300, friction: 20 }
  })

  const handleOverlayClick = () => {
    onClose()
  }

  const [activeIndex, setActiveIndex] = useState(0)

  const handleNext = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % 3)
  }

  const handlePrev = () => {
    setActiveIndex((prevIndex) => (prevIndex - 1 + 3) % 3)
  }

  return (
    <>
      <animated.div style={popupSpring} className={classes.Overlay} onClick={handleOverlayClick}></animated.div>
      <animated.div style={popupSpring} className={classes.AddPostPopUp}>
        <div className={classes.AddPostContainer}>
          <div className={classes.AddPostHeader}>
            <h3>New Post</h3>
            <hr />
            <button className={classes.CloseButton} onClick={onClose}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>

          <div className={classes.CarouselWrapper}>
            <button className={classes.CarouselChangeButton} onClick={handlePrev}>
              <FontAwesomeIcon icon={faCaretLeft} />
            </button>
            <div className={classes.CarouselContent}>
              {activeIndex === 0 && (
                <div className={classes.customFileUpload}>
                  <input type='file' id='fileUpload' name='fileUpload' className={classes.inputFile} />
                  <label htmlFor='fileUpload' className={classes.FileUploadLabel}>
                    Choose File
                  </label>
                </div>
              )}
              {activeIndex === 1 && <button className={classes.UploadButton}>Upload Training Result</button>}
              {activeIndex === 2 && <button className={classes.UploadButton}>Upload Training Plan</button>}
            </div>
            <button className={classes.CarouselChangeButton} onClick={handleNext}>
              <FontAwesomeIcon icon={faCaretRight} />
            </button>
          </div>
          <textarea className={classes.CommentTextArea} placeholder='Add a comment/description'></textarea>
          <button className={classes.SaveButton}>Save & Upload</button>
        </div>
      </animated.div>
    </>
  )
}

export default AddPostPopUp
