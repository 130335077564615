export const requestNotificationPermission = () => {
  if ('Notification' in window) {
    if (Notification.permission === 'default' || Notification.permission === 'denied') {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          console.warn('Notification permission granted.')
        } else {
          console.warn('Notification permission denied.')
        }
      })
    }
  }
}
export const sendNotification = (title, options) => {
  if ('Notification' in window && Notification.permission === 'granted') {
    new Notification(title, options)
  }
}
