import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'

import classes from './Accordion.module.css'

const Accordion = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const contentRef = useRef(null)

  const toggleAccordion = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    if (isOpen) {
      contentRef.current.style.maxHeight = `${contentRef.current.scrollHeight}px`
    } else {
      contentRef.current.style.maxHeight = '0px'
    }
  }, [isOpen])

  // Handle dynamic changes in the content
  useEffect(() => {
    if (isOpen) {
      const observer = new ResizeObserver(() => {
        contentRef.current.style.maxHeight = `${contentRef.current.scrollHeight}px`
      })

      observer.observe(contentRef.current)

      return () => {
        observer.disconnect()
      }
    }
  }, [isOpen, children])

  return (
    <div className={classes.Accordion}>
      <button onClick={toggleAccordion} className={classes.AccordionTitle}>
        {title}
        <FontAwesomeIcon icon={faAngleDown} className={`${classes.Icon} ${isOpen ? classes.Rotate : ''}`} />
      </button>
      <div ref={contentRef} className={`${classes.AccordionContent} ${isOpen ? classes.Open : ''}`}>
        {children}
      </div>
    </div>
  )
}

export default Accordion
