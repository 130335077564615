import { apiClient } from './auth'

export const searchUsername = async (username) => {
  const token = localStorage.getItem('access_token')
  const response = await apiClient.get('/friend/request/search_username', {
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: {
      username: username
    }
  })
  return response.data
}

export const getAllPendingFriendRequests = async () => {
  const token = localStorage.getItem('access_token')
  const response = await apiClient.get('/friend/request/all_pending', {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return response.data
}

export const sendFriendRequest = async (receiverId) => {
  const token = localStorage.getItem('access_token')
  try {
    const response = await apiClient.post(
      '/friend/request/send',
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        params: {
          receiver_id: receiverId
        }
      }
    )
    return response.data
  } catch (error) {
    console.error('Error sending friend request:', error.response.data) // Log the error response
    throw error
  }
}

export const acceptFriendRequest = async (friendRequestId) => {
  const token = localStorage.getItem('access_token')
  const response = await apiClient.post(
    '/friend/request/accept',
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      params: {
        friend_request_id: friendRequestId
      }
    }
  )
  return response.data
}

export const rejectFriendRequest = async (friendRequestId) => {
  const token = localStorage.getItem('access_token')
  const response = await apiClient.post(
    '/friend/request/reject',
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      params: {
        friend_request_id: friendRequestId
      }
    }
  )
  return response.data
}

export const withdrawFriendRequest = async (friendRequestId) => {
  const token = localStorage.getItem('access_token')
  const response = await apiClient.post(
    '/friend/request/withdraw',
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      params: {
        friend_request_id: friendRequestId
      }
    }
  )
  return response.data
}

export const getAllFriends = async () => {
  const token = localStorage.getItem('access_token')
  const response = await apiClient.get('/friend/all', {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return response.data
}

export const deleteFriend = async (friendUserId) => {
  const token = localStorage.getItem('access_token')
  const response = await apiClient.delete(`/friend/delete_friend/${friendUserId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return response.data
}
