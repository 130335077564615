import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getExercisesBySameTargetMuscleGroup } from '../../../../api/exercise'
import classes from './ChangeExercisePopUp.module.css'

const ChangeExercisePopUp = ({ onClose, exercise, onExerciseSelect }) => {
  const { t } = useTranslation()
  const [filteredExercises, setFilteredExercises] = useState([])
  const popupRef = useRef(null)

  useEffect(() => {
    const fetchExercises = async () => {
      try {
        const exercises = await getExercisesBySameTargetMuscleGroup(exercise.exercise_id)
        setFilteredExercises(exercises)
      } catch (error) {
        console.error('Failed to fetch exercises', error)
      }
    }

    fetchExercises()
  }, [exercise.exercise_id])

  useEffect(() => {
    if (popupRef.current) {
      popupRef.current.scrollTop = 0
    }
  }, [])

  return (
    <>
      <div className={classes.Overlay} onClick={onClose} data-testid='overlay'></div>
      <div className={classes.ChangeExercisePopUp} ref={popupRef}>
        <div className={classes.ChangeExerciseContainer}>
          <div className={classes.ChangeExerciseHeader}>
            <h3>{t('training.exercise.changeExercise.headline')}</h3>
            <hr />
            <button className={classes.CloseButton} onClick={onClose} aria-label='Close'>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          <div className={classes.ChangeExerciseContent}></div>
          <div className={classes.ExerciseList}>
            {filteredExercises.map((exercise) => (
              <button key={exercise.exercise_id} className={classes.ExerciseItem} onClick={() => onExerciseSelect(exercise.exercise_id)}>
                {exercise.exercise_name}
              </button>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default ChangeExercisePopUp
