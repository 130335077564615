import 'react-swipeable-list/dist/styles.css'

import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ClipLoader } from 'react-spinners'
import { LeadingActions, SwipeAction, SwipeableList, SwipeableListItem } from 'react-swipeable-list'

import { getAllFinishedTrainings, getFinishedTrainingById } from '../../api/finishedTraining'
import classes from './FinishedTrainingTable.module.css'
import InfoPopUp from './InfoPopUp'

const FinishedTrainingTable = () => {
  const { t } = useTranslation()
  const [finishedTrainings, setFinishedTrainings] = useState([])
  const [trainingInfo, setTrainingInfo] = useState(null)
  const [isPopupOpen, setPopupOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const popupRef = useRef(null)

  useEffect(() => {
    const fetchFinishedTrainings = async () => {
      setLoading(true)
      try {
        const data = await getAllFinishedTrainings()
        setFinishedTrainings(data)
      } catch (error) {
        console.error('Error fetching finished trainings:', error)
      } finally {
        setLoading(false)
      }
    }

    fetchFinishedTrainings()
  }, [])

  const formatDate = (dateString) => {
    if (!dateString) return ''
    const [day, month, year] = dateString.split('-').reverse()
    return `${day}.${month}.${year}`
  }

  useEffect(() => {
    if (isPopupOpen && popupRef.current) {
      setTimeout(() => {
        const popupPosition = popupRef.current.getBoundingClientRect().top + window.scrollY
        const offsetPosition = popupPosition - window.innerHeight * 0.15
        window.scrollTo({ top: offsetPosition, behavior: 'smooth' })
      }, 100)
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }, [isPopupOpen])

  const leadingActions = (trainingId) => (
    <LeadingActions>
      <SwipeAction
        onClick={async () => {
          setLoading(true)
          try {
            const data = await getFinishedTrainingById(trainingId)
            setTrainingInfo(data)
            setPopupOpen(true)
          } catch (error) {
            console.error('Error fetching training info:', error)
          } finally {
            setLoading(false)
          }
        }}
      >
        <div className={classes.SwipeActionButtonLeading}>{t('report.finishedTraining.finishedTrainingTable.info')}</div>
      </SwipeAction>
    </LeadingActions>
  )

  const handlePopupClose = () => {
    setPopupOpen(false)
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <>
      <div className={classes.FinishedTrainingTable}>
        {loading ? (
          <div className={classes.LoadingSpinner}>
            <ClipLoader size={50} color='' loading={loading} />
          </div>
        ) : (
          <div className={classes.Table}>
            <div className={classes.TableHeader}>
              <div className={classes.TableCell}>{t('report.finishedTraining.finishedTrainingTable.name')}</div>
              <div className={classes.TableCell}>{t('report.finishedTraining.finishedTrainingTable.date')}</div>
              <div className={classes.TableCell}>{t('report.finishedTraining.finishedTrainingTable.duration')}</div>
              <div className={classes.TableCell}>{t('report.finishedTraining.finishedTrainingTable.type')}</div>
            </div>
            <div className={classes.TableBody}>
              <SwipeableList>
                {finishedTrainings.map((training) => (
                  <SwipeableListItem
                    className={classes.SwipeableListItem}
                    key={`${training.finished_training_id}`}
                    leadingActions={leadingActions(training.finished_training_id)}
                  >
                    <div className={classes.TableData}>
                      <div className={classes.TableRow}>{training.training_name}</div>
                      <div className={classes.TableRow}>{formatDate(training.training_date)}</div>
                      <div className={classes.TableRow}>
                        {training.training_duration} {t('report.finishedTraining.infoPopUp.minutes')}
                      </div>
                      <div className={classes.TableRow}>{training.training_type_name}</div>
                    </div>
                  </SwipeableListItem>
                ))}
              </SwipeableList>
            </div>
          </div>
        )}
      </div>
      {isPopupOpen && <InfoPopUp trainingInfo={trainingInfo} onClose={handlePopupClose} ref={popupRef} />}
    </>
  )
}

export default FinishedTrainingTable
