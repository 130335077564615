import { faInfoCircle, faPause, faPlay, faStop, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'
import { v4 as uuidv4 } from 'uuid'

import { getAllExercises } from '../../api/exercise'
import { getAllTrainingTypes } from '../../api/trainingType'
import logo from '../../assets/images/logo.png'
import { calculateCalories } from '../../components/Calories/CalculateCalories/calculateCalories'
import Exercise from '../../components/Exercise/Exercise'
import Footer from '../../components/Footer/Footer'
import NavigationMobile from '../../components/Navigation/BottomNavigation/BottomNavigation'
import Navigation from '../../components/Navigation/TopNavigation/Navigation'
import { sendNotification } from '../../components/Notifications/NotificationManager'
import Notification from '../../components/Notifications/NotificationPopUp'
import StopPopUp from '../../components/Notifications/StopPopUp'
import SelectTrainingPlanPopUp from '../../components/PopUp/ExercisePopUps/SelectTrainingPlanPopUp'
import TrainingComparison from '../../components/TrainingComparison/TrainingComparison'
import classes from './Training.module.css'

export const Training = () => {
  const [timer, setTimer] = useState(parseInt(localStorage.getItem('timer')) || 0)
  const [isActive, setIsActive] = useState(localStorage.getItem('isActive') === 'true' || false)
  const [isPaused, setIsPaused] = useState(localStorage.getItem('isPaused') === 'true' || false)
  const [activeButton, setActiveButton] = useState(localStorage.getItem('activeButton') || null)
  const [exercises, setExercises] = useState(JSON.parse(localStorage.getItem('exercises')) || [])
  const [showTrainingPopup, setShowTrainingPopup] = useState(() => {
    const storedPopupState = localStorage.getItem('showTrainingPopup')
    if (storedPopupState !== null) {
      return JSON.parse(storedPopupState)
    } else {
      return localStorage.getItem('selectedTrainingType') === null
    }
  })
  const [showStopPopup, setShowStopPopup] = useState(false)
  const [showNotification, setShowNotification] = useState(false)
  const [notificationText, setNotificationText] = useState('')
  const [notificationButtons, setNotificationButtons] = useState([])
  const [notificationType, setNotificationType] = useState('')
  const [handleFunction, setHandleFunction] = useState('')
  const [trainingTypes, setTrainingTypes] = useState([])
  const [selectedTrainingType, setSelectedTrainingType] = useState(localStorage.getItem('selectedTrainingType') || '')
  const [startTime, setStartTime] = useState(localStorage.getItem('startTime') || '')
  const [showTrainingPlanPopUp, setShowTrainingPlanPopUp] = useState(false)
  const [calories, setCalories] = useState(() => {
    const storedCalories = localStorage.getItem('calories')
    return storedCalories ? JSON.parse(storedCalories) : 0
  })
  // eslint-disable-next-line no-unused-vars
  const [allExercises, setAllExercises] = useState([])
  const [trainingCycleId, setTrainingCycleId] = useState(localStorage.getItem('trainingCycleId')) || null
  const [isTrainingFinished, setIsTrainingFinished] = useState(false)
  const location = useLocation()
  const fromWidget = sessionStorage.getItem('fromWidget') === 'true'
  const trainingPlan = location.state?.trainingPlan
  const { t } = useTranslation()

  useEffect(() => {
    getAllTrainingTypes()
      .then((data) => setTrainingTypes(data))
      .catch((error) => console.error('Error fetching training types:', error))
    const storedSelectedTrainingType = localStorage.getItem('selectedTrainingType')
    if (storedSelectedTrainingType) {
      setSelectedTrainingType(storedSelectedTrainingType)
    }
  }, [])

  useEffect(() => {
    localStorage.setItem('timer', timer)
    localStorage.setItem('isActive', isActive)
    localStorage.setItem('isPaused', isPaused)
    localStorage.setItem('activeButton', activeButton)
    localStorage.setItem('exercises', JSON.stringify(exercises))
    localStorage.setItem('selectedTrainingType', selectedTrainingType)
    localStorage.setItem('startTime', startTime)
    localStorage.setItem('showTrainingPopup', showTrainingPopup)
  }, [timer, isActive, isPaused, activeButton, exercises, selectedTrainingType, startTime, showTrainingPopup])

  useEffect(() => {
    const storedExercises = JSON.parse(localStorage.getItem('exercises'))
    if (storedExercises) {
      setExercises(storedExercises)
    }
    setActiveButton(localStorage.getItem('activeButton'))
  }, [])

  const handleStart = () => {
    const now = new Date()
    if (startTime === '') {
      setStartTime(now.toISOString())
      localStorage.setItem('startTime', now.toISOString())
    } else {
      const pauseTime = localStorage.getItem('pauseTime')
      const elapsed = pauseTime ? parseInt(pauseTime) : 0
      const newStart = new Date(now.getTime() - elapsed * 1000)
      setStartTime(newStart.toISOString())
      localStorage.setItem('startTime', newStart.toISOString())
    }
    setIsActive(true)
    setIsPaused(false)
    setActiveButton('start')
    localStorage.setItem('isPaused', false)
  }

  const handlePause = () => {
    setIsActive(false)
    setIsPaused(true)
    setActiveButton('pause')
    localStorage.setItem('isPaused', true)
    const now = new Date()
    const start = new Date(startTime)
    const secondsPassed = Math.floor((now - start) / 1000)
    localStorage.setItem('pauseTime', secondsPassed)
  }

  useEffect(() => {
    let interval = null
    if (isActive) {
      interval = setInterval(() => {
        const now = new Date()
        const start = new Date(startTime)
        const secondsPassed = Math.floor((now - start) / 1000)
        setTimer(secondsPassed)
      }, 1000)
    } else if (!isActive && timer !== 0) {
      clearInterval(interval)
    }
    return () => clearInterval(interval)
  }, [isActive, timer, startTime])

  const handleStop = () => {
    setShowStopPopup(true)
  }

  const handleStopConfirmation = (confirmed) => {
    if (confirmed) {
      setIsActive(false)
      setIsPaused(false)
      setTimer(0)
      setShowTrainingPopup(true)
      localStorage.setItem('activeButton', null)
      localStorage.setItem('showTrainingPopup', true)
      setExercises([])
      setSelectedTrainingType('')
      setStartTime('')

      const keysToKeep = [
        'access_token',
        'i18nextLng',
        'userProfile',
        'timer',
        'isActive',
        'isPaused',
        'activeButton',
        'exercises',
        'selectedTrainingType',
        'startTime',
        'showTrainingPopup',
        'calories',
        'weight',
        'height' // Keep height in local storage
      ]

      // Get all keys from localStorage
      const allKeys = Object.keys(localStorage)

      // Remove keys that are not in the keysToKeep list
      allKeys.forEach((key) => {
        if (!keysToKeep.includes(key)) {
          localStorage.removeItem(key)
        }
      })
    }
    setShowStopPopup(false)
  }

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    const remainingSeconds = seconds % 60
    return `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`
  }

  const handleTrainingTypeChange = (event) => {
    setSelectedTrainingType(event.target.value)
  }

  const addExercise = () => {
    const exerciseKey = uuidv4()
    const newExercise = { key: exerciseKey }
    setExercises([newExercise, ...exercises])
  }

  const removeExercise = (exerciseKey) => {
    setExercises((prevExercises) => prevExercises.filter((exercise) => exercise.key !== exerciseKey))
  }

  const toggleTrainingPlanPopUp = () => {
    setShowTrainingPlanPopUp(true)
  }

  const handleTrainingPlanSelection = (selectedPlan) => {
    const updatedExercises = selectedPlan.exercises.map((exercise) => ({
      exercise_id: exercise.exercise_id,
      key: uuidv4()
    }))

    setSelectedTrainingType(selectedPlan.training_type_name)
    setTimer(0)
    setStartTime(new Date().toISOString())
    setIsActive(true)
    setIsPaused(false)
    setActiveButton('start')
    localStorage.setItem('isPaused', false)
    setExercises(updatedExercises)
    setShowTrainingPopup(null)
  }

  const updateSelectedExercises = (exerciseData) => {
    const {
      key,
      exerciseData: { exercise_id, finished_sets, exercise_duration, exercise_start, exercise_end }
    } = exerciseData

    const formatTime = (time) => {
      if (!time || isNaN(Date.parse(time))) return null // Check if time is valid
      const date = new Date(time)
      return date.toISOString().split('T')[1]
    }

    const updatedExercises = exercises.map((exercise) => {
      if (exercise.key === key) {
        return {
          ...exercise,
          exercise_id: exercise_id,
          finished_exercise_duration: exercise_duration !== undefined ? exercise_duration : null,
          finished_exercise_start: formatTime(exercise_start),
          finished_exercise_end: formatTime(exercise_end),
          finished_sets: finished_sets !== undefined ? finished_sets : null
        }
      }
      return exercise
    })

    setExercises(updatedExercises)
    localStorage.setItem('exercises', JSON.stringify(updatedExercises))
  }

  const handleNotification = (text, type) => {
    setNotificationText(text)
    setNotificationButtons([{ label: 'OK', action: 'cancel' }])
    setNotificationType(type)
    setHandleFunction(() => handleDiscardNotificationButtonClick)
    setShowNotification(true)
  }

  useEffect(() => {
    const fetchAndCalculateCalories = async () => {
      const selectedTrainingType = localStorage.getItem('selectedTrainingType')
      const totalCalories = await calculateCalories(selectedTrainingType, timer, handleNotification)
      setCalories(totalCalories)
      localStorage.setItem('calories', JSON.stringify(totalCalories))
    }

    fetchAndCalculateCalories()
  }, [timer])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const exercisesData = await getAllExercises()
        setAllExercises(exercisesData)
      } catch (error) {
        console.error('Error fetching exercises:', error)
      }
    }
    fetchData()
  }, [])

  const handlePopupSubmit = () => {
    if (selectedTrainingType !== '') {
      setShowTrainingPopup(false)
      handleStart()
    } else {
      setNotificationText(t('training.trainingTypePopUp.trainingTypeMissing'))
      setNotificationButtons([{ label: t('common.ok'), action: 'dismiss' }])
      setNotificationType('alert')
      setHandleFunction(() => handleNotificationButtonClick)
      setShowNotification(true)
    }
  }

  const handleNotificationButtonClick = (action) => {
    if (action === 'dismiss') {
      setShowNotification(false)
    }
  }

  const discardTraining = () => {
    setNotificationText(t('training.confirmDiscard'))
    setNotificationButtons([
      { label: t('common.yes'), action: 'discard' },
      { label: t('common.no'), action: 'cancel' }
    ])
    setNotificationType('confirmation')
    setHandleFunction(() => handleDiscardNotificationButtonClick)
    setShowNotification(true)
  }

  const handleDiscardNotificationButtonClick = (action) => {
    if (action === 'cancel') {
      setShowNotification(false)
    } else if (action === 'discard') {
      setShowNotification(false)
      handleStopConfirmation(true)
    }
  }

  useEffect(() => {
    if (fromWidget && trainingPlan) {
      setSelectedTrainingType(trainingPlan.training_type_name)
      handleTrainingPlanSelection(trainingPlan)
      setTrainingCycleId(trainingCycleId)
      sessionStorage.removeItem('fromWidget')
      localStorage.setItem('selectedTrainingPlanName', trainingPlan.trainingplan_name)
    }
  }, [fromWidget, trainingPlan])

  const moveExercise = (dragIndex, hoverIndex) => {
    const dragExercise = exercises[dragIndex]
    const newExercises = [...exercises]
    newExercises.splice(dragIndex, 1)
    newExercises.splice(hoverIndex, 0, dragExercise)
    setExercises(newExercises)
  }

  useEffect(() => {
    if (isActive) {
      const handleVisibilityChange = () => {
        if (document.hidden) {
          sendNotification('Workout Reminder', {
            body: t('training.notification.workoutReminder'),
            icon: logo
          })
        }
      }

      document.addEventListener('visibilitychange', handleVisibilityChange)

      return () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange)
      }
    }
  }, [isActive])

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={classes.Training}>
        <Navigation />
        <div className={classes.TrainingContent}>
          <div style={{ position: 'relative', zIndex: '2' }}>
            <div className={classes.TrainingHeader}>
              <div className={classes.TrainingHeaderTop}>
                <div className={classes.TrainingHeadline}>
                  <h2>{t('training.headline')}</h2>
                  <FontAwesomeIcon icon={faInfoCircle} className={classes.TooltipIcon} data-tooltip-id='trainingPageTooltip' />
                  <Tooltip
                    id='trainingPageTooltip'
                    className={classes.TooltipContent}
                    effect='solid'
                    place='bottom'
                    content={t('training.trainingPageTooltip')}
                  />
                </div>
                <button onClick={discardTraining}>
                  <FontAwesomeIcon icon={faTrashCan} />
                </button>
              </div>
              <hr />
            </div>
          </div>
          {showTrainingPopup && (
            <div className={classes.TrainingPopup}>
              <label htmlFor='TrainingCategory'>{t('training.trainingTypePopUp.typeOfTraining')}:</label>
              <div className={classes.Select}>
                <select id='TrainingCategory' name='TrainingCategory' value={selectedTrainingType} onChange={handleTrainingTypeChange}>
                  <option disabled value=''>
                    {t('training.trainingTypePopUp.selectTrainingType')}
                  </option>
                  {trainingTypes.map((trainingType) => (
                    <option key={trainingType.training_type_id} value={trainingType.training_type_name}>
                      {trainingType.training_type_name}
                    </option>
                  ))}
                </select>
              </div>
              <button onClick={handlePopupSubmit}>{t('training.trainingTypePopUp.startTraining')}</button>
            </div>
          )}
          {showTrainingPopup && <div className={classes.TrainingOverlay}></div>}
          <div className={classes.TimeContainer}>
            <div className={classes.TimeButtons}>
              <button className={`${classes.TimeStart} ${activeButton === 'start' ? classes.ActiveButton : ''}`} onClick={handleStart}>
                <FontAwesomeIcon icon={faPlay} />
              </button>
              <button className={`${classes.TimePause} ${activeButton === 'pause' ? classes.ActiveButton : ''}`} onClick={handlePause}>
                <FontAwesomeIcon icon={faPause} />
              </button>
              <button className={`${classes.TimeEnd} ${activeButton === 'stop' ? classes.ActiveButton : ''}`} onClick={handleStop}>
                <FontAwesomeIcon icon={faStop} />
              </button>
            </div>
            <span className={classes.TimeStamp}>{formatTime(timer)}</span>
          </div>
          <div className={classes.AddExercise}>
            <div className={classes.HeaderLowerPart}>
              <div className={classes.CaloriesDisplay}>
                <p data-tooltip-id='caloriesTooltip'>{calories}</p>
                <p className={classes.label}>{t('common.unit.kcal')}</p>
                <Tooltip
                  id='caloriesTooltip'
                  className={classes.TooltipContent}
                  content={t('training.caloriesTooltip')}
                  effect='solid'
                  place='bottom'
                />
              </div>
              {(selectedTrainingType === 'Krafttraining' || selectedTrainingType === 'Strength training') && (
                <div className={classes.ButtonContainer}>
                  <button onClick={toggleTrainingPlanPopUp} className={classes.SelectTrainingPlan}>
                    {t('training.exercise.addTrainingsplan')}
                  </button>
                </div>
              )}
            </div>
          </div>
          {selectedTrainingType !== 'Krafttraining' && selectedTrainingType !== 'Strength training' && (
            <p className={classes.GeneralTrainingNote}>{t('training.trainingTypeInfoText')}</p>
          )}
          {(selectedTrainingType === 'Krafttraining' || selectedTrainingType === 'Strength training') && (
            <div className={classes.ExerciseContainer}>
              <button onClick={addExercise} className={classes.AddExerciseButton}>
                {t('training.exercise.addExercise')}
              </button>
              {exercises.map((exercise, index) => (
                <Exercise
                  key={exercise.key}
                  index={index}
                  exercise={exercise}
                  exercises={exercises}
                  onRemove={() => removeExercise(exercise.key)}
                  onUpdateExercise={updateSelectedExercises}
                  moveExercise={moveExercise}
                />
              ))}
            </div>
          )}
          {showTrainingPlanPopUp && (
            <SelectTrainingPlanPopUp
              onSelectTrainingPlan={handleTrainingPlanSelection}
              onClose={() => setShowTrainingPlanPopUp(false)}
              useAbsolutePosition={true}
            />
          )}
        </div>
        <Footer />
      </div>
      {showStopPopup && (
        <StopPopUp
          onSubmit={() => {
            handleStopConfirmation(true)
            setIsTrainingFinished(true)
          }}
          onCancel={() => handleStopConfirmation(false)}
          exercises={exercises}
          selectedTrainingType={selectedTrainingType}
          timer={timer}
          startTime={startTime}
          calories={calories}
          trainingCycleId={trainingCycleId}
        />
      )}
      {showNotification && (
        <Notification text={notificationText} buttons={notificationButtons} onButtonClick={handleFunction} type={notificationType} />
      )}
      {isTrainingFinished && <TrainingComparison onClose={() => setIsTrainingFinished(false)} />}
      <NavigationMobile />
    </DndProvider>
  )
}

export default Training
