import 'react-swipeable-list/dist/styles.css'

import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ClipLoader } from 'react-spinners'
import { LeadingActions, SwipeAction, SwipeableList, SwipeableListItem, TrailingActions } from 'react-swipeable-list'

import { getAllTrainingCyclesByTrainingPlanId } from '../../api/cycle'
import { getAllExercises } from '../../api/exercise'
import { deleteTrainingPlan, getAllTrainingPlans, postTrainingPlan, updateTrainingPlan } from '../../api/trainingPlan'
import { getAllTrainingTypes } from '../../api/trainingType'
import Notification from '../Notifications/NotificationPopUp'
import TrainingPlanPopup from '../PopUp/TrainingPlanPopUp/TrainingPlanPopUp'
import classes from './TrainingPlanTable.module.css'

const TrainingPlanTable = () => {
  const { t } = useTranslation()
  const [trainingPlans, setTrainingPlans] = useState([])
  const [isPopupOpen, setPopupOpen] = useState(false)
  const [currentTrainingPlan, setCurrentTrainingPlan] = useState({ trainingplan_name: '', training_type_name: '', exercises: [] })
  const [trainingTypes, setTrainingTypes] = useState([])
  const [exercises, setExercises] = useState([])
  const [selectedTrainingType, setSelectedTrainingType] = useState('')
  const [selectedExercises, setSelectedExercises] = useState([])
  const [nameError, setNameError] = useState(false)
  const [typeError, setTypeError] = useState(false)
  const [isAddPopup, setIsAddPopup] = useState(true)
  const [notification, setNotification] = useState({ visible: false, text: '', type: 'alert' })
  const [loading, setLoading] = useState(true)
  const [scrollPosition, setScrollPosition] = useState(0)
  const popupRef = useRef(null)

  useEffect(() => {
    setLoading(true)
    Promise.all([getAllExercises(), getAllTrainingTypes(), getAllTrainingPlans()])
      .then(([exercisesData, trainingTypesData, trainingPlansData]) => {
        setExercises(exercisesData)
        setTrainingTypes(trainingTypesData)
        setTrainingPlans(trainingPlansData)
      })
      .catch((error) => console.error('Error fetching data:', error))
      .finally(() => setLoading(false))
  }, [])

  const saveTrainingPlan = async () => {
    try {
      setNameError(!currentTrainingPlan.trainingplan_name.trim())
      setTypeError(!selectedTrainingType.trim())

      if (!currentTrainingPlan.trainingplan_name.trim() || !selectedTrainingType.trim()) {
        return
      }

      if (selectedExercises.length === 0) {
        setNotification({ visible: true, text: t('report.trainingPlan.notification.atLeastOneExercise'), type: 'alert' })
        return
      }

      const exercisesData = selectedExercises.map((exerciseId) => ({
        exercise_id: exerciseId,
        sets: []
      }))

      const trainingPlanData = {
        trainingplan_name: currentTrainingPlan.trainingplan_name,
        training_type_name: selectedTrainingType,
        exercises: exercisesData
      }

      if (isAddPopup) {
        await postTrainingPlan(trainingPlanData)
      } else {
        await updateTrainingPlan(currentTrainingPlan.trainingplan_id, trainingPlanData)
      }
      setTrainingPlans(await getAllTrainingPlans())
      setPopupOpen(false)
      window.scrollTo({ top: 0, behavior: 'smooth' })
    } catch (error) {
      console.error('Error saving training plan:', error)
    }
  }

  const openAddPopup = () => {
    setScrollPosition(window.scrollY)
    setCurrentTrainingPlan({ trainingplan_name: '', training_type_name: '', exercises: [] })
    setSelectedTrainingType('')
    setSelectedExercises([])
    setIsAddPopup(true)
    window.scrollTo({ top: 0, behavior: 'smooth' })
    setPopupOpen(true)
  }

  const openEditPopup = (trainingPlanId) => {
    setScrollPosition(window.scrollY)
    const selectedTrainingPlan = trainingPlans.find((training) => training.trainingplan_id === trainingPlanId)
    setCurrentTrainingPlan(selectedTrainingPlan)
    setSelectedTrainingType(selectedTrainingPlan.training_type_name)
    setSelectedExercises(selectedTrainingPlan.exercises.map((exercise) => exercise.exercise_id))
    setIsAddPopup(false)
    window.scrollTo({ top: 0, behavior: 'smooth' })
    setPopupOpen(true)
  }

  const closePopup = () => {
    setPopupOpen(false)
    window.scrollTo({ top: scrollPosition, behavior: 'smooth' })
  }

  useEffect(() => {
    if (isPopupOpen && popupRef.current) {
      setTimeout(() => {
        const popupPosition = popupRef.current.getBoundingClientRect().top + window.scrollY
        const offsetPosition = popupPosition - window.innerHeight * 0.15
        window.scrollTo({ top: offsetPosition, behavior: 'smooth' })
      }, 100)
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }, [isPopupOpen])

  const leadingActions = (trainingId) => (
    <LeadingActions>
      <SwipeAction onClick={() => openEditPopup(trainingId)}>
        <div className={classes.SwipeActionButtonLeading}>{t('common.edit')}</div>
      </SwipeAction>
    </LeadingActions>
  )

  const trailingActions = (trainingId) => (
    <TrailingActions>
      <SwipeAction onClick={() => handleDeleteTrainingPlan(trainingId)}>
        <div className={classes.SwipeActionButtonTrailing}>{t('common.delete')}</div>
      </SwipeAction>
    </TrailingActions>
  )

  const handleDeleteTrainingPlan = async (trainingId) => {
    try {
      const cycles = await getAllTrainingCyclesByTrainingPlanId(trainingId)
      if (cycles.length > 0) {
        const cycleNames = cycles.map((cycle) => cycle.training_cycle_name).join(', ')
        setNotification({
          visible: true,
          text: t('report.trainingPlan.notification.inUseCycles', { cycleNames }),
          type: 'alert'
        })
        return
      }

      setNotification({
        visible: true,
        text: t('common.confirmDelete'),
        type: 'confirmation',
        onConfirm: async () => {
          try {
            await deleteTrainingPlan(trainingId)

            const updatedTrainingPlans = trainingPlans.filter((plan) => plan.trainingplan_id !== trainingId)
            setTrainingPlans(updatedTrainingPlans)

            setNotification({ visible: false, text: '', type: 'alert' })
          } catch (error) {
            console.error('Error deleting training plan:', error)
            setNotification({ visible: true, text: t('common.deleteFailed'), type: 'error' })
          }
        },
        onCancel: () => {
          setNotification({ visible: false, text: '', type: 'alert' })
        }
      })
    } catch (error) {
      console.error('Error deleting training plan:', error)
    }
  }

  return (
    <div className={classes.TrainingPlanTableWrapper}>
      {nameError || typeError ? (
        <Notification
          text={t('report.trainingCycle.trainingCyclePopUp.notification.error.nameAndTypeRequired')}
          buttons={[
            {
              label: t('common.ok'),
              action: 'close'
            }
          ]}
          type='error'
          onButtonClick={(action) => {
            if (action === 'close') {
              setNameError(false)
              setTypeError(false)
            }
          }}
        />
      ) : null}
      {notification.visible && (
        <Notification
          text={notification.text}
          buttons={[
            ...(notification.type === 'confirmation'
              ? [
                { label: t('common.yes'), action: 'confirm' },
                { label: t('common.no'), action: 'cancel' }
              ]
              : [{ label: t('common.ok'), action: 'close' }])
          ]}
          type={notification.type}
          onButtonClick={(action) => {
            if (action === 'confirm' && notification.onConfirm) {
              notification.onConfirm()
            } else if (action === 'cancel' && notification.onCancel) {
              notification.onCancel()
            } else {
              setNotification({ visible: false, text: '', type: 'alert' })
            }
          }}
        />
      )}
      <div className={classes.TrainingPlanTable}>
        {loading ? (
          <div className={classes.LoadingSpinner}>
            <ClipLoader size={50} color='' loading={loading} />
          </div>
        ) : (
          <div className={classes.Table}>
            <div className={classes.TableHeader}>
              <div className={classes.TableCell}>{t('report.trainingPlan.name')}</div>
              <div className={classes.TableCell}>{t('report.trainingPlan.type')}</div>
            </div>
            <div className={classes.TableBody}>
              <SwipeableList>
                {trainingPlans.map((training) => (
                  <SwipeableListItem
                    className={classes.SwipeableListItem}
                    key={training.trainingplan_id}
                    leadingActions={leadingActions(training.trainingplan_id)}
                    trailingActions={trailingActions(training.trainingplan_id)}
                  >
                    <div className={classes.TableData}>
                      <div className={classes.TableRow}>{training.trainingplan_name}</div>
                      <div className={classes.TableRow}>{training.training_type_name}</div>
                    </div>
                  </SwipeableListItem>
                ))}
              </SwipeableList>
            </div>
          </div>
        )}
      </div>
      <button onClick={openAddPopup} className={classes.AddTrainingPlan}>
        <FontAwesomeIcon icon={faPlus} />
      </button>
      <TrainingPlanPopup
        isOpen={isPopupOpen}
        isAddPopup={isAddPopup}
        currentTrainingPlan={currentTrainingPlan}
        trainingTypes={trainingTypes}
        exercises={exercises}
        selectedTrainingTypeId={trainingTypes.find((type) => type.training_type_name === selectedTrainingType)?.training_type_id}
        selectedTrainingType={selectedTrainingType}
        selectedExercises={selectedExercises}
        setCurrentTrainingPlan={setCurrentTrainingPlan}
        setSelectedTrainingType={setSelectedTrainingType}
        setSelectedExercises={setSelectedExercises}
        saveTrainingPlan={saveTrainingPlan}
        closePopup={closePopup}
        popupRef={popupRef}
      />
    </div>
  )
}

export default TrainingPlanTable
