import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import classes from './BreakTimerPopUp.module.css'

const BreakTimerPopup = ({ initialTime, onClose }) => {
  const [timeLeft, setTimeLeft] = useState(initialTime * 60) // initialTime in minutes
  const [isPaused, setIsPaused] = useState(false)
  const timerRef = useRef(null)
  const { t } = useTranslation()

  useEffect(() => {
    if (!isPaused) {
      timerRef.current = setInterval(() => {
        setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0))
      }, 1000)
    }

    return () => clearInterval(timerRef.current)
  }, [isPaused])

  const handlePause = () => {
    setIsPaused(!isPaused)
  }

  const handleAddTime = () => {
    setTimeLeft((prevTime) => prevTime + 10)
  }

  const handleSubtractTime = () => {
    setTimeLeft((prevTime) => (prevTime > 10 ? prevTime - 10 : 0))
  }

  useEffect(() => {
    if (timeLeft === 0) {
      onClose()
    }
  }, [timeLeft, onClose])

  const progressBarWidth = (timeLeft / (initialTime * 60)) * 100

  return (
    <div className={classes.PopupContainer}>
      <div className={classes.PopupContent}>
        <div className={classes.Timer}>
          {Math.floor(timeLeft / 60)
            .toString()
            .padStart(2, '0')}
          :
          {Math.floor(timeLeft % 60)
            .toString()
            .padStart(2, '0')}
        </div>
        <div className={classes.ProgressBar} style={{ width: `${progressBarWidth}%` }}></div>
        <div className={classes.Buttons}>
          <button onClick={handlePause}>{isPaused ? t('common.resume') : t('common.pause')}</button>
          <button onClick={onClose}>{t('common.close')}</button>
          <button onClick={handleAddTime}>+10s</button>
          <button onClick={handleSubtractTime}>-10s</button>
        </div>
      </div>
    </div>
  )
}

export default BreakTimerPopup
