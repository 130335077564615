import Slider from '@mui/material/Slider'
import { styled } from '@mui/material/styles'
import React from 'react'

const StyledSlider = styled(Slider)({
  color: 'var(--blue1)',
  height: 8,
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: 'var(--blue1)',
    border: '2px solid var(--blue1)',
    '&:focus, &:hover, &.Mui-active': {
      boxShadow: 'inherit'
    }
  },
  '& .MuiSlider-track': {
    height: 8,
    borderRadius: 4
  },
  '& .MuiSlider-rail': {
    height: 8,
    borderRadius: 4
  },
  '& .MuiSlider-valueLabel': {
    backgroundColor: 'var(--blue1)',
    color: 'black'
  }
})

const CustomSlider = (props) => {
  return <StyledSlider {...props} />
}

export default CustomSlider
