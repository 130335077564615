import Cookies from 'js-cookie'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import classes from './CookiePopUp.module.css'

const CookiePopUp = ({ onClose }) => {
  const { t } = useTranslation()

  const acceptCookies = () => {
    Cookies.set('acceptedCookies', 'true', { expires: 365, sameSite: 'Strict', secure: true })
    Cookies.set('gaEnabled', 'true', { expires: 365, sameSite: 'Strict', secure: true })

    const script = document.createElement('script')
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-1SY3CDDG3R'
    script.async = true
    document.head.appendChild(script)

    script.onload = () => {
      window.dataLayer = window.dataLayer || []
      function gtag() {
        window.dataLayer.push(arguments)
      }
      window.gtag = gtag
      gtag('js', new Date())
      gtag('config', 'G-1SY3CDDG3R')
    }

    onClose()
  }

  return (
    <>
      <div className={classes.CookieOverlay}></div>
      <div className={classes.CookiePopup}>
        <div className={classes.PopupContent}>
          <div className={classes.CookieMessage}>
            <p>{t('cookiePopUp.message')}</p>
            <p>{t('cookiePopUp.essentialCookiesRequired')}</p>
            <Link to='/privacy-policy' target='_blank' rel='noopener noreferrer' className={classes.PrivacyPolicyLink}>
              {t('cookiePopUp.privacyPolicyLink')}
            </Link>
            <br />
          </div>
          <div className={classes.ButtonContainer}>
            <button className={classes.AcceptButton} onClick={acceptCookies}>
              {t('cookiePopUp.accept')}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default CookiePopUp
