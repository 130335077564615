import {
  faAt,
  faBirthdayCake,
  faEnvelope,
  faImage,
  faImagePortrait,
  faPassport,
  faRulerVertical,
  faVenusMars,
  faWeightScale
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import imageCompression from 'browser-image-compression'
import heic2any from 'heic2any'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ClipLoader } from 'react-spinners'

import { deleteUser, getUserProfile, updateUserProfile, updateUserProfilePicture } from '../../api/user'
import Footer from '../../components/Footer/Footer'
import NavigationMobile from '../../components/Navigation/BottomNavigation/BottomNavigation'
import Navigation from '../../components/Navigation/TopNavigation/Navigation'
import Notification from '../../components/Notifications/NotificationPopUp'
import PasswordChangePopup from '../../components/PopUp/PasswordChangePopUp/PasswordChangePopUp'
import classes from './Profile.module.css'

export const Profile = () => {
  const [userProfile, setUserProfile] = useState({
    profile_picture: '',
    username: '',
    firstname: '',
    lastname: '',
    email: '',
    weight: '',
    height: '',
    birthdate: '',
    gender: ''
  })
  const [originalProfile, setOriginalProfile] = useState({})
  const [editMode, setEditMode] = useState(false)
  const [activeView, setActiveView] = useState('profile')
  const [notification, setNotification] = useState(null)
  const [showPasswordChange, setShowPasswordChange] = useState(false)
  const [loading, setLoading] = useState(true)
  const [profilePictureFile, setProfilePictureFile] = useState(null)
  const { t } = useTranslation()

  useEffect(() => {
    fetchUserProfile()
  }, [])

  const fetchUserProfile = async () => {
    try {
      const response = await getUserProfile()
      const cleanedResponse = Object.keys(response).reduce((acc, key) => {
        acc[key] = response[key] || ''
        return acc
      }, {})

      if (cleanedResponse.profile_picture) {
        cleanedResponse.profile_picture = `data:image/webp;base64,${cleanedResponse.profile_picture}`
      }
      setUserProfile(cleanedResponse)
      setOriginalProfile(cleanedResponse)
      setLoading(false)
    } catch (error) {
      console.error('Error fetching user profile:', error)
      setLoading(false)
    }
  }

  const handleEditClick = () => {
    setEditMode(!editMode)
  }

  const handleProfilePictureChange = async (event) => {
    const file = event.target.files[0]
    if (file) {
      try {
        let convertedFile = file

        if (file.type === 'image/heic' || file.type === 'image/heif') {
          convertedFile = await heic2any({ blob: file, toType: 'image/jpeg' })
        }

        const options = {
          maxSizeMB: 0.2,
          maxWidthOrHeight: 500,
          useWebWorker: true,
          fileType: 'image/webp'
        }

        const compressedFile = await imageCompression(convertedFile, options)

        if (compressedFile.size > 200 * 1024) {
          setNotification({
            type: 'error',
            text: t('profile.pictureTooLarge'),
            buttons: [{ label: t('common.ok'), action: 'close' }]
          })
          return
        }

        const webpBlob = new Blob([compressedFile], { type: 'image/webp' })

        setProfilePictureFile(webpBlob)

        setUserProfile({
          ...userProfile,
          profile_picture: URL.createObjectURL(webpBlob)
        })
      } catch (error) {
        console.error('Error processing profile picture:', error)
        setNotification({
          type: 'error',
          text: t('profile.pictureUploadError'),
          buttons: [{ label: t('common.ok'), action: 'close' }]
        })
      }
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    try {
      const formattedDateOfBirth = userProfile.birthdate.split('.').reverse().join('-')
      const updatedUserProfile = { ...userProfile, birthdate: formattedDateOfBirth }

      const changes = Object.keys(updatedUserProfile).reduce((acc, key) => {
        if (updatedUserProfile[key] !== originalProfile[key]) {
          acc[key] = updatedUserProfile[key]
        }
        return acc
      }, {})

      if (Object.keys(changes).length > 0) {
        await updateUserProfile(changes)
        setOriginalProfile(updatedUserProfile)
      }

      if (profilePictureFile) {
        const formData = new FormData()
        formData.append('picture', profilePictureFile, 'profile_picture.webp')

        await updateUserProfilePicture(formData)
        setProfilePictureFile(null)
      }

      setNotification({
        type: 'success',
        text: t('profile.profileUpdated'),
        buttons: [{ label: t('common.ok'), action: 'close' }]
      })

      setEditMode(false)
    } catch (error) {
      console.error('Error updating user profile:', error)
      setNotification({
        type: 'error',
        text: t('profile.updateFailed'),
        buttons: [{ label: t('common.ok'), action: 'close' }]
      })
    }
  }

  const formatDate = (dateString) => {
    if (!dateString) return ''
    const [day, month, year] = dateString.split('-').reverse()
    return `${day}.${month}.${year}`
  }

  const handleViewChange = (view) => {
    setActiveView(view)
  }

  const handleDeleteUser = async () => {
    setNotification({
      type: 'confirmation',
      text: t('profile.deleteConfirmation'),
      buttons: [
        { label: t('common.delete'), action: 'delete' },
        { label: t('common.cancel'), action: 'cancel' }
      ]
    })
  }

  const handlePasswordChangeClick = () => {
    setShowPasswordChange(true)
  }

  const handleNotificationClick = async (action) => {
    if (action === 'delete') {
      try {
        await deleteUser()
        setNotification({
          type: 'alert',
          text: t('profile.deleteSuccess'),
          buttons: []
        })
        window.location.href = '/'
      } catch (error) {
        setNotification({
          type: 'error',
          text: t('profile.deleteFail'),
          buttons: [{ label: t('common.close'), action: 'close' }]
        })
        window.location.href = '/'
      }
    } else {
      setNotification(null)
    }
  }

  return (
    <>
      <div className={classes.Profile}>
        <Navigation />
        <div className={classes.ProfileContent}>
          {loading ? (
            <div className={classes.LoadingSpinner}>
              <ClipLoader size={50} color='' loading={loading} />
            </div>
          ) : (
            <>
              <div className={classes.ProfileHeader}>
                <div className={classes.twoItems}>
                  <div className={classes.ProfilePicture}>
                    <img src={userProfile.profile_picture} alt={t('profile.profilePictureAlt')} />
                  </div>
                  <p>
                    <FontAwesomeIcon icon={faAt} /> {t('profile.username')}: {userProfile.username}
                  </p>
                </div>
                <div className={classes.LinkContainer}>
                  <h4
                    onClick={!editMode ? () => handleViewChange('profile') : null}
                    className={activeView === 'profile' ? classes.active : ''}
                  >
                    {t('profile.headline')}
                  </h4>
                  <h4
                    onClick={!editMode ? () => handleViewChange('achievements') : null}
                    className={activeView === 'achievements' ? classes.active : ''}
                  >
                    {t('profile.achievements')}
                  </h4>
                  <h4
                    onClick={!editMode ? () => handleViewChange('activity') : null}
                    className={activeView === 'activity' ? classes.active : ''}
                  >
                    {t('profile.activity')}
                  </h4>
                </div>
                <hr />
              </div>
              {!editMode && (
                <div>
                  {activeView === 'profile' && (
                    <div>
                      <p>
                        <FontAwesomeIcon icon={faPassport} /> {t('profile.name')}: {userProfile.firstname} {userProfile.lastname}
                      </p>
                      <p>
                        <FontAwesomeIcon icon={faEnvelope} /> {t('profile.email')}: {userProfile.email}
                      </p>
                      <p>
                        <FontAwesomeIcon icon={faWeightScale} /> {t('profile.weight')}: {userProfile.weight} kg
                      </p>
                      <p>
                        <FontAwesomeIcon icon={faRulerVertical} /> {t('profile.height')}: {userProfile.height} cm
                      </p>
                      <p>
                        <FontAwesomeIcon icon={faBirthdayCake} /> {t('profile.birthdate')}: {formatDate(userProfile.birthdate)}
                      </p>
                      <p>
                        <FontAwesomeIcon icon={faVenusMars} /> {t('profile.gender')}: {t(`profile.genderType.${userProfile.gender}`)}
                      </p>
                      <div className={classes.ButtonWrapper}>
                        <button onClick={handleEditClick}>{t('common.edit')}</button>
                        <hr />
                        <button className={classes.SecondToLastButton} onClick={handlePasswordChangeClick}>
                          {t('profile.changePassword')}
                        </button>
                        <button onClick={handleDeleteUser}>{t('profile.deleteAccount')}</button>
                      </div>
                    </div>
                  )}
                  {activeView === 'achievements' && <div>{/* Render achievements here */}</div>}
                  {activeView === 'activity' && <div>{/* Render activity here */}</div>}
                </div>
              )}
              {editMode && (
                <form onSubmit={handleSubmit}>
                  <div className={classes.inputContainer}>
                    <FontAwesomeIcon icon={faImage} />
                    <input type='file' accept='image/*' onChange={handleProfilePictureChange} />
                  </div>
                  <div className={classes.inputContainer}>
                    <FontAwesomeIcon icon={faAt} />
                    <input
                      type='text'
                      value={userProfile.username}
                      onChange={(e) => setUserProfile({ ...userProfile, username: e.target.value })}
                      placeholder={t('profile.usernamePlaceholder')}
                      readOnly
                    />
                  </div>
                  <div className={classes.twoInputs}>
                    <div className={classes.inputContainer}>
                      <FontAwesomeIcon icon={faImagePortrait} />
                      <input
                        type='text'
                        value={userProfile.firstname}
                        onChange={(e) => setUserProfile({ ...userProfile, firstname: e.target.value })}
                        placeholder={t('profile.firstNamePlaceholder')}
                      />
                    </div>
                    <div className={classes.inputContainer}>
                      <FontAwesomeIcon icon={faImagePortrait} />
                      <input
                        type='text'
                        value={userProfile.lastname}
                        onChange={(e) => setUserProfile({ ...userProfile, lastname: e.target.value })}
                        placeholder={t('profile.lastNamePlaceholder')}
                      />
                    </div>
                  </div>
                  <div className={classes.inputContainer}>
                    <FontAwesomeIcon icon={faEnvelope} />
                    <input
                      type='email'
                      value={userProfile.email}
                      onChange={(e) => setUserProfile({ ...userProfile, email: e.target.value })}
                      placeholder={t('profile.emailPlaceholder')}
                    />
                  </div>
                  <div className={classes.inputContainer}>
                    <FontAwesomeIcon icon={faWeightScale} />
                    <input
                      type='number'
                      value={userProfile.weight}
                      onChange={(e) => setUserProfile({ ...userProfile, weight: e.target.value })}
                      placeholder={t('profile.weightPlaceholder')}
                    />
                  </div>
                  <div className={classes.inputContainer}>
                    <FontAwesomeIcon icon={faRulerVertical} />
                    <input
                      type='number'
                      value={userProfile.height}
                      onChange={(e) => setUserProfile({ ...userProfile, height: e.target.value })}
                      placeholder={t('profile.heightPlaceholder')}
                    />
                  </div>
                  <div className={classes.inputContainer}>
                    <FontAwesomeIcon icon={faBirthdayCake} />
                    <input
                      className={classes.DateInput}
                      type='date'
                      value={userProfile.birthdate}
                      onChange={(e) => setUserProfile({ ...userProfile, birthdate: e.target.value })}
                      placeholder={t('profile.birthdatePlaceholder')}
                    />
                  </div>
                  <div className={classes.inputContainer}>
                    <FontAwesomeIcon icon={faVenusMars} />
                    <select value={userProfile.gender} onChange={(e) => setUserProfile({ ...userProfile, gender: e.target.value })}>
                      <option value=''>{t('profile.selectGender')}</option>
                      <option value='male'>{t('profile.genderType.male')}</option>
                      <option value='female'>{t('profile.genderType.female')}</option>
                      <option value='other'>{t('profile.genderType.other')}</option>
                    </select>
                  </div>
                  <div className={classes.ButtonWrapper}>
                    <button type='submit'>{t('common.save')}</button>
                    <button type='button' onClick={handleEditClick}>
                      {t('common.cancel')}
                    </button>
                  </div>
                </form>
              )}
            </>
          )}
        </div>
        <Footer />
      </div>
      <NavigationMobile />
      {notification && <Notification {...notification} onButtonClick={handleNotificationClick} />}
      {showPasswordChange && <PasswordChangePopup onClose={() => setShowPasswordChange(false)} />}
    </>
  )
}

export default Profile
