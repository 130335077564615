import { faAt, faEnvelope, faEye, faEyeSlash, faLock, faPassport, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Cookies from 'js-cookie'
import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { createUser, loginUser } from '../../api/auth'
import Notification from '../Notifications/NotificationPopUp'
import classes from './LoginPopup.module.css'

const LoginPopup = ({ onClose }) => {
  const { t } = useTranslation()

  const [isLoginView, setLoginView] = useState(true)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [firstname, setFirstName] = useState('')
  const [lastname, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [agreeChecked, setAgreeChecked] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [rememberMe, setRememberMe] = useState(false)
  const [notification, setNotification] = useState(null)
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [showLoginPassword, setShowLoginPassword] = useState('')
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [cookiesAccepted, setCookiesAccepted] = useState(false)

  useEffect(() => {
    const acceptedCookies = Cookies.get('acceptedCookies') === 'true'
    setCookiesAccepted(acceptedCookies)
  }, [])

  useEffect(() => {
    if (isLoggedIn) {
      window.location.href = '/dashboard'
    }
  }, [isLoggedIn])

  const switchView = () => {
    setLoginView(!isLoginView)
  }

  const handleAgreeChange = () => {
    setAgreeChecked(!agreeChecked)
  }

  const handleRememberMeChange = () => {
    setRememberMe(!rememberMe)
  }

  const loginUserHandler = async (event) => {
    event.preventDefault()

    // if (!cookiesAccepted) {
    //   setNotification({ text: t('login.cookiesRequired'), buttons: [{ label: 'OK', action: 'ok' }], type: 'alert' })
    //   return
    // }

    if (!username || !password) {
      setNotification({ text: t('login.missingCredentials'), buttons: [{ label: 'OK', action: 'ok' }], type: 'alert' })
      return
    }

    try {
      const data = await loginUser(username, password)
      localStorage.setItem('access_token', data.access_token)

      if (rememberMe) {
        localStorage.setItem('remember_me', 'true')
      } else {
        localStorage.removeItem('remember_me')
      }

      setIsLoggedIn(true)
    } catch (error) {
      console.error('Login error:', error)
      setNotification({ text: t('login.loginFailed'), buttons: [{ label: 'OK', action: 'ok' }], type: 'error' })
    }
  }

  const createUserHandler = async (event) => {
    event.preventDefault()

    if (!cookiesAccepted) {
      setNotification({ text: t('login.cookiesRequired'), buttons: [{ label: 'OK', action: 'ok' }], type: 'alert' })
      return
    }

    if (!agreeChecked) {
      setNotification({ text: t('login.agreeToTerms'), buttons: [{ label: 'OK', action: 'ok' }], type: 'alert' })
      return
    }
    if (newPassword !== confirmPassword) {
      setNotification({ text: t('login.passwordsDoNotMatch'), buttons: [{ label: 'OK', action: 'ok' }], type: 'alert' })
      return
    }

    const userData = {
      username,
      password,
      firstname,
      lastname,
      email
    }

    try {
      const registeredUser = await createUser(userData)
      console.log('Registration successful:', registeredUser)
      setNotification({ text: t('login.registrationSuccess'), buttons: [{ label: 'OK', action: 'ok' }], type: 'alert' })
    } catch (error) {
      console.error('Registration error:', error)
      setNotification({ text: t('login.registrationFailed'), buttons: [{ label: 'OK', action: 'ok' }], type: 'error' })
    }
  }

  const handleNotificationButtonClick = () => {
    setNotification(null)
  }

  return (
    <>
      <div className={classes.LoginOverlay} onClick={onClose}></div>
      <div className={classes.LoginPopup}>
        <div className={classes.PopupContent}>
          <button className={classes.CloseButton} onClick={onClose}>
            <FontAwesomeIcon className={classes.Svg} icon={faTimes} />
          </button>
          {isLoginView ? (
            <div className={classes.LoginView}>
              <h2>{t('login.login')}</h2>
              <hr />
              <form onSubmit={loginUserHandler}>
                <div className={classes.inputContainer}>
                  <FontAwesomeIcon className={classes.Svg} icon={faAt} />
                  <input
                    type='text'
                    id='username'
                    name='username'
                    placeholder={t('login.username')}
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
                <div className={classes.inputContainer}>
                  <FontAwesomeIcon className={classes.Svg} icon={faLock} />
                  <input
                    type={showLoginPassword ? 'text' : 'password'}
                    id='password'
                    name='password'
                    placeholder={t('login.password')}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <button className={classes.InputButton} type='button' onClick={() => setShowLoginPassword((prev) => !prev)}>
                    {showLoginPassword ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
                  </button>
                </div>
                <div className={classes.CheckboxContainer} id={classes.rememberMe}>
                  <input type='checkbox' checked={rememberMe} onChange={handleRememberMeChange} /> {t('login.rememberMe')}
                </div>
                <button className={classes.FullWidthButton} type='submit'>
                  {t('login.login')}
                </button>
              </form>
              <p>
                {/* {t('login.noAccount')} <span onClick={switchView}>{t('login.createAccount')}</span> */}
                {/* <span>{t('login.createAccount')}</span> */}
                <Trans
                  i18nKey='login.createAccount'
                  values={{ email: 'benwal09.business@gmail.com', instagram: 'flexer.fitness' }}
                  components={{ strong: <strong /> }}
                />
              </p>
            </div>
          ) : (
            <div className={classes.CreateView}>
              <h2>{t('login.createAccount')}</h2>
              <hr />
              <form onSubmit={createUserHandler}>
                <div className={classes.inputContainer}>
                  <FontAwesomeIcon className={classes.Svg} icon={faAt} />
                  <input
                    type='text'
                    id='username'
                    name='username'
                    placeholder={t('login.username')}
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                  />
                </div>
                <div className={classes.twoInputs}>
                  <div className={classes.inputContainer}>
                    <FontAwesomeIcon className={classes.Svg} icon={faPassport} />
                    <input
                      type='text'
                      id='firstname'
                      name='firstname'
                      placeholder={t('login.firstName')}
                      value={firstname}
                      onChange={(e) => setFirstName(e.target.value)}
                      required
                    />
                  </div>
                  <div className={classes.inputContainer}>
                    <FontAwesomeIcon className={classes.Svg} icon={faPassport} />
                    <input
                      type='text'
                      id='lastname'
                      name='lastname'
                      placeholder={t('login.lastName')}
                      value={lastname}
                      onChange={(e) => setLastName(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className={classes.inputContainer}>
                  <FontAwesomeIcon className={classes.Svg} icon={faEnvelope} />
                  <input
                    type='email'
                    id='email'
                    name='email'
                    placeholder={t('login.email')}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className={classes.inputContainer}>
                  <FontAwesomeIcon className={classes.Svg} icon={faLock} />
                  <input
                    type={showNewPassword ? 'text' : 'password'}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    placeholder={t('profile.newPasswordPlaceholder')}
                  />
                  <button className={classes.InputButton} type='button' onClick={() => setShowNewPassword((prev) => !prev)}>
                    {showNewPassword ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
                  </button>
                </div>
                <div className={classes.inputContainer}>
                  <FontAwesomeIcon className={classes.Svg} icon={faLock} />
                  <input
                    type={showConfirmPassword ? 'text' : 'password'}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder={t('profile.confirmPasswordPlaceholder')}
                  />
                  <button className={classes.InputButton} type='button' onClick={() => setShowConfirmPassword((prev) => !prev)}>
                    {showConfirmPassword ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
                  </button>
                </div>
                <div className={classes.CheckboxContainer}>
                  <input type='checkbox' checked={agreeChecked} onChange={handleAgreeChange} /> {t('login.agreeTerms')}
                </div>
                <button type='submit' className={classes.FullWidthButton}>
                  {t('login.createAccount')}
                </button>
              </form>
              <p>
                {t('login.alreadyHaveAccount')} <span onClick={switchView}>{t('login.login')}</span>
              </p>
            </div>
          )}
        </div>
      </div>
      {notification && (
        <Notification
          text={notification.text}
          buttons={notification.buttons}
          onButtonClick={handleNotificationButtonClick}
          type={notification.type}
        />
      )}
    </>
  )
}

export default LoginPopup
