import { faInfoCircle, faMoon, faSun } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Switch from '@mui/material/Switch'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Tooltip } from 'react-tooltip'

import { getUserProfile, toggleUsernameSearchable, updateUserProfile } from '../../api/user'
import CustomSlider from '../../components/CustomSlider/CustomSlider'
import Footer from '../../components/Footer/Footer'
import NavigationMobile from '../../components/Navigation/BottomNavigation/BottomNavigation'
import Navigation from '../../components/Navigation/TopNavigation/Navigation'
import { ThemeContext } from '../../context/ThemeContext'
import classes from './Settings.module.css'

export const Settings = () => {
  const { isDarkMode, toggleTheme } = useContext(ThemeContext)
  const { t, i18n } = useTranslation()
  const [exerciseBreakTime, setExerciseBreakTime] = useState(0)
  const [isBreakTimerEnabled, setIsBreakTimerEnabled] = useState(false)
  const [weightWidgetRange, setWeightWidgetRange] = useState(0)
  const [showWeightWidget, setShowWeightWidget] = useState(false)
  const [usernameSearchable, setUsernameSearchable] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userData = await getUserProfile()
        if (userData) {
          setExerciseBreakTime(userData.exercise_breaktime || 0)
          setIsBreakTimerEnabled(userData.exercise_breaktime > 0)
          setWeightWidgetRange(userData.body_weight_widget_range || 0)
          setShowWeightWidget(userData.body_weight_widget_range > 0)
          setUsernameSearchable(userData.username_searchable)
        }
      } catch (error) {
        console.error('Error fetching user profile:', error)
      }
    }

    fetchData()
  }, [])

  const handleThemeToggle = async () => {
    try {
      toggleTheme()
      await updateUserProfile({ is_darkmode: !isDarkMode })
    } catch (error) {
      console.error('Error updating user profile:', error)
      toggleTheme()
    }
  }

  const handleLanguageChange = async (e) => {
    const languageCodeMap = {
      de: 'de',
      en: 'eng'
    }

    const newLanguage = e.target.value
    const backendLanguageCode = languageCodeMap[newLanguage] || newLanguage

    try {
      await updateUserProfile({ language_code: backendLanguageCode })
      i18n.changeLanguage(newLanguage)
    } catch (error) {
      console.error('Error updating language preference:', error)
    }
  }

  const handleBreakTimeChange = async (e, newValue) => {
    setExerciseBreakTime(newValue)

    try {
      await updateUserProfile({ exercise_breaktime: newValue })
    } catch (error) {
      console.error('Error updating exercise break time:', error)
    }
  }

  const handleBreakTimerToggle = async () => {
    const newIsBreakTimerEnabled = !isBreakTimerEnabled
    setIsBreakTimerEnabled(newIsBreakTimerEnabled)
    const newBreakTime = newIsBreakTimerEnabled ? exerciseBreakTime : 0

    try {
      await updateUserProfile({ exercise_breaktime: newBreakTime })
    } catch (error) {
      console.error('Error updating break timer setting:', error)
    }
  }

  const handleWeightWidgetToggle = async () => {
    const newShowWeightWidget = !showWeightWidget
    setShowWeightWidget(newShowWeightWidget)
    const newWeightWidgetRange = newShowWeightWidget ? weightWidgetRange : 0

    try {
      await updateUserProfile({ body_weight_widget_range: newWeightWidgetRange })
    } catch (error) {
      console.error('Error updating weight widget setting:', error)
    }
  }

  const handleWeightWidgetRangeChange = async (e) => {
    const newValue = parseInt(e.target.value, 10)
    setWeightWidgetRange(newValue)

    if (showWeightWidget) {
      try {
        await updateUserProfile({ body_weight_widget_range: newValue })
      } catch (error) {
        console.error('Error updating weight widget range:', error)
      }
    }
  }

  const handleUsernameSearchableToggle = async () => {
    try {
      const updatedProfile = await toggleUsernameSearchable()
      setUsernameSearchable(updatedProfile.username_searchable)
    } catch (error) {
      console.error('Error toggling username searchable:', error)
    }
  }

  return (
    <>
      <div className={classes.Settings}>
        <Navigation />
        <div className={classes.SettingsHeader}>
          <div className={classes.HeadlineWrapper}>
            <h2>{t('settings.headline')}</h2>
            <FontAwesomeIcon icon={faInfoCircle} className={classes.TooltipIcon} data-tooltip-id='settingsTooltip' />
            <Tooltip
              id='settingsTooltip'
              className={classes.TooltipContent}
              effect='solid'
              place='bottom'
              multiline={true}
              content={
                <ul>
                  <li>{t('settings.tooltip.appearance')}</li>
                  <li>{t('settings.tooltip.language')}</li>
                  <li>{t('settings.tooltip.breakTime')}</li>
                  <li>{t('settings.tooltip.weightWidget')}</li>
                  <li>{t('settings.tooltip.usernameSearchable')}</li>
                </ul>
              }
            />
          </div>
          <hr />
        </div>
        <div className={classes.SettingsContent}>
          <div className={classes.ThemeSettingsContainer}>
            <h4>
              {t('settings.appearance')}: {isDarkMode ? t('settings.dark') : t('settings.light')}
            </h4>
            <div className={classes.SwitchContainer}>
              <FontAwesomeIcon icon={faSun} className={classes.ThemeIcon} />
              <Switch checked={isDarkMode} onChange={handleThemeToggle} color='primary' />
              <FontAwesomeIcon icon={faMoon} className={classes.ThemeIcon} />
            </div>
          </div>
          <div className={classes.LanguageSettingsContainer}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              <label htmlFor='language-select'>
                <h4>{t('settings.language')}:</h4>
              </label>
              <span className={classes.RangeValue}>{i18n.language}</span>
            </div>
            <div className={classes.Select}>
              <select id='language-select' onChange={handleLanguageChange} value={i18n.language}>
                <option value='de'>Deutsch</option>
                <option value='eng'>English</option>
              </select>
            </div>
          </div>
          <div className={classes.BreakTimeSettingsContainer}>
            <div className={classes.RangeContainer}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                  <h4>{t('settings.breakTime')}:</h4>
                  <span className={classes.RangeValue}>{exerciseBreakTime}min</span>
                </div>
                <Switch checked={isBreakTimerEnabled} onChange={handleBreakTimerToggle} color='primary' />
              </div>
              {isBreakTimerEnabled && (
                <div style={{ padding: '0px 10px', marginTop: '-10px', marginBottom: '-10px' }}>
                  <CustomSlider
                    value={exerciseBreakTime}
                    onChange={handleBreakTimeChange}
                    aria-labelledby='input-slider'
                    min={1}
                    max={10}
                    valueLabelDisplay='auto'
                  />
                </div>
              )}
            </div>
          </div>
          <div className={classes.WeightWidgetSettingsContainer}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', padding: '-10px' }}>
              <label htmlFor='weight-widget-select'>
                <h4>{t('settings.weightWidget')}:</h4>
              </label>
              <Switch checked={showWeightWidget} onChange={handleWeightWidgetToggle} color='primary' />
            </div>
            {showWeightWidget && (
              <>
                <span className={classes.RangeValue}>
                  {t('dashboard.weightWidget.remindedIn')} {weightWidgetRange}{' '}
                  {weightWidgetRange === 1 ? t('common.unit.day') : t('common.unit.days')}.
                </span>
                <div className={classes.Select}>
                  <select id='weight-widget-select' value={weightWidgetRange} onChange={handleWeightWidgetRangeChange}>
                    <option value={0}>
                      {t('dashboard.weightWidget.remindedIn')} 0 {t('common.unit.days')}
                    </option>
                    {[...Array(365).keys()].map((day) => (
                      <option key={day + 1} value={day + 1}>
                        {day + 1} {day + 1 === 1 ? t('common.unit.day') : t('common.unit.days')}
                      </option>
                    ))}
                  </select>
                </div>
              </>
            )}
          </div>
          <div className={classes.UsernameSearchableSettingsContainer}>
            <h4>
              {t('settings.usernameSearchable')}: <br />
              {usernameSearchable ? t('settings.searchable') : t('settings.notSearchable')}
            </h4>
            <Switch checked={usernameSearchable} onChange={handleUsernameSearchableToggle} color='primary' />
          </div>
        </div>
        <Footer />
      </div>
      <NavigationMobile />
    </>
  )
}

export default Settings
