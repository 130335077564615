import { faArrowDown, faArrowUp, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getPreviewCompareToLastTraining } from '../../api/reporting'
import classes from './TrainingComparison.module.css'

const TrainingComparison = ({ onClose }) => {
  const [comparisonData, setComparisonData] = useState(null)
  const [selectedPeriod, setSelectedPeriod] = useState('compare_last')
  const { t } = useTranslation()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getPreviewCompareToLastTraining()
        setComparisonData(data)
      } catch (error) {
        console.error('Error fetching training comparison data:', error)
      }
    }
    fetchData()
  }, [])

  if (!comparisonData) {
    return <div>{t('loading')}</div>
  }

  const { calories_compare, duration_compare, max_weight_records } = comparisonData

  const handlePeriodChange = (period) => {
    setSelectedPeriod(period)
  }

  const renderChangeIcon = (value) => {
    return (
      <FontAwesomeIcon icon={value > 0 ? faArrowUp : faArrowDown} className={value > 0 ? classes.positiveChange : classes.negativeChange} />
    )
  }

  return (
    <div className={classes.overlay}>
      <div className={classes.trainingComparison}>
        <button className={classes.closeButton} onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <h2>{t('training.trainingComparison.title')}</h2>
        <div className={classes.buttons}>
          <button className={selectedPeriod === 'compare_last' ? classes.active : ''} onClick={() => handlePeriodChange('compare_last')}>
            {t('training.trainingComparison.compareLastButton')}
          </button>
          <button className={selectedPeriod === 'compare_week' ? classes.active : ''} onClick={() => handlePeriodChange('compare_week')}>
            {t('training.trainingComparison.compareWeekButton')}
          </button>
          <button className={selectedPeriod === 'compare_month' ? classes.active : ''} onClick={() => handlePeriodChange('compare_month')}>
            {t('training.trainingComparison.compareMonthButton')}
          </button>
          <button className={selectedPeriod === 'compare_year' ? classes.active : ''} onClick={() => handlePeriodChange('compare_year')}>
            {t('training.trainingComparison.compareYearButton')}
          </button>
        </div>
        <div className={classes.trainingComparisonSection}>
          <h3>{t('training.trainingComparison.caloriesBurned')}</h3>
          <p>
            {t('training.trainingComparison.todayBurned')}: {calories_compare?.today_calories ?? 0} cal
          </p>
          <p>
            {t('training.trainingComparison.percentageChange')}: {calories_compare?.[selectedPeriod] ?? 0}%
            {renderChangeIcon(calories_compare?.[selectedPeriod] ?? 0)}
          </p>
        </div>
        <div className={classes.trainingComparisonSection}>
          <h3>{t('training.trainingComparison.trainingDuration')}</h3>
          <p>
            {t('training.trainingComparison.todayDuration')}: {duration_compare?.today_duration ?? 0} min
          </p>
          <p>
            {t('training.trainingComparison.percentageChange')}: {duration_compare?.[selectedPeriod] ?? 0}%
            {renderChangeIcon(duration_compare?.[selectedPeriod] ?? 0)}
          </p>
        </div>
        {max_weight_records?.length > 0 && (
          <div className={classes.trainingComparisonSection}>
            <h3>{t('training.trainingComparison.maxWeightRecords')}</h3>
            {max_weight_records.map((record) => (
              <div key={record.exercise_id} className={classes.record}>
                <p>
                  {t('training.trainingComparison.exerciseName')}: {record.exercise_name}
                </p>
                <p>
                  {t('training.trainingComparison.newMaxWeight')}: {record.new_max_weight} kg
                </p>
                <p>
                  {t('training.trainingComparison.prevMaxWeight')}: {record.prev_max_weight} kg
                </p>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default TrainingComparison
