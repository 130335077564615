import './index.css'

import React from 'react'
import ReactDOM from 'react-dom/client'

import { App } from './App'
import { ThemeProvider } from './context/ThemeContext'
import reportWebVitals from './reportWebVitals'
import { logMessage } from './utils/logger' // Importiere die benutzerdefinierte Log-Funktion

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <App />
    </ThemeProvider>
  </React.StrictMode>
)

reportWebVitals()

// Service Worker Registrierung
if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/service-worker.js')
    .then((registration) => {
      logMessage(`Service Worker registered with scope: ${registration.scope}`)
    })
    .catch((error) => {
      logMessage(`Service Worker registration failed: ${error}`)
    })
}
