import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import Footer from '../../components/Footer/Footer'
import classes from './PrivacyPolicy.module.css'

const PrivacyPolicy = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  const handleBackClick = () => {
    if (location.key === 'default') {
      navigate('/')
    } else {
      navigate(-1)
    }
  }

  return (
    <div className={classes.PrivacyPolicy}>
      <div className={classes.PrivacyPolicyContent}>
        <button onClick={handleBackClick} className={classes.BackButton}>
          {t('privacyPolicy.backButton')}
        </button>
        <h1>{t('privacyPolicy.title')}</h1>
        <p>{t('privacyPolicy.intro')}</p>
        <section>
          <h2>{t('privacyPolicy.dataCollectionTitle')}</h2>
          <p>{t('privacyPolicy.dataCollectionContent')}</p>
          <ul>
            <li>{t('privacyPolicy.dataCollectionList.personalIdentificationData')}</li>
            <li>{t('privacyPolicy.dataCollectionList.accountInformation')}</li>
            <li>{t('privacyPolicy.dataCollectionList.preferences')}</li>
            <li>{t('privacyPolicy.dataCollectionList.healthAndFitnessData')}</li>
            <li>{t('privacyPolicy.dataCollectionList.trainingData')}</li>
          </ul>
        </section>
        <section>
          <h2>{t('privacyPolicy.dataUsageTitle')}</h2>
          <p>{t('privacyPolicy.dataUsageContent')}</p>
        </section>
        <section>
          <h2>{t('privacyPolicy.dataStorageTitle')}</h2>
          <p>{t('privacyPolicy.dataStorageContent')}</p>
        </section>
        <section>
          <h2>{t('privacyPolicy.cookiesTitle')}</h2>
          <p>{t('privacyPolicy.cookiesContent')}</p>
        </section>
        <section>
          <h2>{t('privacyPolicy.googleAnalyticsTitle')}</h2>
          <p>{t('privacyPolicy.googleAnalyticsContent')}</p>
        </section>
        <section>
          <h2>{t('privacyPolicy.userRightsTitle')}</h2>
          <p>{t('privacyPolicy.userRightsContent')}</p>
        </section>
        <section>
          <h2>{t('privacyPolicy.contactTitle')}</h2>
          <Trans
            i18nKey='privacyPolicy.contactContent'
            values={{ email: 'benwal09.business@gmail.com' }}
            components={{ strong: <strong /> }}
          />
        </section>
      </div>
      <Footer />
    </div>
  )
}

export default PrivacyPolicy
