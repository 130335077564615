import { apiClient } from './auth'

export const countTrainingDaily = async () => {
  const token = localStorage.getItem('access_token')
  const response = await apiClient.get('/reporting/count_training_daily', {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return response.data
}

export const highestWeightWeekly = async (exerciseId) => {
  const token = localStorage.getItem('access_token')
  const response = await apiClient.get(`/reporting/highest_weight_weekly/${exerciseId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return response.data
}

export const highestWeightMonthly = async (exerciseId) => {
  const token = localStorage.getItem('access_token')
  const response = await apiClient.get(`/reporting/highest_weight_monthly/${exerciseId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return response.data
}

export const maxWeightloadWeekly = async (exerciseId) => {
  const token = localStorage.getItem('access_token')
  const response = await apiClient.get(`/reporting/max_weightload_weekly/${exerciseId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return response.data
}

export const maxWeightloadMonthly = async (exerciseId) => {
  const token = localStorage.getItem('access_token')
  const response = await apiClient.get(`/reporting/max_weightload_monthly/${exerciseId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return response.data
}

export const getBodyWeightTrend = async () => {
  const token = localStorage.getItem('access_token')
  const response = await apiClient.get('/reporting/body_weight_trend', {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return response.data
}

export const getBMITrend = async () => {
  const token = localStorage.getItem('access_token')
  const response = await apiClient.get('/reporting/bmi_trend', {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return response.data
}

export const getPreviewCompareToLastTraining = async () => {
  const token = localStorage.getItem('access_token')
  const response = await apiClient.get('/reporting/preview_compare_training_statistics', {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return response.data
}
