import { apiClient } from './auth'

export const getAllFinishedTrainings = async () => {
  const token = localStorage.getItem('access_token')
  const response = await apiClient.get('/finished_training/all_finished_trainings', {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return response.data
}

export const getFinishedTrainingById = async (finishedTrainingId) => {
  const token = localStorage.getItem('access_token')
  const response = await apiClient.get(`/finished_training/finished_training_by_id/${finishedTrainingId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return response.data
}

export const postFinishedTraining = async (finishedTrainingData) => {
  const token = localStorage.getItem('access_token')
  const response = await apiClient.post('/finished_training/save_finished_training', finishedTrainingData, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })
  return response.data
}

export const deleteFinishedTraining = async (finishedTrainingId) => {
  const token = localStorage.getItem('access_token')
  const response = await apiClient.delete(`/finished_training/delete_finished_training/${finishedTrainingId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  if (response.status === 200) {
    return response.data
  } else {
    throw new Error('Failed to delete finished training.')
  }
}
