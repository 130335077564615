import { faCheck, faRemove, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import classes from './Set.module.css'

function Set({ onRemove, uniqueKey, repetition, weight, onComplete, onUpdateRepetition, onUpdateWeight }) {
  const formatWeightDisplay = (value) => {
    const strValue = String(value)
    const num = parseFloat(strValue)
    if (isNaN(num)) return ''

    const isDecimal = strValue.includes('.')
    if (!isDecimal) return strValue

    const parts = strValue.split('.')
    const decimalPart = parts[1]
    const trimmedDecimal = decimalPart.length > 3 ? decimalPart.substr(0, 3) : decimalPart
    const formattedDecimal = trimmedDecimal.replace(/0+$/, '')
    return parts[0] + (formattedDecimal ? ',' + formattedDecimal : '')
  }

  const initialWeightKg = formatWeightDisplay(weight)

  const initialCompleted = JSON.parse(localStorage.getItem(`completed_${uniqueKey}`)) || false

  const [completed, setCompleted] = useState(initialCompleted)
  const [repetitionInput, setRepetitionInput] = useState(repetition ? repetition : '-')
  const [weightInput, setWeightInput] = useState(initialWeightKg ? initialWeightKg : '-')
  const { t } = useTranslation()

  const toggleComplete = useCallback(() => {
    const newCompleted = !completed
    setCompleted(newCompleted)
    localStorage.setItem(`completed_${uniqueKey}`, JSON.stringify(newCompleted)) // Persist the 'completed' state
    if (onComplete) {
      onComplete(uniqueKey, newCompleted)
    }
  }, [completed, onComplete, uniqueKey])

  const removeSet = useCallback(() => {
    localStorage.removeItem(`completed_${uniqueKey}`) // Clean up when set is removed
    if (onRemove) {
      onRemove(uniqueKey)
    }
  }, [onRemove, uniqueKey])

  const handleRepetitionChange = ({ target: { value } }) => {
    if (/^\d{0,3}$/.test(value)) {
      setRepetitionInput(value)
    }
  }

  const handleRepetitionFocus = () => {
    if (repetitionInput === '-') {
      setRepetitionInput('')
    }
  }

  const handleWeightChange = ({ target: { value } }) => {
    const formattedValue = value.replace(',', '.')
    if (/^\d{0,3}(\.\d{0,3})?$/.test(formattedValue)) {
      setWeightInput(formattedValue)
    }
  }

  const handleWeightFocus = () => {
    if (weightInput === '-') {
      setWeightInput('')
    }
  }

  const handleRepetitionBlur = () => {
    if (repetitionInput === '' || repetitionInput === '-') {
      setRepetitionInput('-')
      onUpdateRepetition(uniqueKey, 0)
    } else {
      const intValue = parseInt(repetitionInput, 10)
      onUpdateRepetition(uniqueKey, isNaN(intValue) ? 0 : intValue)
    }
  }

  const handleWeightBlur = () => {
    if (weightInput === '' || weightInput === '-' || parseFloat(weightInput) === 0) {
      setWeightInput('-')
      onUpdateWeight(uniqueKey, 0.0)
    } else {
      const floatValue = parseFloat(weightInput)
      setWeightInput(formatWeightDisplay(weightInput))
      onUpdateWeight(uniqueKey, isNaN(floatValue) ? 0.0 : floatValue.toFixed(3))
    }
  }

  return (
    <div className={classes.SetContainer}>
      <div className={classes.SetContent}>
        <div className={classes.SetContentInput}>
          <div className={classes.InputContainer}>
            <div className={classes.Input} id='repetitionInput'>
              <input
                id='repetition'
                name='repetition'
                type='text'
                value={repetitionInput}
                onChange={handleRepetitionChange}
                onBlur={handleRepetitionBlur}
                onFocus={handleRepetitionFocus}
                inputMode='numeric'
              />
              <span className={classes.InputUnit}>{t('common.unit.times')}</span>
            </div>
            <div className={classes.Input} id='weightInput'>
              <input
                id='kilograms'
                name='kilograms'
                type='text'
                value={weightInput}
                onChange={handleWeightChange}
                onBlur={handleWeightBlur}
                onFocus={handleWeightFocus}
                inputMode='decimal'
              />
              <span className={classes.InputUnit}>{t('common.unit.kg')}</span>
            </div>
          </div>
        </div>
        <div className={classes.SetAction}>
          <button aria-label='toggle-complete' onClick={toggleComplete}>
            <FontAwesomeIcon icon={completed ? faRemove : faCheck} className={classes.RotateIcon} />
          </button>
          <button aria-label='remove-set' onClick={removeSet}>
            <FontAwesomeIcon icon={faTrashCan} />
          </button>
        </div>
      </div>
    </div>
  )
}

export default Set
