import { faBars, faBook, faCog, faCommentAlt, faSignOutAlt, faTimes, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import { logoutUser } from '../../../api/auth'
import logo from '../../../assets/images/logo.png'
import Notification from '../../Notifications/NotificationPopUp'
import TrainingPlanTable from '../../TrainingPlanTable/TrainingPlanTable'
import classes from './Navigation.module.css'

function Navigation() {
  const { t } = useTranslation()

  const [isSettingsVisible, setSettingsVisible] = useState(false)
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false)
  const [showTrainingPlanTable, setShowTrainingPlanTable] = useState(false)

  const handleLogOut = () => {
    setShowLogoutConfirmation(true)
  }

  const confirmLogout = async (confirmed) => {
    if (confirmed) {
      try {
        await logoutUser()
        window.location.reload()
      } catch (error) {
        console.error('Error logging out:', error)
      }
    } else {
      setShowLogoutConfirmation(false)
    }
  }

  return (
    <>
      {isSettingsVisible && <div className={classes.Overlay} onClick={() => setSettingsVisible(false)} />}
      <div className={classes.NavigationContainer_mobile}>
        <div className={classes.NavigationContainer_Mobile_Wrapper}>
          <div className={classes.Logo}>
            <NavLink to='/dashboard'>
              <img src={logo} alt='Logo' />
            </NavLink>
          </div>
          <div
            className={`${classes.Toggle} ${isSettingsVisible ? classes.RotateIn : classes.RotateOut}`}
            onClick={() => setSettingsVisible(!isSettingsVisible)}
          >
            <FontAwesomeIcon icon={isSettingsVisible ? faTimes : faBars} className={classes.RotateIcon} />
          </div>
        </div>

        {isSettingsVisible && (
          <div className={classes.SubNavigation}>
            <div className={classes.ItemsSubNavigation}>
              <NavLink to='/profile' className={({ isActive }) => (isActive ? classes.ActiveMobileSub : '')}>
                <FontAwesomeIcon icon={faUser} className={classes.User} />
                <span>{t('profile.headline')}</span>
              </NavLink>
            </div>
            <div className={classes.ItemsSubNavigation}>
              <NavLink to='/settings' className={({ isActive }) => (isActive ? classes.ActiveMobileSub : '')}>
                <FontAwesomeIcon icon={faCog} className={classes.Setting} />
                <span>{t('settings.headline')}</span>
              </NavLink>
            </div>
            <div className={classes.ItemsSubNavigation} id={classes.feedback}>
              <NavLink to='/feedback' className={({ isActive }) => (isActive ? classes.ActiveMobileSub : '')}>
                <FontAwesomeIcon icon={faCommentAlt} className={classes.Feedback} />
                <span>{t('feedback.headline')}</span>
              </NavLink>
            </div>
            <div className={classes.ItemsSubNavigation}>
              <NavLink to='/release-notes' className={({ isActive }) => (isActive ? classes.ActiveMobileSub : '')}>
                <FontAwesomeIcon icon={faBook} className={classes.Feedback} />
                <span>{t('releaseNotes.title')}</span>
              </NavLink>
            </div>
            <div className={classes.ItemsSubNavigation} onClick={handleLogOut}>
              <FontAwesomeIcon icon={faSignOutAlt} className={classes.Setting} />
              <span>{t('common.logout')}</span>
            </div>
          </div>
        )}
      </div>
      {showTrainingPlanTable && (
        <div className={classes.TrainingPlanTableContainer}>
          <TrainingPlanTable onClose={() => setShowTrainingPlanTable(false)} />
        </div>
      )}
      {showLogoutConfirmation && (
        <Notification
          text={t('navigation.confirmLogout')}
          buttons={[
            { label: t('common.yes'), action: true },
            { label: t('common.no'), action: false }
          ]}
          onButtonClick={confirmLogout}
          type='confirmation'
        />
      )}
    </>
  )
}

export default Navigation
