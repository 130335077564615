import { faAngleDown, faGripLines, faInfo, faPause, faPlay, faRotate, faStop, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'

import { getAllExercisesWithPrevSets } from '../../api/exercise'
import { getUserProfile } from '../../api/user'
import Notification from '../Notifications/NotificationPopUp'
import BreakTimerPopup from '../PopUp/ExercisePopUps/BreakTimerPopUp/BreakTimerPopUp'
import ChangeExercisePopUp from '../PopUp/ExercisePopUps/ExercisePopUps/ChangeExercisePopUp'
import InfoExercisePopUp from '../PopUp/ExercisePopUps/ExercisePopUps/InfoExercisePopUp'
import SelectExercise from '../Select/SelectExercise'
import Set from '../Set/Set'
import classes from './Exercise.module.css'

const Exercise = ({ exercise, exercises, onRemove, onUpdateExercise, index, moveExercise }) => {
  const [expanded, setExpanded] = useState(false)
  const [showChangeExercisePopUp, setShowChangeExercisePopup] = useState(false)
  const [showInfoExercisePopUp, setShowInfoExercisePopup] = useState(false)
  const [allExercises, setAllExercises] = useState([])
  const [selectedExercise, setSelectedExercise] = useState({})
  // eslint-disable-next-line
  const [isExerciseSelected, setIsExerciseSelected] = useState(false)
  const [sets, setSets] = useState([])
  const totalSets = sets.length
  const [exerciseTimer, setExerciseTimer] = useState(parseInt(localStorage.getItem(`exerciseTimer_${exercise.key}`)) || 0)
  const [exerciseIsActive, setExerciseIsActive] = useState(localStorage.getItem(`exerciseIsActive_${exercise.key}`) === 'true' || false)
  const [exerciseIsPaused, setExerciseIsPaused] = useState(localStorage.getItem(`exerciseIsPaused_${exercise.key}`) === 'true' || false)
  const [exerciseStartTime, setExerciseStartTime] = useState(
    localStorage.getItem(`exerciseStartTime_${exercise.key}`) || exercise.training_start || null
  )
  const [pauseDuration, setPauseDuration] = useState(parseInt(localStorage.getItem(`pauseDuration_${exercise.key}`)) || 0)
  const [lastPauseTime, setLastPauseTime] = useState(localStorage.getItem(`lastPauseTime_${exercise.key}`) || null)
  const [exerciseEndTime, setExerciseEndTime] = useState(null)
  const [activeButton, setActiveButton] = useState(localStorage.getItem(`activeButton_${exercise.key}`) || null)
  const [showTimerRunningNotification, setShowTimerRunningNotification] = useState(false)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [showBreakPopup, setShowBreakPopup] = useState(false)
  const [breakTime, setBreakTime] = useState(0) // Set initial breakTime to 0
  const [isBreakTimerEnabled, setIsBreakTimerEnabled] = useState(false) // New state for break timer switch
  const [scrollPosition, setScrollPosition] = useState(0)

  const contentRef = useRef(null)
  const exerciseRef = useRef(null)
  const setsRef = useRef(sets)
  const { t } = useTranslation()

  const [{ isDragging }, drag, dragPreview] = useDrag({
    type: 'EXERCISE',
    item: { type: 'EXERCISE', index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  })

  const [, drop] = useDrop({
    accept: 'EXERCISE',
    hover(item, monitor) {
      if (!exerciseRef.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) {
        return
      }

      const hoverBoundingRect = exerciseRef.current.getBoundingClientRect()
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      moveExercise(dragIndex, hoverIndex)
      item.index = hoverIndex
    }
  })

  useEffect(() => {
    const fetchExercises = async () => {
      try {
        const allExercises = await getAllExercisesWithPrevSets()
        const matchingExercise = allExercises.find((ex) => ex.exercise_id === exercise.exercise_id)
        setAllExercises(allExercises)
        if (matchingExercise) {
          setSelectedExercise(matchingExercise)
          const storedSets = JSON.parse(localStorage.getItem(`sets_${exercise.exercise_id}`)) || []
          setSets(storedSets)
          if (!storedSets.length && matchingExercise.prev_sets) {
            const initializedSets = matchingExercise.prev_sets.map((set) => ({ ...set, key: uuidv4(), completed: false }))
            setSets(initializedSets)
            localStorage.setItem(`sets_${exercise.exercise_id}`, JSON.stringify(initializedSets))
          }
        }
      } catch (error) {
        console.error(error)
      }
    }

    fetchExercises()
  }, [exercise.exercise_id])

  useEffect(() => {
    localStorage.setItem(`sets_${selectedExercise.exercise_id}`, JSON.stringify(sets))
  }, [sets, selectedExercise.exercise_id])

  useEffect(() => {
    setIsExerciseSelected(!!selectedExercise.exercise_id)
  }, [selectedExercise])

  useEffect(() => {
    const fetchProfile = async () => {
      const profile = await getUserProfile()
      setBreakTime(profile.exercise_breaktime || 0)
      setIsBreakTimerEnabled(profile.exercise_breaktime > 0)
    }
    fetchProfile()
  }, [])

  const toggleExpand = () => {
    setExpanded(!expanded)
  }

  const removeExercise = () => {
    setShowDeleteConfirmation(true) // Show confirmation dialog
  }

  const handleDeleteExercise = (confirm) => {
    if (confirm) {
      if (onRemove) {
        onRemove(selectedExercise)
      }
    }
    setShowDeleteConfirmation(false)
  }

  const handleSelectedExercise = useCallback(
    (value) => {
      const exerciseData = {
        key: exercise.key,
        exerciseData: {
          exercise_id: parseInt(value),
          exercise_duration: exerciseTimer || null,
          exercise_start: exerciseStartTime || null,
          exercise_end: exerciseEndTime || null,
          finished_sets: sets.length > 0 ? sets : null
        }
      }
      onUpdateExercise(exerciseData)
      setIsExerciseSelected(true)
      setShowChangeExercisePopup(false)
    },
    [onUpdateExercise, exercise.key, sets, exerciseTimer, exerciseStartTime, exerciseEndTime]
  )

  const handleSetsUpdate = useCallback(() => {
    handleSelectedExercise(selectedExercise.exercise_id)
  }, [selectedExercise, handleSelectedExercise])

  useEffect(() => {
    const prevSets = setsRef.current
    if (prevSets !== sets) {
      handleSetsUpdate()
    }
    setsRef.current = sets
  }, [handleSetsUpdate, sets])

  const addSet = () => {
    const setKey = uuidv4()
    const newSet = { key: setKey, repetition: 0, weight: 0, completed: false }
    setSets((prevSets) => {
      const updatedSets = [...prevSets, newSet]
      localStorage.setItem(`sets_${selectedExercise.exercise_id}`, JSON.stringify(updatedSets))
      return updatedSets
    })
  }

  const removeSet = (setKey) => {
    setSets((prevSets) => {
      const updatedSets = prevSets.filter((set) => set.key !== setKey)
      localStorage.setItem(`sets_${selectedExercise.exercise_id}`, JSON.stringify(updatedSets))
      return updatedSets
    })
  }

  const updateSet = useCallback((setKey, field, value) => {
    setSets((prevSets) => {
      const updatedSets = prevSets.map((set) => (set.key === setKey ? { ...set, [field]: value } : set))
      localStorage.setItem(`sets_${selectedExercise.exercise_id}`, JSON.stringify(updatedSets))
      return updatedSets
    })
  }, [])

  const completeSet = useCallback(
    (setKey, completed) => {
      updateSet(setKey, 'completed', completed)
      if (completed && isBreakTimerEnabled) {
        // Check if break timer is enabled
        setShowBreakPopup(true)
      }
    },
    [updateSet, isBreakTimerEnabled]
  )

  const progress = totalSets > 0 ? sets.filter((set) => set.completed).length / totalSets : 0

  useEffect(() => {
    if (progress === 1) {
      setExpanded(false)
    }
  }, [progress])

  const openChangeExercisePopUp = () => {
    setScrollPosition(window.scrollY)
    window.scrollTo({ top: 0, behavior: 'smooth' })
    setShowChangeExercisePopup(true)
  }

  const closeChangeExercisePopUp = () => {
    setShowChangeExercisePopup(false)
    window.scrollTo({ top: scrollPosition, behavior: 'smooth' })
  }

  const openInfoExercisePopUp = () => {
    setScrollPosition(window.scrollY)
    window.scrollTo({ top: 0, behavior: 'smooth' })
    setShowInfoExercisePopup(true)
  }

  const closeInfoExercisePopUp = () => {
    setShowInfoExercisePopup(false)
    window.scrollTo({ top: scrollPosition, behavior: 'smooth' })
  }

  useEffect(() => {
    const interval = setInterval(() => {
      const storedStartTime = localStorage.getItem(`exerciseStartTime_${exercise.key}`)
      const storedPauseDuration = parseInt(localStorage.getItem(`pauseDuration_${exercise.key}`)) || 0
      if (exerciseIsActive && storedStartTime) {
        const elapsedTime = Math.floor((Date.now() - new Date(storedStartTime).getTime() - storedPauseDuration) / 1000)
        setExerciseTimer(elapsedTime)
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [exerciseIsActive, exercise.key])

  useEffect(() => {
    localStorage.setItem(`exerciseTimer_${exercise.key}`, exerciseTimer)
    localStorage.setItem(`exerciseIsActive_${exercise.key}`, exerciseIsActive)
    localStorage.setItem(`exerciseIsPaused_${exercise.key}`, exerciseIsPaused)
    localStorage.setItem(`exerciseStartTime_${exercise.key}`, exerciseStartTime)
    localStorage.setItem(`pauseDuration_${exercise.key}`, pauseDuration)
    localStorage.setItem(`lastPauseTime_${exercise.key}`, lastPauseTime)
    localStorage.setItem(`activeButton_${exercise.key}`, activeButton)
  }, [exerciseTimer, exerciseIsActive, exerciseIsPaused, exerciseStartTime, pauseDuration, lastPauseTime, activeButton, exercise.key])

  const handleStart = () => {
    const currentTime = new Date().toISOString()
    let updatedStartTime = exerciseStartTime
    if (exerciseIsPaused && lastPauseTime) {
      const additionalPauseDuration = Date.now() - new Date(lastPauseTime).getTime()
      setPauseDuration(pauseDuration + additionalPauseDuration)
      localStorage.setItem(`pauseDuration_${exercise.key}`, pauseDuration + additionalPauseDuration)
    } else {
      updatedStartTime = currentTime
    }

    setExerciseIsActive(true)
    setExerciseIsPaused(false)
    setActiveButton('start')
    setExerciseStartTime(updatedStartTime)
    setLastPauseTime(null)
    localStorage.setItem(`exerciseStartTime_${exercise.key}`, updatedStartTime)
    localStorage.setItem(`lastPauseTime_${exercise.key}`, null)

    const exerciseData = {
      key: exercise.key,
      exerciseData: {
        exercise_id: selectedExercise.exercise_id,
        exercise_duration: exerciseTimer || null,
        exercise_start: updatedStartTime || null,
        exercise_end: exerciseEndTime || null,
        finished_sets: sets.length > 0 ? sets : null
      }
    }
    onUpdateExercise(exerciseData)
  }

  const handleStop = () => {
    setExerciseIsActive(false)
    setExerciseIsPaused(false)
    setActiveButton('stop')
    localStorage.setItem(`activeButton_${exercise.key}`, 'stop')
    const endTime = new Date().toISOString()
    setExerciseEndTime(endTime)

    const exerciseData = {
      key: exercise.key,
      exerciseData: {
        exercise_id: selectedExercise.exercise_id,
        exercise_duration: exerciseTimer || null,
        exercise_start: exerciseStartTime || null,
        exercise_end: endTime || null,
        finished_sets: sets.length > 0 ? sets : null
      }
    }
    onUpdateExercise(exerciseData)
  }

  const handlePause = () => {
    setExerciseIsActive(false)
    setExerciseIsPaused(true)
    setActiveButton('pause')
    setLastPauseTime(new Date().toISOString())
    localStorage.setItem(`activeButton_${exercise.key}`, 'pause')
    localStorage.setItem(`lastPauseTime_${exercise.key}`, new Date().toISOString())
  }

  const getTimerDisplay = () => {
    const minutes = Math.floor(exerciseTimer / 60)
    const seconds = exerciseTimer % 60
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
  }

  const existingExerciseIds = exercises.map((exercise) => exercise.exercise_id)

  const exerciseOptions = allExercises
    .filter((exercise) => !existingExerciseIds.includes(exercise.exercise_id))
    .map((exercise) => ({
      value: exercise.exercise_id,
      label: exercise.exercise_name
    }))

  const selectedOption = selectedExercise ? { value: selectedExercise.exercise_id, label: selectedExercise.exercise_name } : null

  useEffect(() => {
    if (progress === 1 && exerciseIsActive && !exerciseIsPaused && !expanded) {
      setShowTimerRunningNotification(true)
    }
  }, [progress, exerciseIsActive, exerciseIsPaused, expanded])

  dragPreview(drop(exerciseRef))

  return (
    <div
      ref={exerciseRef}
      className={`${classes.ExerciseContainer} ${expanded ? classes.Expanded : ''}`}
      style={{ opacity: isDragging ? 0.5 : 1 }}
    >
      <div style={{ width: '100%' }}>
        <div className={classes.ExerciseHeader}>
          <SelectExercise
            value={selectedOption}
            options={exerciseOptions}
            onChange={(option) => handleSelectedExercise(option.value)}
            placeholder={t('training.exercise.selectExercisePlaceholder')}
          />
          <div className={classes.ExerciseHeaderButton}>
            <button onClick={toggleExpand}>
              <FontAwesomeIcon icon={faAngleDown} className={`${classes.Icon} ${expanded ? classes.Rotate : ''}`} />
            </button>
          </div>
        </div>
        <div ref={contentRef} className={`${classes.ExerciseContent} ${expanded ? classes.Expanded : ''}`}>
          <div className={classes.ExerciseInfo}>
            <span className={classes.ExerciseMuscle}>
              {`${t('training.exercise.exerciseInformation.targetMuscleGroup')}: ${
                selectedExercise.target_musclegroup ? selectedExercise.target_musclegroup : t('common.unit.N/A')
              }`}
            </span>
            <div className={classes.ExerciseInfoExtra}>
              <button className={classes.ExerciseChange} onClick={openChangeExercisePopUp}>
                <FontAwesomeIcon icon={faRotate} />
              </button>
              <button className={classes.ExerciseHelp} onClick={openInfoExercisePopUp}>
                <FontAwesomeIcon icon={faInfo} />
              </button>
              <button onClick={removeExercise}>
                <FontAwesomeIcon icon={faTrashCan} />
              </button>
            </div>
          </div>
          <hr />
          <div className={classes.ExerciseLabel}>
            <p>{t('training.exercise.reps')}</p>
            <p>{t('training.exercise.weight')}</p>
          </div>
          <div className={classes.SetContainer}>
            {sets.map((set) => (
              <Set
                key={set.key}
                uniqueKey={set.key}
                repetition={set.repetition}
                weight={set.weight}
                onRemove={() => removeSet(set.key)}
                onUpdateRepetition={(setKey, repetition) => updateSet(setKey, 'repetition', repetition)}
                onUpdateWeight={(setKey, weight) => updateSet(setKey, 'weight', weight)}
                onComplete={completeSet}
              />
            ))}
          </div>
          <hr />
          <div className={classes.ExerciseFooter}>
            <button onClick={addSet} className={classes.AddSetButton}>
              {t('training.exercise.addSet')}
            </button>
            <div className={classes.TimeContainer}>
              <div className={classes.TimeStamp}>{getTimerDisplay()}</div>
              <div className={classes.ButtonContainer}>
                <button onClick={handleStart} className={activeButton === 'start' ? classes.ActiveButton : ''}>
                  <FontAwesomeIcon icon={faPlay} />
                </button>
                <button onClick={handlePause} className={activeButton === 'pause' ? classes.ActiveButton : ''}>
                  <FontAwesomeIcon icon={faPause} />
                </button>
                <button onClick={handleStop} className={activeButton === 'stop' ? classes.ActiveButton : ''}>
                  <FontAwesomeIcon icon={faStop} />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.ProgressbarContainer}>
          <progress className={classes.Progressbar} value={progress} max='1'></progress>
        </div>
      </div>
      <div ref={drag} className={classes.DragHandle}>
        <FontAwesomeIcon icon={faGripLines} />
      </div>
      {showChangeExercisePopUp && (
        <ChangeExercisePopUp onClose={closeChangeExercisePopUp} exercise={selectedExercise} onExerciseSelect={handleSelectedExercise} />
      )}
      {showInfoExercisePopUp && <InfoExercisePopUp onClose={closeInfoExercisePopUp} selectedExercise={selectedExercise} />}
      {showTimerRunningNotification && (
        <Notification
          text={t('training.exercise.timerRunningNotification')}
          buttons={[
            { label: t('common.ok'), action: () => setShowTimerRunningNotification(false) },
            { label: t('training.exercise.stopTimer'), action: handleStop }
          ]}
          type='alert'
          onButtonClick={(action) => {
            action()
            setShowTimerRunningNotification(false)
          }}
        />
      )}
      {showDeleteConfirmation && (
        <Notification
          text={t('training.exercise.confirmDeleteExercise')}
          buttons={[
            { label: t('common.yes'), action: () => handleDeleteExercise(true) },
            { label: t('common.no'), action: () => handleDeleteExercise(false) }
          ]}
          type='confirmation'
          onButtonClick={(action) => action()}
        />
      )}
      {showBreakPopup && <BreakTimerPopup initialTime={breakTime} onClose={() => setShowBreakPopup(false)} />}
    </div>
  )
}

export default Exercise
