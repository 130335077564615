import { apiClient } from './auth'

export const getUserProfile = async () => {
  const token = localStorage.getItem('access_token')
  const response = await apiClient.get('/user/datas', {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return response.data
}

export const updateUserProfile = async (updatedData) => {
  const token = localStorage.getItem('access_token')
  const response = await apiClient.patch('/user/update_user', updatedData, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })
  return response.data
}

export const changeUserPassword = async (passwordData) => {
  const token = localStorage.getItem('access_token')
  const response = await apiClient.put('/user/change_password', passwordData, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error('Failed to change password.')
  }
}

export const deleteUser = async () => {
  const token = localStorage.getItem('access_token')
  const response = await apiClient.delete('/user/delete_user', {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  if (response.status === 200 && response.data === 'User successfully deleted.') {
    return 'User successfully deleted.'
  } else {
    throw new Error('Failed to delete user.')
  }
}

export const toggleUsernameSearchable = async () => {
  const token = localStorage.getItem('access_token')
  const response = await apiClient.patch(
    '/user/toggle_username_searchable',
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }
  )
  return response.data
}

export const updateUserProfilePicture = async (formData) => {
  const token = localStorage.getItem('access_token')
  const response = await apiClient.put('/user/update_profile_picture', formData, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data'
    }
  })

  if (response.status === 204) {
    return 'Profile picture updated successfully'
  } else {
    throw new Error('Failed to update profile picture.')
  }
}
