import React from 'react'
import Select from 'react-select'

const customStyles = {
  container: (provided) => ({
    ...provided,
    width: '100%'
  }),
  control: (provided) => ({
    ...provided,
    border: '2px solid black',
    boxShadow: '3px 3px 0px -1px black',
    borderRadius: '10px',
    fontSize: '1em',
    fontWeight: 'bold',
    backgroundColor: 'var(--primary-button-color)',
    color: 'var(--blue1)',
    padding: '0',
    margin: '0'
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'var(--blue1)'
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: '1000'
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? 'var(--tertiary-button-color)' : 'var(--blue1)',
    color: 'var(--primary-button-color)',
    padding: '10px',
    marginTop: '-5px',
    marginBottom: '-5px'
  }),
  input: (provided) => ({
    ...provided,
    color: 'var(--blue1)',
    padding: '0',
    margin: '0'
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'var(--blue1)'
  })
}

const SelectExercise = ({ options, value, onChange, placeholder, isDisabled, id, name }) => (
  <Select
    id={id}
    name={name}
    value={value}
    onChange={onChange}
    options={options}
    placeholder={placeholder}
    styles={customStyles}
    isDisabled={isDisabled}
  />
)

export default SelectExercise
